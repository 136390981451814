import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import {
  ChevronRight,
  ChevronDown,
  Settings,
  Play,
  Database,
  ArrowDown,
  X,
} from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

const initialData = {
  databases: [
    {
      id: 1,
      name: 'production_db',
      targetPattern: '^prod_${table}',
      syncInterval: '1h',
      enabled: true,
      expanded: false,
      schemas: [
        {
          id: 1,
          name: 'public',
          targetPattern: null,
          syncInterval: null,
          enabled: true,
          expanded: false,
          tables: [
            {
              id: 1,
              name: 'users',
              targetPattern: null,
              syncInterval: null,
              enabled: true,
            },
            {
              id: 2,
              name: 'orders',
              targetPattern: null,
              syncInterval: null,
              enabled: false,
            },
          ],
        },
      ],
    },
  ],
};

const GCPSettings = () => {
  const [data, setData] = useState(initialData);
  const [editingConfig, setEditingConfig] = useState(null);
  const [configOpen, setConfigOpen] = useState(false);

  const handleManualSync = (item) => {
    console.log(`Manually triggering sync for ${item.name}`);
  };

  const toggleExpand = (type, parentId, itemId) => {
    setData((prev) => {
      const newData = { ...prev };
      if (type === 'database') {
        const db = newData.databases.find((d) => d.id === itemId);
        if (db) db.expanded = !db.expanded;
      } else if (type === 'schema') {
        const db = newData.databases.find((d) => d.id === parentId);
        const schema = db?.schemas.find((s) => s.id === itemId);
        if (schema) schema.expanded = !schema.expanded;
      }
      return newData;
    });
  };

  const toggleSync = (type, parentId, itemId) => {
    setData((prev) => {
      const newData = { ...prev };
      if (type === 'database') {
        const db = newData.databases.find((d) => d.id === itemId);
        if (db) {
          db.enabled = !db.enabled;
          db.schemas.forEach((schema) => {
            schema.enabled = db.enabled;
            schema.tables.forEach((table) => {
              table.enabled = db.enabled;
            });
          });
        }
      } else if (type === 'schema') {
        const db = newData.databases.find((d) => d.id === parentId);
        const schema = db?.schemas.find((s) => s.id === itemId);
        if (schema) {
          schema.enabled = !schema.enabled;
          schema.tables.forEach((table) => {
            table.enabled = schema.enabled;
          });
        }
      } else if (type === 'table') {
        const db = newData.databases.find((d) =>
          d.schemas.some((s) => s.tables.some((t) => t.id === itemId))
        );
        const schema = db?.schemas.find((s) => s.tables.some((t) => t.id === itemId));
        const table = schema?.tables.find((t) => t.id === itemId);
        if (table) {
          table.enabled = !table.enabled;
        }
      }
      return newData;
    });
  };

  const getEffectiveValue = (item, parent, grandParent, field) => {
    if (item[field] !== null) return { value: item[field], inherited: false };
    if (parent && parent[field] !== null)
      return { value: parent[field], inherited: true };
    if (grandParent && grandParent[field] !== null)
      return { value: grandParent[field], inherited: true };
    return { value: '-', inherited: true };
  };

  const updateData = (type, parentId, itemId, newConfig) => {
    setData((prev) => {
      const newData = JSON.parse(JSON.stringify(prev));

      if (type === 'database') {
        const db = newData.databases.find((d) => d.id === itemId);
        if (db) {
          db.targetPattern = newConfig.targetPattern;
          db.syncInterval = newConfig.syncInterval;
          db.schemas.forEach((schema) => {
            schema.targetPattern = null;
            schema.syncInterval = null;
            schema.tables.forEach((table) => {
              table.targetPattern = null;
              table.syncInterval = null;
            });
          });
        }
      } else if (type === 'schema') {
        const db = newData.databases.find((d) => d.id === parentId);
        const schema = db?.schemas.find((s) => s.id === itemId);
        if (schema) {
          schema.targetPattern = newConfig.targetPattern;
          schema.syncInterval = newConfig.syncInterval;
          schema.tables.forEach((table) => {
            table.targetPattern = null;
            table.syncInterval = null;
          });
        }
      } else if (type === 'table') {
        const db = newData.databases.find((d) =>
          d.schemas.some((s) => s.tables.some((t) => t.id === itemId))
        );
        const schema = db?.schemas.find((s) => s.tables.some((t) => t.id === itemId));
        const table = schema?.tables.find((t) => t.id === itemId);
        if (table) {
          table.targetPattern = newConfig.targetPattern;
          table.syncInterval = newConfig.syncInterval;
        }
      }
      return newData;
    });
    setConfigOpen(false);
  };

  const ConfigurationDialog = ({ item, type, parent, grandParent }) => {
    const [localConfig, setLocalConfig] = useState({
      targetPattern: item.targetPattern,
      syncInterval: item.syncInterval,
    });

    const handleSave = () => {
      updateData(type, parent?.id, item.id, localConfig);
      setConfigOpen(false);
    };

    const effectiveTarget = getEffectiveValue(
      item,
      parent,
      grandParent,
      'targetPattern'
    );
    const effectiveInterval = getEffectiveValue(
      item,
      parent,
      grandParent,
      'syncInterval'
    );

    return (
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Configure Sync Settings - {item.name}</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <h4 className="font-medium">Target Pattern</h4>
              {effectiveTarget.inherited && (
                <span className="text-sm text-muted-foreground flex items-center">
                  Inherited <ArrowDown className="ml-1" size={12} />
                </span>
              )}
            </div>
            <Input
              placeholder={effectiveTarget.value}
              value={localConfig.targetPattern || ''}
              onChange={(e) =>
                setLocalConfig((prev) => ({
                  ...prev,
                  targetPattern: e.target.value || null,
                }))
              }
            />
          </div>
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <h4 className="font-medium">Sync Interval</h4>
              {effectiveInterval.inherited && (
                <span className="text-sm text-muted-foreground flex items-center">
                  Inherited <ArrowDown className="ml-1" size={12} />
                </span>
              )}
            </div>
            <Select
              value={localConfig.syncInterval || 'inherit'}
              onValueChange={(value) =>
                setLocalConfig((prev) => ({
                  ...prev,
                  syncInterval: value === 'inherit' ? null : value,
                }))
              }
            >
              <SelectTrigger>
                <SelectValue placeholder={effectiveInterval.value} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="inherit">Inherit from parent</SelectItem>
                <SelectItem value="15m">Every 15 minutes</SelectItem>
                <SelectItem value="30m">Every 30 minutes</SelectItem>
                <SelectItem value="1h">Every hour</SelectItem>
                <SelectItem value="2h">Every 2 hours</SelectItem>
                <SelectItem value="6h">Every 6 hours</SelectItem>
                <SelectItem value="12h">Every 12 hours</SelectItem>
                <SelectItem value="24h">Daily</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
          <Button onClick={handleSave}>Save Changes</Button>
        </DialogFooter>
      </DialogContent>
    );
  };

  const ValueCell = ({ value, inherited }) => (
    <div className="flex items-center gap-2">
      <span>{value}</span>
      {inherited && <span className="text-xs text-muted-foreground">(inherited)</span>}
    </div>
  );

  return (
    <div className="p-6 space-y-4">
      <h2 className="text-2xl font-bold">MySQL to BigQuery Sync Settings</h2>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[400px]">Name</TableHead>
            <TableHead>Target Pattern</TableHead>
            <TableHead>Sync Interval</TableHead>
            <TableHead className="w-[100px]">Sync</TableHead>
            <TableHead className="w-[200px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.databases.map((db) => (
            <React.Fragment key={db.id}>
              {/* Database Row */}
              <TableRow>
                <TableCell className="font-medium">
                  <div className="flex items-center space-x-2">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => toggleExpand('database', null, db.id)}
                    >
                      {db.expanded ? (
                        <ChevronDown size={16} />
                      ) : (
                        <ChevronRight size={16} />
                      )}
                    </Button>
                    <Database size={16} />
                    <span>{db.name}</span>
                  </div>
                </TableCell>
                <TableCell>
                  <ValueCell value={db.targetPattern} inherited={false} />
                </TableCell>
                <TableCell>
                  <ValueCell value={db.syncInterval} inherited={false} />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={db.enabled}
                    onCheckedChange={() => toggleSync('database', null, db.id)}
                  />
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <Dialog
                      open={configOpen && editingConfig?.id === db.id}
                      onOpenChange={(open) => {
                        setConfigOpen(open);
                        if (!open) setEditingConfig(null);
                      }}
                    >
                      <DialogTrigger asChild>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => {
                            setEditingConfig(db);
                            setConfigOpen(true);
                          }}
                        >
                          <Settings size={16} />
                        </Button>
                      </DialogTrigger>
                      <ConfigurationDialog item={db} type="database" />
                    </Dialog>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleManualSync(db)}
                    >
                      <Play size={16} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>

              {/* Schema Rows */}
              {db.expanded &&
                db.schemas.map((schema) => (
                  <React.Fragment key={schema.id}>
                    <TableRow className="bg-muted/50">
                      <TableCell className="font-medium">
                        <div className="flex items-center space-x-2 ml-6">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => toggleExpand('schema', db.id, schema.id)}
                          >
                            {schema.expanded ? (
                              <ChevronDown size={16} />
                            ) : (
                              <ChevronRight size={16} />
                            )}
                          </Button>
                          <span>{schema.name}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <ValueCell
                          {...getEffectiveValue(schema, db, null, 'targetPattern')}
                        />
                      </TableCell>
                      <TableCell>
                        <ValueCell
                          {...getEffectiveValue(schema, db, null, 'syncInterval')}
                        />
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={schema.enabled}
                          onCheckedChange={() => toggleSync('schema', db.id, schema.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-2">
                          <Dialog
                            open={configOpen && editingConfig?.id === schema.id}
                            onOpenChange={(open) => {
                              setConfigOpen(open);
                              if (!open) setEditingConfig(null);
                            }}
                          >
                            <DialogTrigger asChild>
                              <Button variant="ghost" size="sm">
                                <Settings size={16} />
                              </Button>
                            </DialogTrigger>
                            <ConfigurationDialog
                              item={schema}
                              type="schema"
                              parent={db}
                            />
                          </Dialog>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleManualSync(schema)}
                          >
                            <Play size={16} />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>

                    {/* Table Rows */}
                    {/* Table Rows */}
                    {schema.expanded &&
                      schema.tables.map((table) => (
                        <TableRow key={table.id} className="bg-muted/20">
                          <TableCell className="font-medium">
                            <div className="flex items-center space-x-2 ml-12">
                              <span>{table.name}</span>
                            </div>
                          </TableCell>
                          <TableCell>
                            <ValueCell
                              {...getEffectiveValue(table, schema, db, 'targetPattern')}
                            />
                          </TableCell>
                          <TableCell>
                            <ValueCell
                              {...getEffectiveValue(table, schema, db, 'syncInterval')}
                            />
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={table.enabled}
                              onCheckedChange={() =>
                                toggleSync('table', schema.id, table.id)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center gap-2">
                              <Dialog>
                                <DialogTrigger asChild>
                                  <Button variant="ghost" size="sm">
                                    <Settings size={16} />
                                  </Button>
                                </DialogTrigger>
                                <ConfigurationDialog
                                  item={table}
                                  type="table"
                                  parent={schema}
                                  grandParent={db}
                                />
                              </Dialog>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => handleManualSync(table)}
                              >
                                <Play size={16} />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default GCPSettings;
