import { Portal } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/20/solid';


function GlobalAlert({ errors, onClose }) {
    if (errors.length === 0) return null;
    console.log(errors)

    return (
        <Portal>
            <div className="fixed top-2 right-80 left-80 px-24 rounded-lg inset-x-0 z-[9999] bg-red-50 p-4 shadow-lg">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex-grow">
                        <h3 className="text-sm font-medium text-red-800">There were {errors.length} errors with your operator</h3>
                        <div className="mt-2 text-sm text-red-700">
                            <ul role="list" className="list-disc space-y-1 pl-5">
                                {errors.map((msg, index) => <li key={index}>{msg}</li>)}
                            </ul>
                        </div>
                    </div>
                    <div className="flex-shrink-0">
                        <button onClick={onClose} className="rounded-md text-red-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                            <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </Portal>
    );
}


export default GlobalAlert;
