import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
  Position,
} from 'reactflow';
import dagre from 'dagre';
import 'reactflow/dist/style.css';
import { fetchData } from '../../utils/baseRequest';
import { useNavigate } from 'react-router-dom';

import { Handle } from 'reactflow';

const TableNode = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  return (
    <div
      className="relative px-4 py-2 shadow-md rounded-md bg-white border border-gray-300"
      style={{ width: '140px', height: '90px' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Handle type="target" position="left" style={{ background: '#555' }} />

      <div className="text-xs text-center">{truncateText(data.project, 20)}</div>
      <div className="text-center text-xs">{truncateText(data.dataset, 20)}</div>
      <div className="text-center break-words text-xs">
        {truncateText(data.table, 20)}
      </div>
      <div className="text-center text-xs text-gray-400">
        {data.table_type.toLowerCase()}
      </div>
      {isHovered && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-400 bg-opacity-75 rounded-md">
          <span className="text-white text-sm">Go to table</span>
        </div>
      )}

      <Handle type="source" position="right" style={{ background: '#555' }} />
    </div>
  );
};

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 200;
  const nodeHeight = 120;

  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      targetPosition: direction === 'LR' ? Position.Left : Position.Top,
      sourcePosition: direction === 'LR' ? Position.Right : Position.Bottom,
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};

const TableLineage = ({ projectId, datasetId, tableId }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLineage = async () => {
      try {
        const response = await fetchData(
          `/resources/table-lineage/${projectId}/${datasetId}/${tableId}`
        );
        const { nodes: responseNodes, edges: responseEdges } = response;

        const formattedNodes = responseNodes.map((node) => ({
          id: node.id,
          type: 'custom',
          data: {
            project: node.project,
            dataset: node.dataset,
            table: node.table,
            table_type: node.table_type,
          },
          position: { x: 0, y: 0 },
        }));

        const formattedEdges = responseEdges.map((edge) => ({
          id: `${edge.source}-${edge.target}`,
          source: edge.source,
          target: edge.target,
          type: 'smoothstep',
          animated: true,
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
        }));

        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
          formattedNodes,
          formattedEdges,
          'LR'
        );

        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching lineage data:', error);
        setLoading(false);
      }
    };

    fetchLineage();
  }, [projectId, datasetId, tableId]);

  const onNodeDoubleClick = useCallback(
    (event, node) => {
      const { project, dataset, table } = node.data;
      navigate(
        `/domains/noonbicedata/assets/bigquery/projects/${project}/datasets/${dataset}/tables/${table}`
      );
    },
    [navigate]
  );

  const nodeTypes = useMemo(
    () => ({
      custom: TableNode,
    }),
    []
  );

  if (loading) {
    return <div>Loading lineage data...</div>;
  }

  return (
    <div style={{ height: '600px', width: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeDoubleClick={onNodeDoubleClick}
        nodeTypes={nodeTypes}
        fitView
      >
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default TableLineage;
