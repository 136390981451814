import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Badge } from '@/components/ui/badge';
import { getUsers } from '../../utils/user';

function RoleManager({ open, onClose, onSave, initialUser, initialRole, domainId }) {
  const [role, setRole] = useState(initialRole || '');
  const roles = ['Owner', 'Admin', 'Editor', 'Viewer'];

  useEffect(() => {
    if (open) {
      setRole(initialRole || '');
    }
  }, [open, initialRole]);

  const handleSave = () => {
    onSave({ ...initialUser, role });
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Update Member Role</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <Badge variant="secondary" className="text-sm">
              {initialUser ? initialUser.name : 'No user selected'}
            </Badge>
            <Badge variant="outline" className="text-sm">
              {role || 'No role selected'}
            </Badge>
          </div>
          <Select value={role} onValueChange={setRole}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select role" />
            </SelectTrigger>
            <SelectContent>
              {roles.map((roleOption) => (
                <SelectItem key={roleOption} value={roleOption}>
                  {roleOption}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <DialogFooter className="sm:justify-end">
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={!role} onClick={handleSave}>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default RoleManager;
