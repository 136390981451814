import React, { useState, useEffect } from 'react'
import { Edit2, Trash2 } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  getDomainMembers,
  deleteUserFromDomain,
  updateRole,
} from '../../utils/domain'
import RoleManager from '../../components/modals/RoleManager'
import UserIcon from '../user/UserIcon'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

function MemberList({ domain, memberRefreshTrigger }) {
  const [teamMembers, setTeamMembers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [isRoleManagerOpen, setIsRoleManagerOpen] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (domain) {
      fetchTeamMembers()
    }
  }, [domain, memberRefreshTrigger])

  const fetchTeamMembers = async () => {
    try {
      setLoading(true)
      const members = await getDomainMembers(domain)
      setTeamMembers(Array.isArray(members) ? members : [])
      setError(null)
    } catch (error) {
      console.error('Error fetching team members:', error)
      setTeamMembers([])
      setError('Failed to fetch team members. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteMember = async (person) => {
    if (!person || !person.user_email) {
      setError('Invalid user data.')
      return
    }

    const confirmDelete = window.confirm(`Are you sure you want to delete ${person.name}?`)
    if (!confirmDelete) return

    try {
      const success = await deleteUserFromDomain(domain, person.user_email)
      if (success) {
        setTeamMembers((prevMembers) =>
          prevMembers.filter(
            (member) => member.user_email !== person.user_email
          )
        )
        setError(null)
      } else {
        setError('Failed to delete user. Please try again.')
      }
    } catch (error) {
      console.error('Error deleting member:', error)
      setError('An error occurred while deleting the user.')
    }
  }

  const handleEditMember = (user) => {
    if (!user) {
      setError('Invalid user data.')
      return
    }
    setSelectedUser(user)
    setIsRoleManagerOpen(true)
  }

  const handleCloseRoleManager = () => {
    setIsRoleManagerOpen(false)
    setSelectedUser(null)
  }

  const handleRoleSave = async (updatedUser) => {
    if (!updatedUser || !updatedUser.user_email) {
      setError('Invalid user data.')
      return
    }

    try {
      const roleUpdateData = { role_name: updatedUser.role }
      await updateRole(domain, updatedUser.user_email, roleUpdateData)

      setTeamMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.user_email === updatedUser.user_email
            ? {
                ...member,
                roles: { ...(member.roles || {}), [domain]: updatedUser.role },
              }
            : member
        )
      )
      handleCloseRoleManager()
      setError(null)
    } catch (error) {
      console.error('Failed to update role:', error)
      setError('Failed to update role. Please try again.')
    }
  }

  const getRoleColor = (role) => {
    const colorMap = {
      admin: 'bg-green-100 text-green-800',
      editor: 'bg-yellow-100 text-yellow-800',
      viewer: 'bg-gray-100 text-gray-800',
      contributor: 'bg-blue-100 text-blue-800',
      owner: 'bg-purple-100 text-purple-800',
      guest: 'bg-indigo-100 text-indigo-800',
    }
    return colorMap[role?.toLowerCase()] || 'bg-gray-100 text-gray-800'
  }

  return (
    <div className="space-y-4">
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="rounded-lg border">
        {loading ? (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <Table>
            <TableHeader>
              <TableRow className="hover:bg-transparent">
                <TableHead className="h-12 px-6">Member</TableHead>
                <TableHead className="h-12 px-6">Email</TableHead>
                <TableHead className="h-12 px-6">Role</TableHead>
                <TableHead className="h-12 px-6 text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {teamMembers.length === 0 ? (
                <TableRow>
                  <TableCell 
                    colSpan="4" 
                    className="h-16 px-6 text-center text-sm text-muted-foreground"
                  >
                    No team members found
                  </TableCell>
                </TableRow>
              ) : (
                teamMembers.map((person) => (
                  <TableRow 
                    key={person.user_email}
                    className="hover:bg-gray-50"
                  >
                    <TableCell className="h-16 px-6">
                      <div className="flex items-center space-x-4">
                        <UserIcon
                          name={person.name}
                          imgUrl={person.img_url}
                          size="sm"
                        />
                        <div>
                          <div className="font-medium">
                            {person.name || 'N/A'}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="h-16 px-6 text-muted-foreground">
                      {person.user_email}
                    </TableCell>
                    <TableCell className="h-16 px-6">
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getRoleColor(
                          person.roles?.[domain]
                        )}`}
                      >
                        {person.roles?.[domain]
                          ? person.roles[domain].charAt(0).toUpperCase() +
                            person.roles[domain].slice(1).toLowerCase()
                          : 'No Role'}
                      </span>
                    </TableCell>
                    <TableCell className="h-16 px-6 text-right">
                      <div className="flex justify-end items-center space-x-2">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => handleEditMember(person)}
                                className="h-8 w-8 p-0"
                              >
                                <Edit2 className="h-4 w-4" />
                                <span className="sr-only">Edit role</span>
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>Edit role</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>

                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => handleDeleteMember(person)}
                                className="h-8 w-8 p-0 hover:text-red-600"
                              >
                                <Trash2 className="h-4 w-4" />
                                <span className="sr-only">Delete member</span>
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>Remove member</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
      </div>

      <RoleManager
        open={isRoleManagerOpen}
        onClose={handleCloseRoleManager}
        onSave={handleRoleSave}
        initialUser={selectedUser}
        initialRole={selectedUser?.roles?.[domain] || ''}
        domainId={domain}
      />
    </div>
  )
}

export default MemberList