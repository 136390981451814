import React, { createContext, useState, useContext, useCallback } from 'react';
import GlobalAlert from '../components/notifocations/alert';

const AlertContext = createContext({
    showAlert: (messages) => { }, // messages can be an array
    hideAlert: () => { }
});


const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ show: false, messages: [] });

    const showAlert = useCallback((messages) => {
        console.log('totititii')
        console.log(messages)
        setAlert({ show: true, messages: Array.isArray(messages) ? messages : [messages] });
    }, []);

    const hideAlert = useCallback(() => {
        setAlert({ show: false, messages: [] });
    }, []);

    return (
        <AlertContext.Provider value={{ showAlert, hideAlert }}>
            {children}
            {alert.show && <GlobalAlert errors={alert.messages} onClose={hideAlert} />}
        </AlertContext.Provider>
    );
};

export { AlertContext, AlertProvider };
