import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card';

import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

const TagBadge = ({ tag, onClick }) => (
  <HoverCard>
    <HoverCardTrigger asChild>
      <Badge
        variant="outline"
        className={cn(
          'cursor-pointer transition-colors hover:bg-secondary/80',
          tag.color,
          tag.text_color
        )}
        onClick={onClick}
      >
        {tag.name}
      </Badge>
    </HoverCardTrigger>
    <HoverCardContent className="w-48">
      <div className="space-y-1">
        <p className="text-sm font-medium">{tag.name}</p>
        {tag.description && (
          <p className="text-sm text-muted-foreground">{tag.description}</p>
        )}
      </div>
    </HoverCardContent>
  </HoverCard>
);

export default TagBadge;
