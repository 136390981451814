import React, { useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Bell, ChevronRight, Compass, ChevronDown, User, Search } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { AuthContext } from '@/context/AuthContext';
import SearchCommand from '@/components/search/SearchCommand';

const DomainHeader = ({ additionalBreadcrumbs = [], onSearch }) => {
  const { user, logout } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const domainId = pathSegments[1];
  const [openCommandMenu, setOpenCommandMenu] = useState(false);

  const baseBreadcrumbs = [
    { label: 'Home', link: '/' },
    { label: domainId, link: `/domains/${domainId}` },
  ];

  const allBreadcrumbs = [...baseBreadcrumbs, ...additionalBreadcrumbs];

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleProfileClick = () => {
    navigate(`/user/${user?.id}`);
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  return (
    <header className="border-b border-zinc-200 dark:border-zinc-800 p-4">
      <div className="">
        <div className="flex flex-1 items-center justify-between space-x-4">
          <div className="flex items-center space-x-4">
            <Avatar className="h-10 w-10 bg-gradient-to-br from-violet-500 to-indigo-500">
              <AvatarImage
                src="https://play-lh.googleusercontent.com/4-wa067yiYYMj5rRdHm82jPdDiSHSoIkwwvHA0teCEauFFU_cjx053jMBwBxfo2anJo"
                alt="AI Assistant"
              />
              <AvatarFallback>N</AvatarFallback>
            </Avatar>

            <Breadcrumb>
              <BreadcrumbList>
                {allBreadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && (
                      <BreadcrumbSeparator>
                        <ChevronRight className="h-4 w-4" />
                      </BreadcrumbSeparator>
                    )}
                    <BreadcrumbItem>
                      {crumb.link ? (
                        <BreadcrumbLink asChild>
                          <Link
                            to={crumb.link}
                            className="text-sm font-medium text-muted-foreground hover:text-primary"
                          >
                            {crumb.label}
                          </Link>
                        </BreadcrumbLink>
                      ) : (
                        <span className="text-sm font-medium text-muted-foreground">
                          {crumb.label}
                        </span>
                      )}
                    </BreadcrumbItem>
                  </React.Fragment>
                ))}
              </BreadcrumbList>
            </Breadcrumb>
          </div>

          <div className="flex items-center gap-x-4  justify-between">
            <Button
              onClick={() => navigate('/search')}
              variant="outline"
              className="group relative inline-flex items-center gap-2.5 py-2 font-semibold transition-all duration-200 hover:shadow-md mr-2"
            >
              <span className="relative flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 group-hover:from-cyan-600 group-hover:to-blue-600 transition-all duration-200">
                <Compass className="h-3 w-3 text-white" aria-hidden="true" />
              </span>
              <span className="relative">Explore</span>
            </Button>
            <SearchCommand
              className="mr-2"
              open={openCommandMenu}
              onOpenChange={setOpenCommandMenu}
            />

            <div className="flex items-center gap-2">
              <Button variant="ghost" size="icon" className="h-9 w-9">
                <Bell className="h-4 w-4" />
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="sm" className="gap-2">
                    <span className="hidden md:inline-block">
                      {user?.name || 'User'}
                    </span>
                    <ChevronDown className="h-4 w-4 opacity-50" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-48">
                  <DropdownMenuLabel>My Account</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Profile</DropdownMenuItem>
                  <DropdownMenuItem>Sign out</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default DomainHeader;
