import React from 'react';

const LoadingBar = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="fixed top-0 left-0 right-0 h-0.5 z-50 animate-fade-in bg-background/10">
      <div className="h-full bg-indigo-600 animate-progress-loading relative" />
    </div>
  );
};

export default LoadingBar;
