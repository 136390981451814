import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Notebook } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { getResourcesInDomain, importNotebook } from '@/utils/resources';

export default function NotebookList() {
  const [notebooks, setNotebooks] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [newNotebook, setNewNotebook] = useState({ name: '', url: '' });
  const { domainId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchNotebooks();
  }, [domainId]);

  const fetchNotebooks = async () => {
    try {
      const response = await getResourcesInDomain(domainId, 'notebook');
      const data = await response;
      setNotebooks(data);
    } catch (error) {
      console.error('Error fetching notebooks:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await importNotebook(domainId, {
        url: newNotebook.url,
        name: newNotebook.name,
      });

      setIsOpen(false);
      fetchNotebooks();
      setNewNotebook({ name: '', url: '' });
    } catch (error) {
      console.error('Error adding notebook:', error);
    }
  };

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle>Notebooks</CardTitle>
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogTrigger asChild>
            <Button>Add Notebook</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Notebook</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="name">Notebook Name</Label>
                <Input
                  id="name"
                  value={newNotebook.name}
                  onChange={(e) =>
                    setNewNotebook((prev) => ({ ...prev, name: e.target.value }))
                  }
                  placeholder="Enter notebook name"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="url">Notebook URL</Label>
                <Input
                  id="url"
                  value={newNotebook.url}
                  onChange={(e) =>
                    setNewNotebook((prev) => ({ ...prev, url: e.target.value }))
                  }
                  placeholder="Enter notebook URL"
                />
              </div>
              <Button type="submit" className="w-full">
                Add Notebook
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {notebooks.map((notebook) => (
              <TableRow key={notebook.id_notebook}>
                <TableCell className="flex items-center space-x-2">
                  <Notebook className="h-4 w-4" />
                  <span
                    className="cursor-pointer hover:underline"
                    onClick={() =>
                      navigate(
                        `/domains/${domainId}/assets/notebooks/${notebook.id_notebook}`
                      )
                    }
                  >
                    {notebook.name}
                  </span>
                </TableCell>
                <TableCell>
                  <Button
                    variant="ghost"
                    onClick={() => window.open(notebook.url, '_blank')}
                  >
                    Open
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
