import React from 'react';
import { getUserInitials } from '../../utils/user';

const UserIcon = ({ name, imgUrl, size = 'md' }) => {
  const initials = getUserInitials(name);
  
  const sizeClasses = {
    sm: 'h-8 w-8 text-xs',
    md: 'h-10 w-10 text-sm',
    lg: 'h-12 w-12 text-base',
  };

  const className = `${sizeClasses[size]} rounded-full flex items-center justify-center font-medium`;

//   if (imgUrl) {
//     return <img src={imgUrl} alt={name} className={className} />;
//   }

  return (
    <div className={`${className} bg-[#689f38] text-white`}>
      {initials}
    </div>
  );
};

export default UserIcon;

// 'bg-green-500',
// 'bg-emerald-500',
// 'bg-teal-500',
// 'bg-green-600',
// 'bg-emerald-600',
// 'bg-teal-600',
// 'bg-green-700',
// 'bg-emerald-700',
// 'bg-teal-700',