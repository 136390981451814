import { createData, deleteData, fetchData, updateData } from './baseRequest';

export const getDomains = async () => {
  return fetchData(`/domains/`);
};

export const addDomain = async (data) => {
  return createData(`/domain/`, data);
};

export const getDomainsTeams = async (idDomain) => {
  return fetchData('/dunit/' + idDomain);
};

export const triggerDomainDag = async (domainId, dagId) => {
  return createData(`/temporal/trigger/${domainId}/${dagId}`);
};

export const toggleDag = async (apiPart, domainId, dagId) => {
  return createData(`/temporal/${apiPart}/${domainId}/${dagId}`);
};

export const fetchRuns = async (idTask) => {
  return fetchData(`/domain/dags/${idTask}/runs`);
};

export const fetchTask = async (dagId, taskId) => {
  return fetchData(`/dags/${dagId}/tasks/${taskId}`);
};

export const fetchTaskRuns = async (domainId, id_dag) => {
  return fetchData(`/domain/${domainId}/workflow/${id_dag}`);
};

export const getDomainDags = async (domainId) => {
  return fetchData(`/domain/${domainId}/dags/`);
};

export const getDomainMembers = async (domainId) => {
  return fetchData(`/domains/${domainId}/users`);
};

export const addMemberToDomain = async (data) => {
  return createData(`/user-domain-roles/`, data);
};

export const updateRole = async (domain_id, user_email, data) => {
  return updateData(`/user-domain-roles/${user_email}/${domain_id}`, data);
};

export const deleteUserFromDomain = async (domainId, userEmail) => {
  return deleteData(`/domains/${domainId}/users/${userEmail}`);
};

export const getDagSummary = async (domainId, idDag) => {
  return fetchData(`/domain/${domainId}/${idDag}/summary`);
};

export const fetchAllTagList = async () => {
  return fetchData(`tags`);
};
