import React, { useState, useEffect, useCallback } from 'react';
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

import {
  Building2,
  Users,
  CreditCard,
  SlidersHorizontal,
  ArrowUpDown,
  RotateCw,
  ChevronDown,
  Search,
} from 'lucide-react';

import { cn } from '@/lib/utils';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Badge } from '@/components/ui/badge';
import { Skeleton } from '@/components/ui/skeleton';

const tabs = [
  { name: 'Teams', href: 'teams', icon: Building2 },
  { name: 'GCP', href: 'gcp', icon: Users },
  { name: 'Access', href: 'access', icon: CreditCard },
  { name: 'Secrets', href: 'secrets', icon: CreditCard },
  { name: 'Ingestion', href: 'ingestion', icon: CreditCard },
];

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('asc');
  const [filteredTabs, setFilteredTabs] = useState(tabs);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = useCallback(
    debounce((term) => {
      const filtered = tabs.filter((tab) =>
        tab.name.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredTabs(filtered);
    }, 300),
    []
  );

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, handleSearch]);

  const isActive = (href) => location.pathname.includes(href);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleSort = (option) => {
    setSortOption(option);
    const sorted = [...filteredTabs].sort((a, b) => {
      if (a.name < b.name) return option === 'asc' ? -1 : 1;
      if (a.name > b.name) return option === 'asc' ? 1 : -1;
      return 0;
    });
    setFilteredTabs(sorted);
  };

  return (
    <Card className="border-0 shadow-lg">
      <CardHeader className="border rounded-t-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Building2 className="h-5 w-5 text-primary" />
            <div>
              <CardTitle>Settings</CardTitle>
              <CardDescription>
                Manage your application settings and configurations
              </CardDescription>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {/* Search Bar */}
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
              <Input
                placeholder="Search settings..."
                className="pl-10"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {/* Sort Dropdown */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="gap-2">
                  <ArrowUpDown className="h-4 w-4" />
                  Sort
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-48">
                <DropdownMenuLabel>Sort by</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuCheckboxItem
                  checked={sortOption === 'asc'}
                  onCheckedChange={() => handleSort('asc')}
                >
                  Ascending
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={sortOption === 'desc'}
                  onCheckedChange={() => handleSort('desc')}
                >
                  Descending
                </DropdownMenuCheckboxItem>
              </DropdownMenuContent>
            </DropdownMenu>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => {
                      setIsLoading(true);
                      setTimeout(() => {
                        setIsLoading(false);
                        navigate(location.pathname);
                      }, 1000);
                    }}
                    disabled={isLoading}
                  >
                    <RotateCw
                      className={`h-5 w-5 ${isLoading ? 'animate-spin' : ''}`}
                    />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Refresh settings</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </CardHeader>

      <CardContent className="p-6">
        <div className="mb-4">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              defaultValue={filteredTabs.find((tab) => isActive(tab.href))?.name || ''}
              onChange={(e) => {
                const selectedTab = filteredTabs.find(
                  (tab) => tab.name === e.target.value
                );
                if (selectedTab) navigate(selectedTab.href);
              }}
            >
              {filteredTabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>

          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {filteredTabs.map((tab) => (
                  <NavLink
                    key={tab.name}
                    to={tab.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                      )
                    }
                    aria-current={isActive(tab.href) ? 'page' : undefined}
                  >
                    <tab.icon
                      className={classNames(
                        isActive(tab.href)
                          ? 'text-indigo-500'
                          : 'text-gray-400 group-hover:text-gray-600',
                        '-ml-0.5 mr-2 h-5 w-5'
                      )}
                      aria-hidden="true"
                    />
                    {tab.name}
                  </NavLink>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <div>
          {isLoading ? (
            <div className="space-y-4">
              {[1, 2, 3].map((n) => (
                <div key={n} className="flex gap-4">
                  <Skeleton className="h-6 w-6 rounded-full" />
                  <div className="space-y-2 flex-1">
                    <Skeleton className="h-4 w-1/2" />
                    <Skeleton className="h-4 w-3/4" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default Settings;
