import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { SelectedContext } from '../../context/SelectedContext';
import {
  getDagSummary,
  getDomainDags,
  toggleDag,
  triggerDomainDag,
} from '../../utils/domain';
import useAuth from '../../context/useAuth';
import PermissionButton from '../common/PermissonButton';
import { PERMISSIONS } from '../../context/permissions';
import { DataTable } from '../dag/DataTable';
import { columns } from '../dag/Columns';
import { Skeleton } from '../ui/skeleton';

export function DomainDags(props) {
  const { selectedDomain } = useContext(SelectedContext);
  const { domainId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('all');
  const [statusFilter, setStatusFilter] = useState('ALL');
  const { user } = useAuth();

  const statuses = ['ALL', 'EDITING', 'SCHEDULED', 'RUNNING', 'DISABLED', 'FAILED'];

  const fetchData = async () => {
    setLoading(true);
    try {
      const dags = await getDomainDags(domainId);
      setData(dags); // dags already include summaries in the response
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [domainId]);

  const handleToggleDag = async (action, domainId, dagId) => {
    try {
      await toggleDag(action, domainId, dagId);
      setData((prevData) =>
        prevData.map((dag) => {
          if (dag.id_dag === dagId) {
            return {
              ...dag,
              status: action === 'disable' ? 'DISABLED' : 'SCHEDULED',
              is_disabled: action === 'disable',
            };
          }
          return dag;
        })
      );
    } catch (error) {
      console.error('Error toggling dag:', error);
      fetchData();
    }
  };

  const handleCreateDag = () => {
    navigate(`/domains/${domainId}/createDag`);
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  const filteredData = data
    .filter((item) => viewMode === 'all' || item.dag.created_by === user.user_email)
    .filter((item) =>
      item.dag.dag_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((item) => statusFilter === 'ALL' || item.dag.status === statusFilter);
  return (
    <div className="">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4">
          <div className="flex gap-2">
            <Input
              placeholder="Search dags..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="max-w-sm"
            />
            <Select value={statusFilter} onValueChange={setStatusFilter}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Filter by status" />
              </SelectTrigger>
              <SelectContent>
                {statuses.map((status) => (
                  <SelectItem key={status} value={status}>
                    {status === 'ALL' ? 'All Statuses' : status}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex gap-2">
            <Button
              variant={viewMode === 'all' ? 'secondary' : 'outline'}
              onClick={() => handleViewModeChange('all')}
            >
              View all
            </Button>
            <Button
              variant={viewMode === 'my' ? 'secondary' : 'outline'}
              onClick={() => handleViewModeChange('my')}
            >
              Show my dags
            </Button>
          </div>
        </div>
        <PermissionButton
          onClick={handleCreateDag}
          domainId={domainId}
          permission={PERMISSIONS.CREATE_DAG}
          className="bg-primary text-primary-foreground hover:bg-primary/90"
        >
          + Create new dag
        </PermissionButton>
      </div>

      {loading ? (
        <div className="space-y-4">
          {[1, 2, 3].map((n) => (
            <div key={n} className="flex gap-4">
              <Skeleton className="h-6 w-6 rounded-full" />
              <div className="space-y-2 flex-1">
                <Skeleton className="h-4 w-1/2" />
                <Skeleton className="h-4 w-3/4" />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <DataTable
          columns={columns({
            domainId,
            navigate,
            toggleDag: handleToggleDag,
            triggerDomainDag,
          })}
          data={filteredData}
        />
      )}
    </div>
  );
}

export default DomainDags;
