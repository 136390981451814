// components/tasks/DagTasks.jsx
import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Plus, Loader2, Network, Settings2, Workflow } from 'lucide-react';
import { TaskDataTable } from './TaskDataTable';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/hooks/use-toast';
import DagEditor from './DagEditor';
import DagTaskItem from './DagTaskItem';
import PermissionButton from '@/components/common/PermissonButton';
import { PERMISSIONS } from '@/context/permissions';

import {
  deleteDagTask,
  getDagTask,
  getDagTaskList,
  terminateActivity,
  toggleTask,
  trigger_adhoc,
} from '@/utils/dag';
import { fetchTaskRuns } from '@/utils/domain';
import { taskColumns } from './TaskColumns';

export default function DagTasks({ dagStatus }) {
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState();
  const [taskRunStatus, setTaskRunStatus] = useState();
  const [dagRuns, setDagRuns] = useState([]);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [expandedState, setExpandedState] = useState({});

  const { dagId, domainId, taskId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isEditDagTasks =
      location.pathname.includes('edit') && !location.pathname.includes('editors');
    setShowEditor(isEditDagTasks);
  }, [location.pathname]);

  useEffect(() => {
    const interval = setInterval(fetchDataAndMerge, 10000);
    fetchDataAndMerge();
    return () => clearInterval(interval);
  }, [dagId, taskId]);

  useEffect(() => {
    if (!tasks.length) return;

    setTasks((prevTasks) =>
      prevTasks.map((task) => ({
        ...task,
        status:
          dagStatus === 'DISABLED'
            ? 'DISABLED'
            : task.individualStatus === 'DISABLED'
            ? 'DISABLED'
            : task.individualStatus || task.status,
      }))
    );
  }, [dagStatus]);

  const fetchDataAndMerge = async () => {
    try {
      if (taskId) {
        const taskData = await getDagTask(dagId, taskId);
        setTask(taskData);
      } else {
        const [tasksList, tasksStatus] = await Promise.all([
          getDagTaskList(dagId),
          fetchTaskRuns(domainId, dagId),
        ]);

        setTaskRunStatus(tasksStatus);
        setDagRuns(tasksStatus.dags_status || []);

        const processedTasks = tasksList.map((task) => ({
          ...task,
          parent_nodes: task.parent_nodes
            ? task.parent_nodes.split(',').map((id) => id.trim())
            : [],
          child_nodes: task.child_nodes
            ? task.child_nodes.split(',').map((id) => id.trim())
            : [],
          statuses: (tasksStatus.tasks_status?.[task.id_task] || []).sort(
            (a, b) => new Date(b.start_ts) - new Date(a.start_ts)
          ),
          individualStatus: task.status,
          status: dagStatus === 'DISABLED' ? 'DISABLED' : task.status,
        }));

        setTasks(processedTasks);

        const newExpandedState = {};
        processedTasks.forEach((task) => {
          if (task.parent_nodes?.length > 0) {
            newExpandedState[task.id_task] = true;
          }
        });
        setExpandedState(newExpandedState);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch tasks data',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (taskToDelete) => {
    try {
      await deleteDagTask(dagId, taskToDelete.id_task);
      setTasks((prevTasks) =>
        prevTasks.filter((task) => task.id_task !== taskToDelete.id_task)
      );
      toast({
        description: 'Task deleted successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete task',
        variant: 'destructive',
      });
    }
  };

  const handleTrigger = async (tasksToTrigger) => {
    const tasksList = Array.isArray(tasksToTrigger) ? tasksToTrigger : [tasksToTrigger];
    const taskIds = tasksList.map((task) => task.id_task);

    try {
      await trigger_adhoc(dagId, { tasks_to_run: taskIds });
      toast({
        description: `Successfully triggered ${taskIds.length} task${
          taskIds.length === 1 ? '' : 's'
        }`,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to trigger tasks',
        variant: 'destructive',
      });
    }
  };

  const handleTaskUpdate = async (updatedTask) => {
    const newStatus = updatedTask.status === 'DISABLED' ? 'disable' : 'enable';

    try {
      await toggleTask(newStatus, updatedTask.id_task);

      setTasks((prevTasks) =>
        prevTasks.map((task) => {
          if (task.id_task === updatedTask.id_task) {
            let newTaskStatus;

            if (newStatus === 'disable') {
              newTaskStatus = 'DISABLED';
            } else {
              if (dagStatus === 'EDITING') {
                newTaskStatus = 'EDITING';
              } else if (dagStatus === 'SCHEDULED') {
                newTaskStatus = 'SCHEDULED';
              } else {
                newTaskStatus = dagStatus;
              }
            }

            return {
              ...task,
              individualStatus: newTaskStatus,
              status: newTaskStatus,
            };
          }
          return task;
        })
      );

      toast({
        description: `Task ${newStatus}d successfully`,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: `Failed to ${newStatus} task`,
        variant: 'destructive',
      });
    }
  };

  const handleTerminate = async (workflowId, runId, activityId) => {
    try {
      await terminateActivity(domainId, workflowId, runId, activityId);
      toast({
        description: 'Activity terminated successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to terminate activity',
        variant: 'destructive',
      });
    }
  };

  const handleCloseDagEditor = () => {
    showEditor(false);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (showEditor) {
    return (
      <DagEditor
        runs={taskRunStatus}
        onClose={async () => {
          setLoading(true);
          setShowEditor(false);
          await fetchDataAndMerge();
          navigate(`/domains/${domainId}/dags/${dagId}/tasks`);
        }}
      />
    );
  }

  if (!task && tasks.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-[400px] space-y-4">
        <div className="p-4 rounded-full bg-muted">
          <Plus className="h-8 w-8 text-muted-foreground" />
        </div>
        <h3 className="text-lg font-semibold">No tasks found</h3>
        <p className="text-sm text-muted-foreground">
          Get started by creating new tasks for this DAG.
        </p>
        <PermissionButton
          onClick={() => {
            setShowEditor(true);
            navigate(`/domains/${domainId}/dags/${dagId}/tasks/edit`);
          }}
          permission={PERMISSIONS.EDIT_DAG}
          domainId={domainId}
        >
          Create Tasks
        </PermissionButton>
      </div>
    );
  }

  if (task) {
    return (
      <DagTaskItem
        row={task}
        onBackButtonClick={() => {
          setTask(null);
          navigate(`/domains/${domainId}/dags/${dagId}/tasks`);
        }}
      />
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-lg font-semibold">Tasks</h2>
          <p className="text-sm text-muted-foreground">
            Manage and monitor your DAG tasks
          </p>
        </div>
        <div className="flex items-center gap-2">
          <PermissionButton
            onClick={() => {
              setShowEditor(true);
              navigate(`/domains/${domainId}/dags/${dagId}/tasks/edit`);
            }}
            permission={PERMISSIONS.EDIT_DAG}
            domainId={domainId}
            variant="outline"
            className="flex items-center gap-2"
          >
            <Workflow className="h-4 w-4" />
            Dag Editor
          </PermissionButton>
        </div>
      </div>

      <TaskDataTable
        columns={taskColumns({
          onDelete: handleDelete,
          onTrigger: handleTrigger,
          onTaskUpdate: handleTaskUpdate,
          onTerminate: handleTerminate,
          onRowClick: (row) =>
            navigate(`/domains/${domainId}/dags/${dagId}/tasks/${row.id_task}`),
          dagDisabled: dagStatus === 'DISABLED',
          dagStatus: dagStatus,
        })}
        data={tasks}
        onRowSelectionChange={setSelectedTaskIds}
        selectedRowIds={selectedTaskIds}
        expandedState={expandedState}
        onTriggerSelected={handleTrigger}
        dagStatus={dagStatus}
      />
    </div>
  );
}
