import React, { createContext, useState, useContext, useCallback } from 'react';
import GlobalAlert from '../components/notifocations/alert';
import GlobalSuccess from '../components/notifocations/sucess';

const SuccessContext = createContext({
    showSuccess: (messages) => { },
    hideSuccess: () => { }
});

const SuccessProvider = ({ children }) => {
    const [success, setSuccess] = useState({ show: false, messages: [] });

    const showSuccess = useCallback((messages) => {
        console.log('totititii')
        console.log(messages)
        setSuccess({ show: true, messages: Array.isArray(messages) ? messages : [messages] });
    }, []);

    const hideSuccess = useCallback(() => {
        setSuccess({ show: false, messages: [] });
    }, []);

    return (
        <SuccessContext.Provider value={{ showSuccess, hideSuccess }}>
            {children}
            {success.show && <GlobalSuccess errors={success.messages} onClose={hideSuccess} />}
        </SuccessContext.Provider>
    );
};

export { SuccessContext, SuccessProvider };
