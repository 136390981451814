// src/components/modals/SecretManager.jsx

import React, { useState, Fragment } from 'react';
import {
  Dialog,
  DialogOverlay,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog';
import { X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';

function SecretManager({ onClose, onSave, secret, error }) {
  const [key, setKey] = useState(secret ? secret.key : '');
  const [value, setValue] = useState(secret ? secret.value : '');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (key && value) {
      const secretData = secret
        ? { ...secret, key, value }
        : { id: Date.now(), key, value };
      onSave(secretData);
    }
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogOverlay className="bg-black bg-opacity-25" />
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{secret ? 'Edit Secret' : 'Add Secret'}</DialogTitle>
          <DialogDescription>
            {secret
              ? 'Update the details of the secret below.'
              : 'Create a new secret by providing a key and value.'}
          </DialogDescription>
        </DialogHeader>
        {error && (
          <Alert variant="destructive" className="mt-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <form onSubmit={handleSubmit} className="mt-4 grid gap-4">
          <div className="space-y-2">
            <Label htmlFor="key">Key</Label>
            <Input
              id="key"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              placeholder="Enter secret key"
              required
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="value">Value</Label>
            <Input
              type="password"
              id="value"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Enter secret value"
              required
            />
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <Button
              type="button"
              variant="secondary"
              onClick={onClose}
              className="px-4 py-2 text-sm"
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary" className="px-4 py-2 text-sm">
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default SecretManager;
