import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { VscFileSubmodule } from "react-icons/vsc";
import { SiLooker } from "react-icons/si";
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import ReactQuill from 'react-quill';
import { fetchData } from '../../utils/baseRequest';
import { Badge } from "@/components/ui/badge"; // Ensure this path is correct
import { Card, CardContent } from "@/components/ui/card"; // Ensure these components exist

export default function LookerAsset() {
  const [looker, setLooker] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { domainId, lookerId, lookerType } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLooker = async () => {
      try {
        const data = await fetchData(`/resources/${lookerType}/${lookerId}`);
        console.log("Fetched Looker Data:", data); // Debugging line
        setLooker(data);
      } catch (error) {
        console.error("A problem occurred with fetching the docs:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchLooker();
  }, [domainId, lookerId, lookerType]);

  if (loading) return <div className="py-4 text-center">Loading...</div>;
  if (error) return (
    <div className="py-4 text-center text-red-500">
      Error fetching lookers: {error}
    </div>
  );

  const assetTypeLabel = lookerType === 'looker_dashboard' ? 'Dashboard' : 'Look';

  return (
    <div className="min-h-screen px-4">
      <Breadcrumb asset={looker} lookerType={lookerType} />

      <Card className="bg-white rounded-lg shadow-sm p-4">
        <CardContent>
          <div className='flex justify-between items-center mb-6'>
            <div className='flex items-center'>
              <SiLooker className="mr-3 text-2xl text-blue-400" />
              <div>
                <h1 className="text-lg font-semibold text-gray-900">
                  {looker?.resource_name || 'No Resource Name'}
                </h1>
                <span className="text-xs text-gray-500">{assetTypeLabel}</span>
              </div>
            </div>

            {looker?.url && (
              <a
                href={looker.url}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Go To Looker
              </a>
            )}
          </div>

          <div className="grid grid-cols-1 gap-6">
            <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2 border-t border-gray-200 pt-4">
              
              <div>
                <dt className="text-sm font-medium text-gray-500">Resource URN</dt>
                <dd className="mt-1 text-sm text-gray-900">{looker?.urn || 'No URN'}</dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-500">Owner</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {looker?.owner ? (
                    <Badge variant="secondary" className="text-xs font-normal">
                      {looker.owner}
                    </Badge>
                  ) : (
                    'Unknown'
                  )}
                </dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-500">Owner ID</dt>
                <dd className="mt-1 text-sm text-gray-900">{looker?.owner_id || 'No Owner ID'}</dd>
              </div>

              <div>
                <dt className="text-sm font-medium text-gray-500">{assetTypeLabel} ID</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {looker?.dashboard_id || looker?.look_id || 'No ID'}
                </dd>
              </div>
            </dl>

            <div className="border-t border-gray-200 pt-4">
              <h3 className="text-sm font-medium text-gray-500 mb-2">Description</h3>
              <ReactQuill
                value={looker?.description || "No documentation yet."}
                readOnly={true}
                theme={"snow"}
                modules={{ toolbar: false }}
                className="text-sm"
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

function Breadcrumb({ asset, lookerType }) {
  const { lookerId, domainId } = useParams();
  const assetTypeLabel = lookerType === 'looker_dashboard' ? 'Dashboards' : 'Looks';

  const items = [
    {
      name: `Looker ${assetTypeLabel}`,
      icon: <VscFileSubmodule className="text-xl text-gray-400" />,
      href: `/domains/${domainId}/assets/lookers`
    },
    {
      name: asset?.resource_name || 'No Resource Name',
      href: `/domains/${domainId}/assets/${lookerType}/${lookerId}`
    }
  ];

  return (
    <nav className="flex mb-6" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        {items.map((item, index) => (
          <li key={index}>
            <div className="flex items-center">
              {index !== 0 && (
                <ChevronRightIcon 
                  className="h-4 w-4 flex-shrink-0 text-gray-400 mx-2" 
                  aria-hidden="true" 
                />
              )}
              <Link
                to={item.href}
                className="flex items-center text-xs font-medium text-gray-600 hover:text-gray-900"
              >
                {item.icon && <span className="mr-1">{item.icon}</span>}
                {item.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
