import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { VscProject } from "react-icons/vsc"; // Icon for projects
import { fetchProjects } from '../../utils/resources';
import { cn } from "@/lib/utils";

import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";

export default function BqAssetProjectsList() {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { domainId } = useParams();

    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const getProjects = async () => {
            try {
                const data = await fetchProjects(domainId);
                console.log("Fetched Projects:", data);
                setProjects(data.projects);
            } catch (error) {
                console.error("A problem occurred with fetching the projects:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        getProjects();
    }, [domainId]);

    const filteredProjects = useMemo(() => {
        return projects.filter(project =>
            project.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [projects, searchTerm]);

    const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);
    const paginatedProjects = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredProjects.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredProjects, currentPage, itemsPerPage]);

    const handlePageChange = (direction) => {
        if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        } else if (direction === 'next' && currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };

    if (loading) return <div className="py-4 text-center">Loading...</div>;
    if (error) return (
        <Alert variant="destructive" className="my-4">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
        </Alert>
    );

    return (
        <div className="min-h-screen">
            <Card>
                <CardHeader className="flex flex-col sm:flex-row justify-between items-center mb-4">
                    <CardTitle className="text-lg">BigQuery Projects</CardTitle>
                    <div className="mt-4 sm:mt-0 flex flex-col sm:flex-row items-center gap-4">
                        <Input
                            type="text"
                            placeholder="Search projects..."
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setCurrentPage(1);
                            }}
                            className="w-full sm:w-64 text-sm"
                        />
                    </div>
                </CardHeader>
                <CardContent>
                    {paginatedProjects.length > 0 ? (
                        <>
                            <ul className="divide-y divide-gray-200">
                                {paginatedProjects.map((project, index) => (
                                    <li key={index} className="flex items-center justify-between p-4 bg-white rounded-md shadow-sm mb-2">
                                        <div className="flex items-center">
                                            <VscProject className="mr-3 text-2xl text-slate-500" />
                                            <div>
                                                <Link
                                                    to={`/domains/${domainId}/assets/bigquery/projects/${project}`}
                                                    className="text-sm text-gray-800 hover:text-slate-600 hover:underline"
                                                >
                                                    {project}
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="flex justify-center mt-6 space-x-2">
                                <Button
                                    variant="ghost"
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange('prev')}
                                    className={cn(
                                        "px-3 py-2 rounded-md border border-gray-300 text-sm font-medium",
                                        currentPage === 1 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
                                    )}
                                >
                                    Previous
                                </Button>
                                <Button
                                    variant="ghost"
                                    disabled={currentPage === totalPages || totalPages === 0}
                                    onClick={() => handlePageChange('next')}
                                    className={cn(
                                        "px-3 py-2 rounded-md border border-gray-300 text-sm font-medium",
                                        currentPage === totalPages || totalPages === 0 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
                                    )}
                                >
                                    Next
                                </Button>
                            </div>
                        </>
                    ) : (
                        <p className="text-gray-500">No projects found.</p>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};
