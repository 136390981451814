import React, { useState, useEffect } from 'react';
import { Check, Pencil, Trash, Table, User, Play, Clock } from 'lucide-react';
import { SiGooglebigquery, SiLooker } from 'react-icons/si';
import {
  Dialog,
  DialogOverlay,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { cn } from '@/lib/utils';
import {
  getConfigFields,
  getIngestionConfigurations,
  updateIngestionConfig,
  saveIngestionConfig,
  deleteIngestionConfig,
  triggerIngestion,
} from '@/utils/ingestion';
import LoadingBar from '@/components/common/LoadingBar';
import { format, formatDistanceToNow } from 'date-fns';
import { Badge } from '@/components/ui/badge';

const ingestionTypes = [
  {
    name: 'BigQuery',
    id: 'bigquery',
    description: 'Ingest data from Google BigQuery datasets.',
    icon: SiGooglebigquery,
  },
  {
    name: 'Looker',
    id: 'looker',
    description: 'Ingest data from Looker analytics.',
    icon: SiLooker,
  },
  {
    name: 'Clickhouse',
    id: 'clickhouse',
    description: 'Ingest data from Clickhouse databases.',
    icon: Table,
  },
  {
    name: 'Users',
    id: 'users',
    description: 'Ingest user data from various sources.',
    icon: User,
  },
];

const timeUnits = [
  { id: 'weeks', name: 'Weeks' },
  { id: 'days', name: 'Days' },
  { id: 'hours', name: 'Hours' },
  { id: 'minutes', name: 'Minutes' },
  { id: 'seconds', name: 'Seconds' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const STATUS_COLORS = {
  scheduled: 'bg-blue-100 text-blue-800',
  syncing: 'bg-yellow-100 text-yellow-800',
  completed: 'bg-green-100 text-green-800',
  failed: 'bg-red-100 text-red-800',
};

const IngestionSettings = () => {
  const [configurations, setConfigurations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [configFields, setConfigFields] = useState([]);
  const [configValues, setConfigValues] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editingConfigId, setEditingConfigId] = useState(null);
  const [intervalValue, setIntervalValue] = useState('');
  const [name, setName] = useState('');
  const [selectedTimeUnit, setSelectedTimeUnit] = useState(timeUnits[2]);
  const [search, setSearch] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [triggeringConfigs, setTriggeringConfigs] = useState({});
  const [isIngesting, setIsIngesting] = useState(false);

  useEffect(() => {
    fetchConfigurations();
    const intervalId = setInterval(fetchConfigurations, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const formatLastSync = (lastSync) => {
    if (!lastSync) return 'Never';
    const date = new Date(lastSync);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const fetchConfigurations = async () => {
    try {
      const isFirstFetch = configurations.length === 0;
      if (isFirstFetch) {
        setLoading(true);
      }

      const response = await getIngestionConfigurations();

      setConfigurations((prevConfigs) => {
        const hasChanges = response.some((newConfig, index) => {
          const prevConfig = prevConfigs[index];
          if (!prevConfig) return true;
          return (
            newConfig.status !== prevConfig.status ||
            newConfig.last_synced !== prevConfig.last_synced
          );
        });

        return hasChanges ? response : prevConfigs;
      });

      setError(null);
    } catch (error) {
      console.error('Error fetching configurations:', error);
      setError('Failed to fetch configurations.');
    } finally {
      setLoading(false);
    }
  };

  const openModal = async (type) => {
    setIsEditing(false);
    setSelectedType(type);
    setError(null);

    try {
      const fields = await getConfigFields(type);
      setConfigFields(fields || []);
      setConfigValues(
        (fields || []).reduce((acc, field) => {
          acc[field.name] = '';
          return acc;
        }, {})
      );
    } catch (error) {
      console.error('Error fetching config fields:', error);
      setError('Failed to fetch configuration fields.');
      return;
    }

    setIntervalValue('');
    setSelectedTimeUnit(timeUnits[2]);
    setSearch('');
    setIsModalOpen(true);
  };

  const handleInputChange = (field, value) => {
    setConfigValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (intervalValue === '' || isNaN(intervalValue)) {
      setError('Please enter a valid interval value.');
      return;
    }

    const interval = `${intervalValue} ${selectedTimeUnit.id}`;

    const configData = {
      type: selectedType,
      config: configValues,
      interval: interval,
      name: name,
    };

    try {
      setLoading(true);
      if (isEditing) {
        await updateIngestionConfig(editingConfigId, configData);
      } else {
        await saveIngestionConfig(configData);
      }
      setIsModalOpen(false);
      fetchConfigurations();
      setError(null);
    } catch (error) {
      console.error('Error saving configuration:', error);
      setError('Failed to save configuration. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (config) => {
    setIsEditing(true);
    setEditingConfigId(config.id);
    setSelectedType(config.type);
    setError(null);

    try {
      const fields = await getConfigFields(config.type);
      setConfigFields(fields || []);
      setConfigValues(config.config || {});

      const [value, unit] = (config.interval || '').split(' ');
      const foundUnit = timeUnits.find((t) => t.id === unit);
      setIntervalValue(value || '');
      setSelectedTimeUnit(foundUnit || timeUnits[2]);
      setSearch('');
    } catch (error) {
      console.error('Error fetching config fields:', error);
      setError('Failed to fetch configuration fields. Please try again.');
      return;
    }

    setIsModalOpen(true);
  };

  const handleTrigger = async (configId) => {
    try {
      setTriggeringConfigs((prev) => ({ ...prev, [configId]: true }));
      setIsIngesting(true);
      // Just trigger and return immediately
      triggerIngestion(configId).catch((error) => {
        console.error('Error triggering ingestion:', error);
        setError('Failed to trigger ingestion. Please try again.');
        // Reset the triggering state if there's an error
        setTriggeringConfigs((prev) => ({ ...prev, [configId]: false }));
        setIsIngesting(false);
      });

      // Return immediately after triggering
      console.log('Ingestion triggered');

      // Reset the triggering UI state after a short delay
      setTimeout(() => {
        setTriggeringConfigs((prev) => ({ ...prev, [configId]: false }));
        setIsIngesting(false);
      }, 1000); // Short delay to show feedback
    } catch (error) {
      console.error('Error triggering ingestion:', error);
      setError('Failed to trigger ingestion. Please try again.');
      setTriggeringConfigs((prev) => ({ ...prev, [configId]: false }));
      setIsIngesting(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this configuration?')) {
      return;
    }
    try {
      await deleteIngestionConfig(id);
      fetchConfigurations();
      setError(null);
    } catch (error) {
      console.error('Error deleting configuration:', error);
      setError('Failed to delete configuration. Please try again.');
    }
  };

  const IntervalInput = () => (
    <div className="space-y-2">
      <Label htmlFor="interval">Interval</Label>
      <div className="flex gap-2">
        <Input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          name="intervalValue"
          id="intervalValue"
          value={intervalValue}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, '');
            setIntervalValue(value);
          }}
          placeholder="Enter number"
          className="w-1/2"
          required
        />
        <Select
          value={selectedTimeUnit.id}
          onValueChange={(value) => {
            const unit = timeUnits.find((u) => u.id === value);
            setSelectedTimeUnit(unit || timeUnits[2]);
          }}
        >
          <SelectTrigger className="w-1/2">
            <SelectValue placeholder="Select unit" />
          </SelectTrigger>
          <SelectContent>
            {timeUnits.map((unit) => (
              <SelectItem key={unit.id} value={unit.id}>
                {unit.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
  return (
    <>
      <LoadingBar isLoading={isIngesting} />
      <div className="p-4 min-h-screen">
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-10">
          {ingestionTypes.map((type) => {
            const Icon = type.icon;
            return (
              <div
                key={type.id}
                className="bg-white shadow rounded-lg p-5 cursor-pointer hover:shadow-md transition"
                onClick={() => openModal(type.id)}
              >
                <div className="flex items-center mb-3">
                  <Icon className="h-6 w-6 text-indigo-600" />
                  <h3 className="ml-3 text-base font-medium text-gray-800">
                    {type.name}
                  </h3>
                </div>
                <p className="text-sm text-gray-600">{type.description}</p>
              </div>
            );
          })}
        </div>

        <div className="bg-white shadow rounded-lg">
          <div className="px-6 py-4">
            <h3 className="text-lg font-medium text-gray-800">Configurations</h3>
          </div>
          {loading ? (
            <div className="p-6">
              <p className="text-sm text-gray-600">Loading configurations...</p>
            </div>
          ) : configurations.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-700"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-700"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-700"
                    >
                      Interval
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-700"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-700"
                    >
                      Last Synced
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {configurations.map((config) => {
                    return (
                      <tr key={config.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {config.name.charAt(0).toUpperCase() + config.name.slice(1)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                          {config.type.charAt(0).toUpperCase() + config.type.slice(1)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          Every {config.interval}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <Badge
                            variant="outline"
                            className={cn(
                              'capitalize',
                              STATUS_COLORS[config.status || 'scheduled']
                            )}
                          >
                            {config.status || 'scheduled'}
                          </Badge>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          <div className="flex items-center gap-1">
                            <Clock className="h-4 w-4" />
                            {formatLastSync(config.last_synced)}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex justify-end gap-2">
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleTrigger(config.id)}
                            className={cn(
                              'text-gray-600 hover:text-gray-700',
                              (triggeringConfigs[config.id] ||
                                config.status === 'syncing') &&
                                'animate-pulse'
                            )}
                            disabled={
                              triggeringConfigs[config.id] ||
                              config.status === 'syncing'
                            }
                            aria-label="Trigger Ingestion"
                          >
                            <Play className="h-5 w-5" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleEdit(config)}
                            className="text-gray-600 hover:text-gray-700"
                            aria-label="Edit Configuration"
                          >
                            <Pencil className="h-5 w-5" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleDelete(config.id)}
                            className="text-gray-600 hover:text-gray-700"
                            aria-label="Delete Configuration"
                          >
                            <Trash className="h-5 w-5" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="px-6 py-4">
              <p className="text-sm text-gray-600">
                No configurations found. Add a new configuration by selecting an
                ingestion type above.
              </p>
            </div>
          )}
        </div>

        <Dialog open={isModalOpen} onOpenChange={() => setIsModalOpen(false)}>
          <DialogOverlay className="bg-black bg-opacity-25" />
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>
                {isEditing ? 'Edit' : 'Add'}{' '}
                {selectedType &&
                  selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}{' '}
                Configuration
              </DialogTitle>
              <DialogDescription>
                {isEditing
                  ? 'Update the details of the configuration below.'
                  : 'Create a new configuration by providing the necessary details.'}
              </DialogDescription>
            </DialogHeader>

            {error && (
              <Alert variant="destructive" className="mt-4">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {/* Form */}
            <form onSubmit={handleSubmit} className="mt-4 grid gap-4">
              <Label htmlFor={name}>name</Label>
              <Input
                type="text"
                name={name}
                id={name}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
              {configFields.map((field) => (
                <div key={field.name} className="space-y-2">
                  <Label htmlFor={field.name}>{field.name}</Label>
                  <Input
                    type={field.type}
                    name={field.name}
                    id={field.name}
                    value={configValues[field.name] || ''}
                    onChange={(e) => handleInputChange(field.name, e.target.value)}
                    placeholder={`Enter ${field.name.toLowerCase()}`}
                    required
                  />
                </div>
              ))}

              <IntervalInput />

              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 text-sm"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="px-4 py-2 text-sm"
                  disabled={loading}
                >
                  {isEditing ? 'Update' : 'Save'}
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default IngestionSettings;
