import { useState, useEffect } from "react";
import History from "../History";
import { useParams } from "react-router-dom";
import { Loader2 } from "lucide-react";

const DomainHistory = () => {
  const [loading, setLoading] = useState(true);
  const { domainId } = useParams();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 20);
  }, []);

  return (
    <div className="w-full">
      {loading ? (
        <div className="flex justify-center items-center min-h-[300px]">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
        </div>
      ) : (
        <div className="space-y-4">
          <div className="bg-card text-card-foreground shadow-sm overflow-hidden">
            <History 
              hist_type="dunit" 
              id_dunit={domainId}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DomainHistory;