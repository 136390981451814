export const PERMISSIONS = {
    TRIGGER_DAG: 'TRIGGER_DAG',
    EDIT_DAG: 'EDIT_DAG',
    DELETE_DAG: 'DELETE_DAG',
    PUBLISH_DAG: 'PUBLISH_DAG',
    TOGGLE_DAG: 'TOGGLE_DAG',
    CREATE_DAG: 'CREATE_DAG',
    UPDATE_TASK: 'UPDATE_TASK',
    TRIGGER_TASK: 'TRIGGER_TASK',
    CHANGE_STATUS_DAG: 'CHANGE_STATUS_DAG'
  };
  
  export const ROLE_PERMISSIONS = {
    Viewer: [PERMISSIONS.TRIGGER_DAG],
    Editor: [PERMISSIONS.TRIGGER_DAG, PERMISSIONS.UPDATE_TASK, PERMISSIONS.UPDATE_TASK, PERMISSIONS.CREATE_DAG, PERMISSIONS.EDIT_DAG, PERMISSIONS.PUBLISH_DAG, PERMISSIONS.TOGGLE_DAG],
    Admin: [PERMISSIONS.TRIGGER_DAG, PERMISSIONS.UPDATE_TASK, PERMISSIONS.UPDATE_TASK, PERMISSIONS.CHANGE_STATUS_DAG, PERMISSIONS.EDIT_DAG, PERMISSIONS.CREATE_DAG, PERMISSIONS.DELETE_DAG, PERMISSIONS.PUBLISH_DAG, PERMISSIONS.TOGGLE_DAG],
    Owner: [PERMISSIONS.TRIGGER_DAG, PERMISSIONS.UPDATE_TASK, PERMISSIONS.UPDATE_TASK, PERMISSIONS.EDIT_DAG, PERMISSIONS.CHANGE_STATUS_DAG, PERMISSIONS.CREATE_DAG, PERMISSIONS.DELETE_DAG, PERMISSIONS.PUBLISH_DAG, PERMISSIONS.TOGGLE_DAG]
  };