import axios from "axios";

const getBaseURL = () => {
  if (process.env.NODE_ENV === "production") {
    return "/api";
  }
  return "http://dp-dagman-api.noondv.com/api";
};

const commonHeaders = {
  "Content-Type": "application/json",
  "N-user": JSON.stringify({ email: "soto@noon.com" }),
};

const apiClient = axios.create({
  baseURL: getBaseURL(),
  withCredentials: true,
  headers: {
    ...commonHeaders,
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 404) {
      console.error("API not found");
    }
    if (error.response?.status === 500) {
      console.error("Server error");
    }
    return Promise.reject(error);
  }
);

const fetchData = async (path) => {
  try {
    const response = await apiClient.get(path);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const createData = async (path, data) => {
  try {
    const response = await apiClient.post(path, data);
    return response.data;
  } catch (error) {
    console.error("Error creating data:", error);
    throw error;
  }
};

const updateData = async (path, data) => {
  try {
    const response = await apiClient.put(path, data);
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

const deleteData = async (path) => {
  try {
    const response = await apiClient.delete(path);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};


export { fetchData, createData, updateData, deleteData };
