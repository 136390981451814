import React, { useState, useEffect, useCallback, memo } from 'react';
import { MessageCircle, Edit, MoreHorizontal, Tags, UserPlus } from 'lucide-react';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { updateDescription } from '../../utils/resources';
import TextEditorModal from '../modals/TextEditorModal';
import { Button } from '@/components/ui/button';

const Modal = memo(({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
        ></div>
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle">
          &#8203;
        </span>
        <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 sm:align-middle">
          {children}
        </div>
      </div>
    </div>
  );
});

const Editor = memo(({ value, onChange }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      ['link', 'blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    keyboard: {
      bindings: {
        tab: false,
        'shift+tab': false,
      },
    },
  };

  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      theme="snow"
      modules={modules}
      className="h-64 mb-12"
      preserveWhitespace={true}
    />
  );
});

const DagDocumentation = (props) => {
  const { dagId } = useParams();
  const [loading, setLoading] = useState(false);
  const [documentation, setDocumentation] = useState(props.description);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempDocumentation, setTempDocumentation] = useState('');
  const [description, setDescription] = useState(props?.description || '');
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const handleOpenModal = useCallback(() => {
    setTempDocumentation(documentation);
    setIsModalOpen(true);
    setIsEditing(true);
    setError(null);
  }, [documentation]);

  const handleSaveDescription = async (newDescription) => {
    try {
      const body = {
        description: newDescription,
      };
      const res = await updateDescription(dagId, JSON.stringify(body));
      console.log(res);
      setDescription(newDescription);
    } catch (error) {
      console.error('Error updating description:', error);
    }
    setIsDescriptionOpen(false);
  };

  if (loading) {
    return (
      <div className="">
        <div className="max-w-lg animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="h-20 bg-gray-200 rounded w-full"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="max-w-lg">
        <div className="mt-4 border-b pb-4">
          <h3 className="font-medium text-gray-800 mb-2">Documentation</h3>
          {documentation ? (
            <div className="mt-2">
              <p
                className="text-sm mb-1"
                dangerouslySetInnerHTML={{ __html: documentation }}
              />
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsDescriptionOpen(true)}
              >
                <Edit className="h-4 w-4" />
                Edit description
              </Button>
            </div>
          ) : (
            <div className="mt-2 flex flex-col items-start">
              <span className="text-sm text-gray-400 mb-2">
                No documentation yet. Share your knowledge by adding documentation and
                links to helpful resources.
              </span>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsDescriptionOpen(true)}
              >
                <Edit className="h-4 w-4" />
                Add description
              </Button>
            </div>
          )}
        </div>

        {isDescriptionOpen && (
          <TextEditorModal
            content={description}
            isOpen={isDescriptionOpen}
            onClose={() => setIsDescriptionOpen(false)}
            title="Edit Description"
            onSave={handleSaveDescription}
            id_resource={dagId}
          />
        )}
      </div>
    </div>
  );
};

export default DagDocumentation;
