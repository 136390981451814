import './App.css';

import React, { useState } from 'react';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { SelectedProvider } from './context/SelectedContext';
import Domain from './pages/Domain';
import DagMain from './pages/DagMain';
import Reports from './pages/Reports';
import Settings from './pages/settings/Settings';
import Documents from './pages/Documents';
import MainLayout from './pages/MainLayout';
import LoadingBar from 'react-top-loading-bar';
import DagCreate from './components/dag/DagCreate';
import DomainAssets from './components/domain/DomainAssets';
import DomainDags from './components/domain/DomainDags';
import DomainMembers from './components/domain/DomainMembers';
import DomainHistory from './components/domain/DomainHistory';
import GCSAsset from './components/assets/GCSAsset';
import LookerAsset from './components/assets/LookerAsset';
import ModelAsset from './components/assets/ModelAsset';
import NotebookAsset from './components/assets/NotebookAsset';
import DatastudioAsset from './components/assets/DatastudioAsset';
import DocsAssetList from './components/assets/DocAssetList';
import DocsAsset from './components/assets/DocAsset';
import BqAsset from './components/assets/BqAsset';
import AssetMain from './components/assets/AssetMain';
import BqAssetProject from './components/assets/BqAssetProject';
import BqAssetProjectList from './components/assets/BqAssetProjectList';
import BqAssetDatasetList from './components/assets/BqAssetDatasetList';
import BqAssetDataset from './components/assets/BqAssetDataset';
import BqAssetTableList from './components/assets/BqAssetTableList';
import BqAssetTable from './components/assets/BqAssetTable';
import AccessSettings from './pages/settings/AccessSettings';
import GCPSettings from './pages/settings/GCPSettings';
import SecretSettings from './pages/settings/SecretSettings';
import TeamSettings from './pages/settings/TeamSettings';
import People from './pages/People';
import Search from './pages/Search';
import User from './pages/User';
import NotebookAssetList from './components/assets/NotebookAssetList';
import Chat from './pages/Chat';
import LookerAssetList from './components/assets/LookerAssetList';
import DatastudioAssetList from './components/assets/DatastudioAssetList';
import { AuthProvider } from './context/AuthContext';
import Login from './pages/Login';
import ProtectedRoute from './pages/Protected';
import { AlertProvider } from './context/AlertContext';
import { SuccessProvider } from './context/SuccessContext';
import AssetMainWrapper from './components/assets/AssetMainWrapper';
import IngestionSettings from './pages/settings/IngestionSetting';
import DagTaskItem from './components/dag/DagTaskItem';

const App = () => {
  const [progress, setProgress] = useState(0);

  const handleSetProgress = (newProgress) => {
    setProgress(newProgress);
  };

  return (
    <AuthProvider>
      <SuccessProvider>
        <AlertProvider>
          <SelectedProvider>
            <LoadingBar color="#6366f1" progress={progress} />
            <Router>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<ProtectedRoute />}>
                  <Route
                    exact
                    path="/"
                    element={
                      <MainLayout>
                        <Home setProgress={handleSetProgress} />
                      </MainLayout>
                    }
                  />
                  <Route
                    exact
                    path="/reports"
                    element={
                      <MainLayout>
                        <Reports />
                      </MainLayout>
                    }
                  />
                  <Route
                    exact
                    path="/users"
                    element={
                      <MainLayout>
                        <People />
                      </MainLayout>
                    }
                  />
                  <Route
                    exact
                    path="/settings"
                    element={
                      <MainLayout>
                        <Settings />
                      </MainLayout>
                    }
                  >
                    <Route index element={<Navigate replace to="teams" />} />
                    <Route path="access" element={<AccessSettings />} />
                    <Route path="gcp" element={<GCPSettings />} />
                    <Route path="secrets" element={<SecretSettings />} />
                    <Route path="ingestion" element={<IngestionSettings />} />
                    <Route path="teams" element={<TeamSettings />} />
                  </Route>

                  <Route
                    exact
                    path="/documents"
                    element={
                      <MainLayout>
                        <Documents />
                      </MainLayout>
                    }
                  />

                  {/* <Route exact path="domain/:domainId" element={<Domain />} /> */}

                  <Route path="domains/:domainId" element={<Domain />}>
                    <Route index element={<Navigate replace to="history" />} />
                    <Route path="assets" element={<DomainAssets />}>
                      <Route element={<AssetMain />}>
                        <Route path="bigquery" element={<BqAsset />}>
                          <Route path="projects" element={<BqAssetProjectList />} />
                          <Route
                            path="projects/:projectId"
                            element={<BqAssetProject />}
                          >
                            <Route path="datasets" element={<BqAssetDatasetList />} />
                            <Route
                              path="datasets/:datasetId"
                              element={<BqAssetDataset />}
                            >
                              <Route path="tables" element={<BqAssetTableList />} />
                              <Route
                                path="tables/:tableId"
                                element={<BqAssetTable />}
                              />
                            </Route>
                          </Route>
                        </Route>
                        <Route path="gcs" element={<GCSAsset />} />
                        <Route path="docs" element={<DocsAssetList />}></Route>
                        <Route path="docs/:docId" element={<DocsAsset />} />
                        <Route path="lookers" element={<LookerAssetList />} />
                        <Route path="lookers/:lookerId" element={<LookerAsset />} />
                        <Route
                          path="lookers/:lookerType/:lookerId"
                          element={<LookerAsset />}
                        />
                        <Route
                          path="lookers/lookerType/:lookerId"
                          element={<LookerAsset />}
                        />
                        <Route path="models" element={<ModelAsset />} />
                        <Route path="notebooks" element={<NotebookAssetList />} />
                        <Route
                          path="notebooks/:notebookId"
                          element={<NotebookAsset />}
                        />
                        <Route path="datastudios" element={<DatastudioAssetList />} />
                        <Route
                          path="datastudios/:datastudioId"
                          element={<DatastudioAsset />}
                        />
                      </Route>
                    </Route>
                    <Route
                      path="dags"
                      element={<DomainDags setProgress={handleSetProgress} />}
                    />
                    <Route path="members" element={<DomainMembers />} />
                    <Route path="history" element={<DomainHistory />} />
                  </Route>

                  <Route
                    exact
                    path="/domains/:domainId/createDag"
                    element={<DagCreate setProgress={handleSetProgress} />}
                  />
                  <Route
                    exact
                    path="/domains/:domainId/dags/:dagId/tasks/edit"
                    element={<DagMain setProgress={handleSetProgress} />}
                  />
                  <Route
                    exact
                    path="/domains/:domainId/dags/:dagId/tasks/:taskId/:taskReqTab"
                    element={<DagMain setProgress={handleSetProgress} />}
                  />
                  <Route
                    exact
                    path="/domains/:domainId/dags/:dagId/tasks/:taskId"
                    element={<DagTaskItem setProgress={handleSetProgress} />}
                  />
                  <Route
                    exact
                    path="/domains/:domainId/dags/:dagId/:reqTab"
                    element={<DagMain setProgress={handleSetProgress} />}
                  />

                  <Route
                    exact
                    path="/domains/:domainId/dags/:dagId/"
                    element={<DagMain setProgress={handleSetProgress} />}
                  />

                  <Route
                    exact
                    path="domains/:domainId/createDag"
                    element={<DagMain />}
                  />

                  <Route
                    exact
                    path="/search"
                    element={<Search setProgress={handleSetProgress} />}
                  />

                  <Route
                    exact
                    path="/chat"
                    element={<Chat setProgress={handleSetProgress} />}
                  />

                  <Route
                    exact
                    path="/user/:userId"
                    element={<User setProgress={handleSetProgress} />}
                  />
                  <Route path="/assets" element={<AssetMainWrapper />}>
                    <Route path="bigquery" element={<BqAsset />}>
                      <Route path="projects" element={<BqAssetProjectList />} />
                      <Route path="projects/:projectId" element={<BqAssetProject />}>
                        <Route path="datasets" element={<BqAssetDatasetList />} />
                        <Route path="datasets/:datasetId" element={<BqAssetDataset />}>
                          <Route path="tables" element={<BqAssetTableList />} />
                          <Route path="tables/:tableId" element={<BqAssetTable />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="gcs" element={<GCSAsset />} />
                    <Route path="docs" element={<DocsAssetList />}></Route>
                    <Route path="docs/:docId" element={<DocsAsset />} />
                    <Route path="lookers" element={<LookerAssetList />} />
                    <Route
                      path="looker_dashboard/:lookerId"
                      element={<LookerAsset />}
                    />
                    <Route path="looker_chart/:lookerId" element={<LookerAsset />} />
                    <Route path="models" element={<ModelAsset />} />
                    <Route path="notebooks" element={<NotebookAssetList />} />
                    <Route path="notebooks/:notebookId" element={<NotebookAsset />} />
                    <Route path="datastudios" element={<DatastudioAssetList />} />
                    <Route
                      path="datastudios/:datastudioId"
                      element={<DatastudioAsset />}
                    />
                  </Route>
                </Route>

                {/* <Route exact path="/:domain/dag/:dagId" element={Dag} /> */}
              </Routes>
            </Router>
          </SelectedProvider>
        </AlertProvider>
      </SuccessProvider>
    </AuthProvider>
  );
};

export default App;
