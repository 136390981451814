import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { getDagTaskRuns, getDagTask, fetchDag } from '../../utils/dag';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  SheetClose,
} from '@/components/ui/sheet';

import { ArrowLeft, CheckCircle, XCircle, Loader2, Tags } from 'lucide-react';

import { Button } from '@/components/ui/button';
import DagTaskItemConf from './DagTaskItemConf';
import TaskRunDetail from './TaskRunDetail';
import TaskDocumentation from './TaskDocumentation';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import DagHeader from '../headers/DagHeader';
import useAuth from '@/context/useAuth';
import { getResourceTags } from '@/utils/tags';
import { useToast } from '@/hooks/use-toast';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import TagModal from '../common/Tags';
import DomainHeader from '../headers/DomainHeader';

const statusStyles = {
  DISABLED: {
    bg: 'bg-gray-100 hover:bg-gray-200/80',
    text: 'text-gray-800',
    badge: 'bg-gray-100 text-gray-800 border-gray-200',
  },
  RUNNING: {
    bg: 'bg-green-100 hover:bg-green-200/80',
    text: 'text-green-800',
    badge: 'bg-green-100 text-green-800 border-green-200 animate-pulse',
  },
  EDITING: {
    bg: 'bg-orange-100 hover:bg-orange-200/80',
    text: 'text-orange-800',
    badge: 'bg-orange-100 text-orange-800 border-orange-200',
  },
  DELETED: {
    bg: 'bg-red-100 hover:bg-red-200/80',
    text: 'text-red-800',
    badge: 'bg-red-100 text-red-800 border-red-200',
  },
  FAILED: {
    bg: 'bg-red-100 hover:bg-red-200/80',
    text: 'text-red-800',
    badge: 'bg-red-100 text-red-800 border-red-200',
  },
  SCHEDULED: {
    bg: 'bg-blue-100 hover:bg-blue-200/80',
    text: 'text-blue-800',
    badge: 'bg-blue-100 text-blue-800 border-blue-200',
  },
};

const StatusBadge = ({ status }) => {
  const style = statusStyles[status] || statusStyles.DISABLED;

  return (
    <Badge variant="outline" className={cn('capitalize', style.badge)}>
      {status.toLowerCase()}
    </Badge>
  );
};

const TagBadge = ({ tag, onClick }) => (
  <HoverCard>
    <HoverCardTrigger asChild>
      <Badge
        variant="outline"
        className={cn(
          'cursor-pointer transition-colors hover:bg-secondary/80',
          tag.color,
          tag.text_color
        )}
        onClick={onClick}
      >
        {tag.name}
      </Badge>
    </HoverCardTrigger>
    <HoverCardContent className="w-48">
      <div className="space-y-1">
        <p className="text-sm font-medium">{tag.name}</p>
        {tag.description && (
          <p className="text-sm text-muted-foreground">{tag.description}</p>
        )}
      </div>
    </HoverCardContent>
  </HoverCard>
);

const DagTaskItem = () => {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('configuration');
  const [runs, setRuns] = useState([]);
  const [selectedRun, setSelectedRun] = useState(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [taskData, setTaskData] = useState(null);
  const { user, hasPermission } = useAuth();
  const [dagData, setDagData] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTagModal, setShowTagModal] = useState(false);
  const { toast } = useToast();

  const { dagId, domainId, taskId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [task, dag, resourceTags] = await Promise.all([
          getDagTask(dagId, taskId),
          fetchDag(dagId),
          getResourceTags(taskId),
        ]);
        setTaskData(task);
        setDagData(dag);
        setSelectedTags(resourceTags);
        fetchRuns(taskId);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast({
          title: 'Error',
          description: 'Failed to load task information',
          variant: 'destructive',
        });
      }
    };

    fetchData();
  }, [dagId, taskId]);

  const fetchRuns = async (id_task) => {
    try {
      let result = await getDagTaskRuns(id_task);
      result.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      setRuns(result);
    } catch (error) {
      console.error('Fetch error: ', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchResourceTagList = async () => {
    try {
      const resourceTags = await getResourceTags(taskId);
      setSelectedTags(resourceTags);
    } catch (error) {
      toast({
        title: 'Error fetching tags',
        description: 'Failed to load task tags.',
        variant: 'destructive',
      });
    }
  };

  const handleSelectRun = (run) => {
    setSelectedRun(run);
    setIsSheetOpen(true);
  };

  const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diff = endDate - startDate;
    return diff > 0 ? `${(diff / 1000).toFixed(2)} seconds` : 'N/A';
  };

  const truncateMessage = (message) => {
    if (message && message.length > 70) {
      return message.substring(0, 70) + '...';
    }
    return message || '';
  };

  if (loading || !taskData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  const breadcrumbs = [
    {
      label: 'dags',
      link: `/domains/${domainId}/dags`,
    },
    {
      label: dagData?.dag_name || 'DAG',
      link: `/domains/${domainId}/dags/${dagId}`,
    },
    {
      label: 'tasks',
      link: `/domains/${domainId}/dags/${dagId}/tasks`,
    },
    {
      label: taskData?.task_name || 'Task',
    },
  ];

  return (
    <div className="min-h-screen bg-background">
      <DomainHeader user={user} additionalBreadcrumbs={breadcrumbs} />
      <div className="px-32 py-6">
        <div className="space-y-4">
          <div>
            <h1 className="text-2xl font-semibold tracking-tight">
              {taskData.task_name}
            </h1>
            <div className="flex items-center space-x-2">
              <StatusBadge status={taskData.status} />
              <div className="text-sm text-muted-foreground">
                Part of {dagData.dag_name}
              </div>
            </div>
            <div className="flex items-center space-x-2 mt-2">
              {selectedTags.map((tag) => (
                <TagBadge
                  key={tag.id}
                  tag={tag}
                  onClick={() => navigate(`/search?filter=labels:${tag.name}`)}
                />
              ))}
              <Button variant="outline" size="sm" onClick={() => setShowTagModal(true)}>
                <Tags className="h-4 w-4 mr-1" />
                {selectedTags.length ? 'Edit Tags' : 'Add Tags'}
              </Button>
            </div>{' '}
          </div>

          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList>
              <TabsTrigger value="configuration">Configuration</TabsTrigger>
              <TabsTrigger value="runs">Run History</TabsTrigger>
              <TabsTrigger value="documentation">Documentation</TabsTrigger>
            </TabsList>

            <TabsContent value="runs" className="mt-4">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Status</TableHead>
                    <TableHead>Start Date</TableHead>
                    <TableHead>End Date</TableHead>
                    <TableHead>Duration</TableHead>
                    <TableHead>Message</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {runs.length > 0 ? (
                    runs.map((run) => (
                      <TableRow
                        key={run.id_dag_task_run}
                        onClick={() => handleSelectRun(run)}
                        className="cursor-pointer hover:bg-gray-50"
                      >
                        <TableCell className="flex items-center">
                          {run.status === 'FAILED' ? (
                            <XCircle className="h-5 w-5 text-red-500 inline-block mr-2" />
                          ) : (
                            <CheckCircle className="h-5 w-5 text-green-500 inline-block mr-2" />
                          )}
                          <span>{run.status}</span>
                        </TableCell>
                        <TableCell>
                          {format(new Date(run.start_ts), 'yyyy-MM-dd HH:mm:ss')}
                        </TableCell>
                        <TableCell>
                          {format(new Date(run.end_ts), 'yyyy-MM-dd HH:mm:ss')}
                        </TableCell>
                        <TableCell>
                          {calculateDuration(run.start_ts, run.end_ts)}
                        </TableCell>
                        <TableCell>{truncateMessage(run.error_msg)}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} className="text-center">
                        No runs found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TabsContent>

            <TabsContent value="configuration" className="mt-4">
              <DagTaskItemConf task={taskData} onClose={() => {}} />
            </TabsContent>

            <TabsContent value="documentation" className="mt-4">
              <TaskDocumentation description={taskData.description} />
            </TabsContent>
          </Tabs>
        </div>

        {/* Sheet for Run Details */}
        <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
          <SheetContent position="right" size="lg">
            <SheetHeader>
              <SheetTitle>Run Details</SheetTitle>
              <SheetDescription>
                Details for run {selectedRun?.id_dag_task_run}
              </SheetDescription>
            </SheetHeader>
            {selectedRun && (
              <TaskRunDetail run={selectedRun} onClose={() => setIsSheetOpen(false)} />
            )}
            <SheetClose asChild>
              <Button variant="ghost" className="absolute top-2 right-2">
                Close
              </Button>
            </SheetClose>
          </SheetContent>
        </Sheet>
      </div>
      {showTagModal && (
        <TagModal
          resource_id={taskId}
          onClose={() => {
            setShowTagModal(false);
            fetchResourceTagList();
          }}
        />
      )}
    </div>
  );
};

export default DagTaskItem;
