import React from 'react';
import { useParams } from 'react-router-dom';
import MemberList from './MemberList';

const DomainMembers = () => {
  const { domainId } = useParams();

  return (
    <div className="max-w-4xl">
      <MemberList domain={domainId} />
    </div>
  );
};

export default DomainMembers;
