import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ArrowUpDown, MoreHorizontal, Play, Ban, Check } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import cronParser from 'cron-parser';

const getNextRunTime = (schedule) => {
  try {
    const interval = cronParser.parseExpression(schedule);
    const nextRunDate = interval.next().toDate();
    return `in ${formatDistanceToNow(nextRunDate)}`;
  } catch (err) {
    console.error('Error parsing cron string:', err);
    return 'Invalid cron schedule';
  }
};

const getStatusColor = (status) => {
  const colors = {
    EDITING: 'bg-orange-100 text-orange-800',
    FAILED: 'bg-red-100 text-red-800',
    RUNNING: 'bg-green-100 text-green-800',
    SCHEDULED: 'bg-blue-100 text-blue-800',
    DISABLED: 'bg-gray-100 text-gray-800',
  };
  return colors[status] || 'bg-gray-100 text-gray-800';
};

export const columns = ({ domainId, navigate, toggleDag, triggerDomainDag }) => [
  {
    accessorKey: 'dag.dag_name',
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Name
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => (
      <div
        className="cursor-pointer hover:text-primary"
        onClick={() =>
          navigate(`/domains/${domainId}/dags/${row.original.dag.id_dag}/tasks`)
        }
      >
        {row.original.dag.dag_name}
      </div>
    ),
  },
  {
    accessorKey: 'dag.schedule',
    header: 'Schedule',
  },
  {
    accessorKey: 'dag.status',
    header: 'Status',
    cell: ({ row }) => (
      <span
        className={`inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium ${getStatusColor(
          row.original.dag.status
        )}`}
      >
        {row.original.dag.status.toLowerCase()}
      </span>
    ),
  },
  {
    accessorKey: 'dag.created_by',
    header: 'Created By',
  },
  {
    accessorKey: 'summary',
    header: 'Last Successful Run',
    cell: ({ row }) => {
      const summary = row.original.summary;
      return summary?.last_successful_run
        ? formatDistanceToNow(new Date(summary.last_successful_run), {
            addSuffix: true,
          })
        : '-';
    },
  },
  {
    header: 'Dag Runs',
    cell: ({ row }) => {
      const summary = row.original.summary;
      return (
        <div className="flex gap-2">
          <div
            className={`flex h-7 w-7 items-center justify-center rounded-lg border border-green-400 ${
              summary?.statuses.success > 0 ? 'font-medium' : ''
            }`}
          >
            {summary?.statuses.success || 0}
          </div>
          <div
            className={`flex h-7 w-7 items-center justify-center rounded-lg border border-red-400 ${
              summary?.statuses.failed > 0 ? 'font-medium' : ''
            }`}
          >
            {summary?.statuses.failed || 0}
          </div>
          <div
            className={`flex h-7 w-7 items-center justify-center rounded-lg border border-yellow-400 ${
              summary?.statuses.running > 0 ? 'font-medium' : ''
            }`}
          >
            {summary?.statuses.running || 0}
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: 'dag.schedule',
    header: 'Next Run',
    cell: ({ row }) => getNextRunTime(row.original.dag.schedule),
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      const dag = row.original.dag;
      const isEditing = dag.status === 'EDITING';
      const isDisabled = dag.status === 'DISABLED';

      if (isEditing) return null;

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            {isDisabled ? (
              <DropdownMenuItem
                onClick={() => toggleDag('enable', domainId, dag.id_dag)}
              >
                <Check className="mr-2 h-4 w-4" />
                Enable
              </DropdownMenuItem>
            ) : (
              <>
                <DropdownMenuItem
                  onClick={() => toggleDag('disable', domainId, dag.id_dag)}
                >
                  <Ban className="mr-2 h-4 w-4" />
                  Disable
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => triggerDomainDag(domainId, dag.id_dag)}
                >
                  <Play className="mr-2 h-4 w-4" />
                  Trigger
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];
