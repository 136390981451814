import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { SiLooker } from "react-icons/si";
import { fetchData } from '../../utils/baseRequest';
import { cn } from "@/lib/utils";

import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";

export default function LookerAssetList() {
    const [lookerAssets, setLookerAssets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [gdocsUrl, setGdocsUrl] = useState("");
    const [docName, setDocName] = useState("");
    const { domainId } = useParams();

    // Search and Pagination States
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust as needed

    const handleGdocsImport = async () => {
        if (!gdocsUrl || !docName) {
            setError("Please provide both Document URL and Name.");
            return;
        }

        try {
            const response = await fetch(`http://localhost:8080/resources/${domainId}/lookers/import/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ url: gdocsUrl, name: docName }),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Optionally, handle the response data
            const result = await response.json();
            console.log("Imported Looker Asset:", result); // Debugging line
            // Refresh the asset list after import
            fetchLookerAssets();
            setGdocsUrl("");
            setDocName("");
            setError(null); // Clear any previous errors
        } catch (error) {
            console.error("Error while importing Looker asset:", error);
            setError("Failed to import Looker asset.");
        }
    };

    useEffect(() => {
        fetchLookerAssets();
    }, [domainId]);

    const fetchLookerAssets = async () => {
        setLoading(true);
        setError(null);
        try {
            // Fetch both dashboards and charts
            const [dashboardsResponse, chartsResponse] = await Promise.all([
                fetchData(`/resources/${domainId}/looker_dashboard/`),
                fetchData(`/resources/${domainId}/looker_chart/`)
            ]);

            if (!dashboardsResponse || !chartsResponse) {
                throw new Error(`Failed to fetch Looker assets.`);
            }

            // Combine and format the data
            const combinedAssets = [
                ...dashboardsResponse.map(dashboard => ({
                    ...dashboard,
                    type: 'Dashboard',
                    displayName: dashboard.resource_name || dashboard.name,
                    id: dashboard.dashboard_id,
                })),
                ...chartsResponse.map(chart => ({
                    ...chart,
                    type: 'Chart',
                    displayName: chart.resource_name || chart.name,
                    id: chart.look_id, // Ensure 'look_id' is correct; may need to be 'chart_id'
                }))
            ];

            console.log("Combined Looker Assets:", combinedAssets); // Debugging line
            setLookerAssets(combinedAssets);
        } catch (error) {
            console.error("Error fetching Looker assets:", error);
            setError("Failed to fetch Looker assets.");
        } finally {
            setLoading(false);
        }
    };

    // Filtered Assets based on Search Term
    const filteredAssets = useMemo(() => {
        return lookerAssets.filter(asset =>
            asset.displayName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [lookerAssets, searchTerm]);

    // Pagination Logic
    const totalPages = Math.ceil(filteredAssets.length / itemsPerPage);
    const paginatedAssets = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredAssets.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredAssets, currentPage, itemsPerPage]);

    // Handle Page Change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (loading) return <div className="py-4 text-center">Loading...</div>;
    if (error) return (
        <Alert variant="destructive" className="my-4">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
        </Alert>
    );

    return (
        <div className="min-h-screen">
            {/* Looker Assets List Card */}
            <Card>
                <CardHeader className="flex flex-col sm:flex-row justify-between items-center mb-4">
                    <CardTitle className="text-lg">Looker Assets</CardTitle>
                    <div className="mt-4 sm:mt-0 flex flex-col sm:flex-row items-center gap-4">
                        {/* Search Input */}
                        <Input
                            type="text"
                            placeholder="Search assets..."
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setCurrentPage(1); // Reset to first page on search
                            }}
                            className="w-full sm:w-64 text-sm"
                        />
                        {/* Optionally, you can add the "Add New" or "Import" buttons here */}
                    </div>
                </CardHeader>
                <CardContent>
                    {paginatedAssets.length > 0 ? (
                        <>
                            <ul className="divide-y divide-gray-200">
                                {paginatedAssets.map((asset) => (
                                    <li key={asset.id} className="flex items-center justify-between p-4 bg-white rounded-md shadow-sm mb-2">
                                        <div className="flex items-center">
                                            <SiLooker className="mr-3 text-2xl text-blue-500" />
                                            <div>
                                                <Link
                                                    to={`/domains/${domainId}/assets/lookers/looker_${asset.type}/${asset.id_resource}`}
                                                    className="text-sm font-medium text-gray-800 hover:text-blue-600 hover:underline"
                                                >
                                                    {asset.displayName}
                                                </Link>
                                                <div className="text-xs text-gray-500">
                                                    {asset.type}
                                                </div>
                                            </div>
                                        </div>
                                        <Badge variant="secondary" className="text-xs font-normal">
                                            Owner: {asset.owner || 'Unknown'}
                                        </Badge>
                                    </li>
                                ))}
                            </ul>

                            {/* Pagination Controls */}
                            <div className="flex justify-center mt-6 space-x-2">
                                <Button
                                    variant="ghost"
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    className={cn(
                                        "px-3 py-2 rounded-md border border-gray-300 text-sm font-medium",
                                        currentPage === 1 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
                                    )}
                                >
                                    Previous
                                </Button>
                                <Button
                                    variant="ghost"
                                    disabled={currentPage === totalPages || totalPages === 0}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    className={cn(
                                        "px-3 py-2 rounded-md border border-gray-300 text-sm font-medium",
                                        currentPage === totalPages || totalPages === 0 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
                                    )}
                                >
                                    Next
                                </Button>
                            </div>
                        </>
                    ) : (
                        <p className="text-gray-500">No Looker assets found.</p>
                    )}
                </CardContent>
            </Card>
        </div>
    )
};
