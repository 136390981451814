import { Outlet } from 'react-router-dom';
import AssetInfo from './AssetInfo';

export default function BqAssetDataset() {
    return (
        <div className="grid grid-cols-4 gap-4">
            <div className="col-span-3">
                <Outlet />
            </div>
            <div className="col-span-1">
                <AssetInfo />
            </div>
        </div>
    );
}
