import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Search, Play } from 'lucide-react';
import { Checkbox } from '@/components/ui/checkbox';

export function TaskDataTable({
  columns,
  data,
  onRowSelectionChange,
  selectedRowIds = [],
  expandedState = {},
  onTriggerSelected,
  dagStatus,
}) {
  const [sorting, setSorting] = React.useState([]);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [expanded, setExpanded] = React.useState(expandedState);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [rowSelection, setRowSelection] = React.useState({});

  const handleRowSelectionChange = (newSelection) => {
    const cleanSelection = Object.entries(newSelection).reduce((acc, [key, value]) => {
      if (value === true) {
        acc[key] = true;
      }
      return acc;
    }, {});

    setRowSelection(cleanSelection);
    const selectedIds = Object.keys(cleanSelection);
    onRowSelectionChange(selectedIds);
  };

  const getParentRows = (task) => {
    if (!task.parent_nodes?.length) return [];
    return data.filter((t) => task.parent_nodes.includes(t.id_task));
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    state: {
      sorting,
      columnFilters,
      expanded,
      rowSelection,
      globalFilter,
    },
    getSubRows: (row) => {
      return getParentRows(row);
    },
    onRowSelectionChange: handleRowSelectionChange,
    globalFilterFn: (row, columnId, filterValue) => {
      const value = row.getValue(columnId);
      return String(value).toLowerCase().includes(filterValue.toLowerCase());
    },
  });

  const isRowSelectable = (row) => {
    return !row.child_nodes?.length;
  };

  const handleSelectAll = (checked) => {
    const selectableRows = data.filter(isRowSelectable);
    const newSelection = {};

    if (checked) {
      selectableRows.forEach((row) => {
        newSelection[row.id_task] = true;
      });
    }

    handleRowSelectionChange(newSelection);
  };

  const areAllSelectableRowsSelected = () => {
    const selectableRows = data.filter(isRowSelectable);
    return (
      selectableRows.length > 0 &&
      selectableRows.every((row) => rowSelection[row.id_task])
    );
  };

  const getSelectedCount = () => Object.keys(rowSelection).length;

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <div className="relative flex-1">
          <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
          <Input
            placeholder="Search tasks..."
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="pl-8"
          />
        </div>
        {getSelectedCount() > 0 && dagStatus !== 'DISABLED' && (
          <Button
            onClick={() => {
              const selectedTasks = data.filter((task) => rowSelection[task.id_task]);
              onTriggerSelected(selectedTasks);
            }}
            variant="default"
          >
            <Play className="h-4 w-4 mr-2" />
            Trigger Selected ({getSelectedCount()})
          </Button>
        )}
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.column.id === 'select' ? (
                      <Checkbox
                        checked={areAllSelectableRowsSelected()}
                        onCheckedChange={handleSelectAll}
                        disabled={dagStatus === 'EDITING'}
                        aria-label="Select all"
                      />
                    ) : (
                      flexRender(header.column.columnDef.header, header.getContext())
                    )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {cell.column.id === 'select' ? (
                        <Checkbox
                          checked={rowSelection[row.original.id_task] || false}
                          onCheckedChange={(checked) => {
                            const newSelection = { ...rowSelection };
                            if (checked) {
                              newSelection[row.original.id_task] = true;
                            } else {
                              delete newSelection[row.original.id_task];
                            }
                            handleRowSelectionChange(newSelection);
                          }}
                          disabled={
                            dagStatus === 'EDITING' || !isRowSelectable(row.original)
                          }
                          aria-label="Select row"
                        />
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No tasks found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
