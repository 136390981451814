// src/pages/People.jsx

import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash'; // Import debounce from lodash
import {
  Search,
  Users2,
  Mail,
  Building2,
  SlidersHorizontal,
  ArrowUpDown,
  Check,
  X,
} from 'lucide-react';
import { getAllUsers } from '../utils/user';
import UserIcon from '../components/user/UserIcon';

import { cn } from '@/lib/utils'; // Ensure this utility function is correctly imported

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';

export default function People() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [usersPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [availableDomains, setAvailableDomains] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    const debouncedFetch = debounce(() => {
      fetchUsers();
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [currentPage, searchTerm, selectedDomains]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const fetchUsers = async () => {
    if (initialLoading) {
      setIsLoading(true);
    }

    try {
      const response = await getAllUsers(currentPage, usersPerPage, searchTerm);
      if (Array.isArray(response.users)) {
        setUsers(response.users);
        setTotalUsers(response.total);

        const domains = new Set();
        response.users.forEach((user) => {
          if (user.roles) {
            Object.keys(user.roles).forEach((domain) => domains.add(domain));
          }
        });
        setAvailableDomains(Array.from(domains));
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      if (initialLoading) {
        setIsLoading(false);
        setInitialLoading(false);
      }
    }
  };

  const handleSort = (column) => {
    setSortDirection(sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc');
    setSortColumn(column);
  };

  // Use useMemo to optimize sorting
  const sortedUsers = useMemo(() => {
    return [...users].sort((a, b) => {
      const modifier = sortDirection === 'asc' ? 1 : -1;
      if (a[sortColumn] > b[sortColumn]) return modifier;
      if (a[sortColumn] < b[sortColumn]) return -modifier;
      return 0;
    });
  }, [users, sortColumn, sortDirection]);

  // Use useMemo to optimize filtering
  const filteredUsers = useMemo(() => {
    return sortedUsers.filter((user) => {
      if (selectedDomains.length === 0) return true;
      return selectedDomains.some((domain) => user.roles && user.roles[domain]);
    });
  }, [sortedUsers, selectedDomains]);

  const UserProfileCard = ({ user }) => (
    <HoverCard>
      <HoverCardTrigger asChild>
        <Link
          to={`/user/${user.user_email}`}
          className="font-medium hover:text-primary transition-colors relative z-10" // Add z-index
        >
          <div className="flex items-center gap-3">
            <UserIcon name={user.name} imgUrl={user.img_url} size="sm" />
            {user.name}
          </div>
        </Link>
      </HoverCardTrigger>
      <HoverCardContent className="w-80 z-[60]">
        {' '}
        {/* Increase z-index */}
        <div className="flex justify-between space-x-4">
          <div className="space-y-1">
            <h4 className="text-sm font-semibold">{user.name}</h4>
            <p className="text-sm text-muted-foreground">
              <Mail className="h-3 w-3 inline-block mr-1" />
              {user.user_email}
            </p>
            <div className="flex flex-wrap gap-1 mt-2">
              {user.roles &&
                Object.entries(user.roles).map(([domain, role], idx) => (
                  <Badge key={idx} variant="outline" className="text-xs">
                    {domain} • {role}
                  </Badge>
                ))}
            </div>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );

  const LoadingSkeleton = () => (
    <div className="space-y-3">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="flex items-center space-x-4">
          <Skeleton className="h-12 w-12 rounded-full" />
          <div className="space-y-2">
            <Skeleton className="h-4 w-[250px]" />
            <Skeleton className="h-4 w-[200px]" />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="">
      <Card className="border-0 shadow-lg">
        <CardHeader className="border rounded-t-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Users2 className="h-5 w-5 text-primary" />
              <div>
                <CardTitle>People</CardTitle>
                <CardDescription>
                  Manage and view all users in the system
                </CardDescription>
              </div>
            </div>
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="outline" className="gap-2">
                  <SlidersHorizontal className="h-4 w-4" />
                  Filters
                </Button>
              </DialogTrigger>
              <DialogContent className="z-[100]">
                <DialogHeader>
                  <DialogTitle>Filter Users</DialogTitle>
                  <DialogDescription>
                    Select domains to filter the user list
                  </DialogDescription>
                </DialogHeader>
                <ScrollArea className="h-[300px] rounded-md border p-4">
                  <div className="space-y-4">
                    {availableDomains.map((domain) => (
                      <div key={domain} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          checked={selectedDomains.includes(domain)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedDomains([...selectedDomains, domain]);
                            } else {
                              setSelectedDomains(
                                selectedDomains.filter((d) => d !== domain)
                              );
                            }
                          }}
                          id={`domain-${domain}`}
                          className="checkbox"
                        />
                        <label
                          htmlFor={`domain-${domain}`}
                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          {domain}
                        </label>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </DialogContent>
            </Dialog>
          </div>
        </CardHeader>
        <CardContent className="p-6">
          <div className="flex gap-4 mb-6">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
              <Input
                placeholder="Search by name or email..."
                className="pl-10"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="gap-2">
                  <ArrowUpDown className="h-4 w-4" />
                  Sort
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-48">
                <DropdownMenuLabel>Sort by</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {['name', 'user_email'].map((column) => (
                  <DropdownMenuCheckboxItem
                    key={column}
                    checked={sortColumn === column}
                    onCheckedChange={() => handleSort(column)}
                  >
                    {column === 'user_email' ? 'Email' : 'Name'}
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          {isLoading ? (
            <LoadingSkeleton />
          ) : (
            <>
              {filteredUsers.length === 0 ? (
                <div className="text-center py-4">
                  No users found matching your search criteria.
                </div>
              ) : (
                <div className="relative rounded-lg border">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[300px]">User</TableHead>
                        <TableHead>Email</TableHead>
                        <TableHead>Domains & Roles</TableHead>
                        <TableHead className="w-[100px]">Status</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredUsers.map((user) => (
                        <TableRow key={user.user_email}>
                          <TableCell>
                            <UserProfileCard user={user} />
                          </TableCell>
                          <TableCell className="text-muted-foreground">
                            <div className="flex items-center gap-2">
                              <Mail className="h-4 w-4" />
                              {user.user_email}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex flex-wrap gap-1">
                              {user.roles &&
                                Object.entries(user.roles).map(
                                  ([domain, role], idx) => (
                                    <HoverCard key={idx}>
                                      <HoverCardTrigger asChild>
                                        <Badge
                                          variant="secondary"
                                          className="cursor-help"
                                        >
                                          <Building2 className="h-3 w-3 mr-1" />
                                          {domain}
                                        </Badge>
                                      </HoverCardTrigger>
                                      <HoverCardContent className="w-48">
                                        <p className="text-sm font-semibold">
                                          {domain}
                                        </p>
                                        <p className="text-sm text-muted-foreground">
                                          Role: {role}
                                        </p>
                                      </HoverCardContent>
                                    </HoverCard>
                                  )
                                )}
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
            </>
          )}

          <div className="mt-6 flex items-center justify-between">
            <p className="text-sm text-muted-foreground">
              Showing {filteredUsers.length} of {totalUsers} users
            </p>
            <div className="flex items-center space-x-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span>
                Page {currentPage} of {Math.ceil(totalUsers / usersPerPage) || 1}
              </span>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage((prev) => prev + 1)}
                disabled={currentPage * usersPerPage >= totalUsers}
              >
                Next
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
