import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Bot, Copy, Check, PanelRightClose } from 'lucide-react';
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { dracula } from '@uiw/codemirror-theme-dracula';

const CodeArtifactViewer = ({ 
    open, 
    onOpenChange, 
    code, 
    language = 'javascript',
    readOnly = true
}) => {
    const [copied, setCopied] = useState(false);
    const [value, setValue] = useState(code);
    
    useEffect(() => {
        setValue(code);
    }, [code]);

    const handleCopy = () => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const getLanguageExtension = (lang) => {
        switch (lang.toLowerCase()) {
            case 'javascript':
            case 'js':
                return langs.javascript();
            case 'jsx':
                return langs.jsx();
            case 'typescript':
            case 'ts':
                return langs.typescript();
            case 'tsx':
                return langs.tsx();
            case 'python':
                return langs.python();
            case 'html':
                return langs.html();
            case 'css':
                return langs.css();
            case 'markdown':
            case 'md':
                return langs.markdown();
            case 'json':
                return langs.json();
            case 'sql':
                return langs.sql();
            default:
                return langs.javascript();
        }
    };

    const panelAnimation = {
        open: {
            width: "600px",
            x: 0,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 30
            }
        },
        closed: {
            width: "0px",
            x: "100%",
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 30
            }
        }
    };

    const containerAnimation = {
        open: {
            marginRight: "600px",
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 30
            }
        },
        closed: {
            marginRight: "0px",
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 30
            }
        }
    };
  
    return (
        <>
            <motion.div
                className="absolute inset-0 pointer-events-none"
                animate={open ? "open" : "closed"}
                variants={containerAnimation}
            />

            <AnimatePresence>
                {open && (
                    <motion.div
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={panelAnimation}
                        className="fixed top-0 right-0 h-full bg-white dark:bg-zinc-900 border-l border-zinc-200 dark:border-zinc-800 shadow-lg z-50"
                    >
                        <div className="flex flex-col h-full">
                            <div className="flex items-center justify-between border-b border-zinc-200 dark:border-zinc-800 p-4">
                                <div className="flex items-center gap-2">
                                    <Bot className="h-5 w-5" />
                                    <span className="font-medium">Code Artifact</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Badge variant="secondary" className="text-xs capitalize">
                                        {language}
                                    </Badge>
                                    <Button
                                        size="icon"
                                        variant="ghost"
                                        onClick={handleCopy}
                                        className="h-8 w-8"
                                    >
                                        {copied ? (
                                            <Check className="h-4 w-4" />
                                        ) : (
                                            <Copy className="h-4 w-4" />
                                        )}
                                    </Button>
                                    <Button
                                        size="icon"
                                        variant="ghost"
                                        onClick={() => onOpenChange(false)}
                                        className="h-8 w-8"
                                    >
                                        <PanelRightClose className="h-4 w-4" />
                                    </Button>
                                </div>
                            </div>
                            <div className="flex-1 overflow-auto">
                                <CodeMirror
                                    value={value}
                                    height="100%"
                                    theme={dracula}
                                    extensions={[getLanguageExtension(language)]}
                                    onChange={(val) => setValue(val)}
                                    readOnly={readOnly}
                                    className="h-full"
                                    basicSetup={{
                                        lineNumbers: true,
                                        highlightActiveLineGutter: true,
                                        highlightSpecialChars: true,
                                        foldGutter: true,
                                        drawSelection: true,
                                        dropCursor: true,
                                        allowMultipleSelections: true,
                                        indentOnInput: true,
                                        bracketMatching: true,
                                        closeBrackets: true,
                                        autocompletion: true,
                                        rectangularSelection: true,
                                        crosshairCursor: true,
                                        highlightActiveLine: true,
                                        highlightSelectionMatches: true,
                                        closeBracketsKeymap: true,
                                        defaultKeymap: true,
                                        searchKeymap: true,
                                        historyKeymap: true,
                                        foldKeymap: true,
                                        completionKeymap: true,
                                        lintKeymap: true,
                                    }}
                                />
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default CodeArtifactViewer;