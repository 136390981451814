import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Notebook } from 'lucide-react';
import { ChevronRight } from 'lucide-react';
import ReactQuill from 'react-quill';
import { fetchData } from '@/utils/baseRequest';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent } from '@/components/ui/card';
import { fetchResource } from '@/utils/resources';

export default function NotebookAsset() {
  const [notebook, setNotebook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { domainId, notebookId } = useParams();

  useEffect(() => {
    const fetchNotebook = async () => {
      try {
        const data = await fetchResource('notebook', notebookId);
        console.log('Fetched Notebook Data:', data);
        setNotebook(data);
      } catch (error) {
        console.error('Error fetching notebook:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchNotebook();
  }, [notebookId]);

  if (loading) return <div className="py-4 text-center">Loading...</div>;
  if (error)
    return (
      <div className="py-4 text-center text-red-500">
        Error fetching notebook: {error}
      </div>
    );

  return (
    <div className="min-h-screen px-4">
      <Breadcrumb asset={notebook} />

      <Card className="bg-white rounded-lg shadow-sm p-4">
        <CardContent>
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center">
              <Notebook className="mr-3 text-2xl text-blue-400" />
              <div>
                <h1 className="text-lg font-semibold text-gray-900">
                  {notebook?.resource_name || 'No Resource Name'}
                </h1>
                <span className="text-xs text-gray-500">Notebook</span>
              </div>
            </div>

            {notebook?.url && (
              <a
                href={notebook.url}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Open Notebook
              </a>
            )}
          </div>

          <div className="grid grid-cols-1 gap-6">
            <div className="border-t border-gray-200 pt-4">
              <h3 className="text-sm font-medium text-gray-500 mb-2">Description</h3>
              <ReactQuill
                value={notebook?.description || 'No documentation yet.'}
                readOnly={true}
                theme={'snow'}
                modules={{ toolbar: false }}
                className="text-sm"
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

function Breadcrumb({ asset }) {
  const { notebookId, domainId } = useParams();

  const items = [
    {
      name: 'Notebooks',
      icon: <Notebook className="text-xl text-gray-400" />,
      href: `/domains/${domainId}/assets/notebooks`,
    },
    {
      name: asset?.resource_name || 'No Resource Name',
      href: `/domains/${domainId}/assets/notebooks/${notebookId}`,
    },
  ];

  return (
    <nav className="flex mb-6" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        {items.map((item, index) => (
          <li key={index}>
            <div className="flex items-center">
              {index !== 0 && (
                <ChevronRight
                  className="h-4 w-4 flex-shrink-0 text-gray-400 mx-2"
                  aria-hidden="true"
                />
              )}
              <Link
                to={item.href}
                className="flex items-center text-xs font-medium text-gray-600 hover:text-gray-900"
              >
                {item.icon && <span className="mr-1">{item.icon}</span>}
                {item.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
