'use client';

import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ArrowLeft, Search, ArrowRight, Maximize2, Minimize2, X } from 'lucide-react';

import CommonTask from '../tasks/CommonTask';
import DagEditorTest from './DagDepEditor';
import { fetchOperators } from '../../utils/resources';
import { fetchDag } from '../../utils/dag';
import { cn } from '@/lib/utils';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

const DagEditor = (props) => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [editingTask, setEditingTask] = useState({});
  const [editingTaskType, setEditingTaskType] = useState('');
  const [tasks, setTasks] = useState([]);
  const [currentDag, setCurrentDag] = useState(null);
  const { dagId } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTasks, setFilteredTasks] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const isFullPage = location.pathname.endsWith('/full');

  useEffect(() => {
    const groupedTasks = tasks
      .filter((task) => task.alias.toLowerCase().includes(searchTerm.toLowerCase()))
      .reduce((acc, task) => {
        if (!acc[task.label]) {
          acc[task.label] = [];
        }
        acc[task.label].push(task);
        return acc;
      }, {});

    setFilteredTasks(groupedTasks);
  }, [searchTerm, tasks]);

  useEffect(() => {
    getTasks();
    getDag(dagId);
  }, [dagId]);

  const getDag = async (id_dag) => {
    try {
      const dag = await fetchDag(id_dag);
      setCurrentDag(dag);
    } catch (error) {
      console.error('Error fetching DAG:', error);
    }
  };

  const getTasks = async () => {
    try {
      const fetchedTasks = await fetchOperators();
      setTasks(fetchedTasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleClose = (e) => {
    e.preventDefault();
    props.onClose();
  };

  const handleBack = (e) => {
    e.preventDefault();
    if (isFullPage) {
      const basePath = location.pathname.replace('/full', '');
      navigate(basePath);
    } else {
      navigate(-1);
    }
  };

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const toggleFullscreen = () => {
    const currentPath = location.pathname;
    if (isFullPage) {
      navigate(currentPath.replace('/full', ''));
    } else {
      navigate(`${currentPath}/full`);
    }
  };

  return (
    <div
      className={cn(
        'h-full w-full',
        isFullPage ? 'fixed inset-0 z-50 bg-background' : 'h-[calc(100vh-4rem)]'
      )}
    >
      {showTaskModal && (
        <CommonTask
          taskId={editingTask['taskId']}
          task_type={editingTaskType}
          onCloseTask={() => setShowTaskModal(false)}
        />
      )}

      <div className={cn('flex h-full w-full', 'flex-row')}>
        <aside className="bg-white border-r h-full flex flex-col min-h-0 w-72">
          <div className="flex items-center justify-between px-4 py-3 bg-gray-50 border-b">
            <div className="flex items-center gap-3">
              {' '}
              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={handleClose}
                      className="h-8 w-8 border border-gray-200 hover:bg-gray-100 hover:border-gray-300"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="right" className="text-xs">
                    Close editor
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={toggleFullscreen}
                      className="h-8 w-8 border border-gray-200 hover:bg-gray-100 hover:border-gray-300"
                    >
                      {isFullPage ? (
                        <Minimize2 className="h-4 w-4" />
                      ) : (
                        <Maximize2 className="h-4 w-4" />
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="right" className="text-xs">
                    {isFullPage ? 'Exit fullscreen' : 'Enter fullscreen'}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>

          <div className="px-4 py-3 border-b bg-white">
            <div className="relative">
              <Search className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
              <Input
                placeholder="Search tasks..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="pl-10 h-9 text-sm"
              />
            </div>
          </div>

          <div className="flex-1 overflow-hidden min-h-0">
            <ScrollArea className="h-full">
              <div className="p-3">
                {Object.keys(filteredTasks).length > 0 ? (
                  <Accordion
                    type="multiple"
                    className="space-y-2"
                    defaultValue={Object.keys(filteredTasks)}
                  >
                    {Object.entries(filteredTasks).map(([label, tasks]) => (
                      <AccordionItem key={label} value={label} className="border-none">
                        <AccordionTrigger
                          className={cn(
                            'flex items-center py-2 px-3 rounded-md',
                            'no-underline hover:bg-gray-100 hover:no-underline',
                            'data-[state=open]:bg-gray-50',
                            '[&[data-state=open]>div>div]:rotate-180'
                          )}
                        >
                          <span className="text-sm font-semibold text-gray-700">
                            {label}
                          </span>
                        </AccordionTrigger>
                        <AccordionContent className="pt-1 pb-2">
                          <div className="space-y-1">
                            {tasks.map((task) => (
                              <div
                                key={task.alias}
                                draggable
                                onDragStart={(event) => onDragStart(event, task.alias)}
                                className={cn(
                                  'group flex items-center justify-between',
                                  'rounded-md px-3 py-1.5',
                                  'text-xs text-gray-700',
                                  'hover:bg-gray-100 cursor-move',
                                  'transition-colors duration-150'
                                )}
                              >
                                <span className="font-medium truncate">
                                  {task.alias}
                                </span>
                                <ArrowRight className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
                              </div>
                            ))}
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    ))}
                  </Accordion>
                ) : (
                  <div className="py-2 px-3 text-sm text-gray-500">No tasks found</div>
                )}
              </div>
            </ScrollArea>
          </div>
        </aside>

        <main className="bg-white relative flex-1 overflow-hidden">
          <div className="h-full">
            <DagEditorTest dag={currentDag} runs={props.runs} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default DagEditor;
