import { useCallback } from "react";
import { useStore, getBezierPath } from "reactflow";
import { cn } from "@/lib/utils";

const globalStyles = `
.react-flow__connection-path {
  stroke-width: 12;
  stroke: rgb(203 213 225);
}
.react-flow__edge-path {
  stroke-width: 12px; !important /* Increased the stroke width */
  transition: none !important; /* Removed any transitions */
  animation: none !important;  /* Removed any animations */
  stroke-dasharray: none; /* Ensure no dashed lines */
}
.react-flow__edge-path, .react-flow__connection-path {
  stroke: #b1b1b7;
  stroke-width: 2;
  fill: none;
}
`;

const getEdgePosition = (node, position = "bottom") => {
  if (!node) return { x: 0, y: 0 };

  const nodeWidth = node.width || 200;
  const nodeHeight = node.height || 100;

  switch (position) {
    case "left":
      return {
        x: node.position.x,
        y: node.position.y + nodeHeight / 2,
      };
    case "right":
      return {
        x: node.position.x + nodeWidth,
        y: node.position.y + nodeHeight / 2,
      };
    case "top":
      return {
        x: node.position.x + nodeWidth / 2,
        y: node.position.y,
      };
    case "bottom":
      return {
        x: node.position.x + nodeWidth / 2,
        y: node.position.y + nodeHeight,
      };
    default:
      return {
        x: node.position.x + nodeWidth / 2,
        y: node.position.y + nodeHeight / 2,
      };
  }
};

function SimpleFloatingEdge({
  id,
  source,
  target,
  markerEnd,
  style,
  selected,
}) {
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const sourcePos = getEdgePosition(sourceNode, "right");
  const targetPos = getEdgePosition(targetNode, "left");

  const [edgePath] = getBezierPath({
    sourceX: sourcePos.x,
    sourceY: sourcePos.y,
    sourcePosition: "right",
    targetPosition: "left",
    targetX: targetPos.x,
    targetY: targetPos.y,
  });

  return (
    <>
      <style>{globalStyles}</style>
      <path
        id={id}
        className={cn(
          "react-flow__edge-path",
          selected && "stroke-indigo-500 w-2" // Adjusted class names
        )}
        d={edgePath}
        strokeWidth={8} // Increased the stroke width
        stroke="rgb(203 213 225)"
        markerEnd={markerEnd}
        style={{
          ...style,
          strokeDasharray: "none", // Ensure no dashed lines
        }}
      />
    </>
  );
}

export default SimpleFloatingEdge;
