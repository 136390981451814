import { createData, deleteData, fetchData, updateData } from "./baseRequest";

export const checkAuth = async () => {
  return fetchData(`/check-auth`);
};

export const getUserInfo = async (userId) => {
  return await fetchData(`/users/${userId}`);
};

export const getUserDomains = async (userId) => {
  return fetchData(`/users/${userId}/domains`);
};

export const getUsers = async () => {
  return fetchData(`/user/`);
};

export const getAllUsers = async (
  page = 1,
  per_page = 30,
  search_term = ""
) => {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    per_page: per_page.toString(),
  });

  if (search_term) {
    queryParams.append("q", search_term);
  }

  const queryString = queryParams.toString();
  return fetchData(`/users?${queryString}`);
};

export const getUserInitials = (fullName) => {
    if (!fullName) return '';
    
    const names = fullName.split(' ').filter(n => n);
    let initials = names[0].charAt(0).toUpperCase();
  
    if (names.length > 1) {
      initials += names[names.length - 1].charAt(0).toUpperCase();
    }
  
    return initials;
  };
