import React, { useState } from 'react';
import { Check, ChevronsUpDown, User } from 'lucide-react';
import { getAllUsers } from '../../utils/user';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { cn } from '@/lib/utils';

function TeamManager({ parentDomainId, onClose, onSave }) {
  const [name, setName] = useState('');
  const [owner, setOwner] = useState(null);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [userPopoverOpen, setUserPopoverOpen] = useState(false);

  const handleSearch = async (query) => {
    if (query.length < 2) {
      setUsers([]);
      return;
    }

    try {
      const result = await getAllUsers(1, 30, query);
      if (result && Array.isArray(result.users)) {
        setUsers(result.users);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
    }
  };

  const handleSave = () => {
    if (name.trim()) {
      onSave({
        id_parent_domain: parentDomainId,
        id_domain: name,
        name: name,
        owner: owner?.user_email || null,
      });
      onClose();
    }
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create New Domain</DialogTitle>
          <DialogDescription>Set up a new domain with owner.</DialogDescription>
        </DialogHeader>

        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="grid gap-4 py-4">
          <div className="space-y-2">
            <Label htmlFor="name">Domain Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter domain name"
            />
          </div>

          <div className="space-y-2">
            <Label>Owner</Label>
            <Popover open={userPopoverOpen} onOpenChange={setUserPopoverOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={userPopoverOpen}
                  className="w-full justify-between"
                >
                  {owner ? owner.name : 'Search users...'}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[--radix-popover-trigger-width] p-0">
                <Command shouldFilter={false}>
                  <CommandInput
                    placeholder="Search users..."
                    onValueChange={handleSearch}
                  />
                  <CommandList>
                    <CommandEmpty>No users found.</CommandEmpty>
                    <CommandGroup>
                      {users.map((user) => (
                        <CommandItem
                          key={user.user_email}
                          value={user.user_email}
                          onSelect={() => {
                            setOwner(user);
                            setUserPopoverOpen(false);
                          }}
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              owner?.user_email === user.user_email
                                ? 'opacity-100'
                                : 'opacity-0'
                            )}
                          />
                          {user.name} ({user.user_email})
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
        </div>

        <div className="flex justify-end gap-3">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={!name.trim()}>
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default TeamManager;
