import { Button } from '@/components/ui/button';
import { MessageCircle} from 'lucide-react';


const ChatButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    variant="outline"
    className="group relative inline-flex items-center gap-2.5 py-2 font-semibold transition-all duration-200 hover:shadow-md"
  >
    <span className="relative flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-gradient-to-r from-indigo-500 to-purple-500 group-hover:from-indigo-600 group-hover:to-purple-600 transition-all duration-200">
      <MessageCircle className="h-3 w-3 text-white" aria-hidden="true" />
    </span>
    <span className="relative">AI Chat</span>
  </Button>
);

export default ChatButton;
