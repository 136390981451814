import React, { useState, useCallback, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import { memo } from 'react';
import { Edit2, ChevronDown, ChevronRight, Plus, Loader2, Minus } from 'lucide-react';
import { cn } from '@/lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Input } from '@/components/ui/input';
import CommonTask from '../tasks/CommonTask';
import { createDagTask, deleteDagTaskDep } from '../../utils/dag';
import { useParams } from 'react-router-dom';
import { fetchData } from '@/utils/baseRequest';

function TextUpdaterNode({ id, data, isConnectable }) {
  const [inputValue, setInputValue] = useState(data.name || '');
  const [isSubmitted, setIsSubmitted] = useState(!!data.name);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showConnector, setShowConnector] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [availableTasks, setAvailableTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dagId, domainId } = useParams();
  const [showRemoveConnector, setShowRemoveConnector] = useState(false);
  const [removableTasks, setRemovableTasks] = useState([]);

  const fetchAvailableTasks = async () => {
    setIsLoading(true);
    try {
      const tasks = await fetchData(`/dags/${dagId}/tasks`);
      setAvailableTasks(
        tasks
          .filter((task) => task.id_task !== id)
          .map((task) => ({
            id: task.id_task,
            name: task.task_name,
            type: task.task_type,
          }))
      );
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnectorOpenChange = (open) => {
    setShowConnector(open);
    if (open) {
      fetchAvailableTasks();
    }
  };

  const statusColors = {
    SUCCESS: 'bg-emerald-50 text-emerald-700 border-emerald-200 hover:bg-emerald-100',
    FAILED: 'bg-rose-50 text-rose-700 border-rose-200 hover:bg-rose-100',
    SCHEDULED: 'bg-blue-50 text-blue-700 border-blue-200 hover:bg-blue-100',
    RUNNING: 'bg-amber-50 text-amber-700 border-amber-200 hover:bg-amber-100',
    DEFAULT: 'bg-white border-gray-200 hover:bg-gray-50',
  };

  const getStatusStyles = () => {
    if (!data.showStatus) return statusColors.DEFAULT;
    return statusColors[data.last_status] || statusColors.DEFAULT;
  };

  const nodeStyle = cn(
    'px-4 py-2 rounded-lg border-2 transition-colors duration-200 relative',
    'shadow-sm hover:shadow-md',
    getStatusStyles(),
    data.hasChildren && 'border-l-4 border-l-indigo-500'
  );

  const onChange = useCallback(
    (evt) => {
      const value = evt.target.value;
      setInputValue(value);
      if (isSubmitted && value === '') {
        data.onSave?.(id, '');
      }
    },
    [id, isSubmitted, data]
  );

  const onEnterPress = async (evt) => {
    if (evt.key === 'Enter' && inputValue.trim()) {
      try {
        const task_to_create = {
          id_task: id,
          id_dag: dagId,
          created_by: 'salihatilay@noon.com',
          task_name: inputValue,
          task_type: data.label,
          id_domain: domainId,
        };

        await createDagTask(JSON.stringify(task_to_create));
        data.onSave(id, inputValue);
        setIsSubmitted(true);
      } catch (error) {
        console.error('Failed to create task:', error);
      }
    }
  };

  const handleDoubleClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (isSubmitted) {
        setShowTaskModal(true);
      }
    },
    [isSubmitted]
  );

  const handleExpandClick = useCallback(
    (e) => {
      e.stopPropagation();
      data.onToggleChildren?.(id);
    },
    [id, data]
  );

  const handleConnect = useCallback(
    (targetId) => {
      if (data.onConnect) {
        data.onConnect({
          source: id,
          target: targetId,
        });
      }
      setShowConnector(false);
    },
    [id, data]
  );

  const handleRemoveDependency = useCallback(
    (targetId) => {
      if (data.onRemove) {
        const edgesToRemove =
          data.edges?.filter(
            (edge) =>
              edge.target === id && edge.source === targetId
          ) || [];

        if (edgesToRemove.length > 0) {
          data.onRemove(edgesToRemove);
        }
      }
      setShowRemoveConnector(false);
    },
    [id, data]
  );

  // const handleRemoveDependency = useCallback(
  //   (e) => {
  //     e.stopPropagation();
  //     const incomingEdges = data.edges?.filter((edge) => edge.target === id) || [];
  //     if (incomingEdges.length > 0) {
  //       data.onRemove?.(incomingEdges);
  //       incomingEdges.forEach(async (edge) => {
  //         try {
  //           const body = JSON.stringify({ source: edge.source, target: edge.target });
  //           await deleteDagTaskDep(data.dagId, body);
  //         } catch (error) {
  //           console.error('Error removing dependency:', error);
  //         }
  //       });
  //     }
  //   },
  //   [id, data]
  // );

  const handleRemoveConnectorOpenChange = (open) => {
    setShowRemoveConnector(open);
    if (open) {
      fetchAvailableTasks();
    }
  };

  // const handleRemoveConnectorOpenChange = (open) => {
  //   setShowRemoveConnector(open);
  //   if (open) {
  //     // Get all incoming edges and their source nodes
  //     const incomingEdges = data.edges?.filter((edge) => edge.target === id) || [];
  //     const tasksToRemove = incomingEdges.map((edge) => ({
  //       id: edge.source,
  //       name: data.nodes?.find((n) => n.id === edge.source)?.data?.name || 'Unknown',
  //       type: data.nodes?.find((n) => n.id === edge.source)?.data?.label || 'Unknown',
  //     }));
  //     setRemovableTasks(tasksToRemove);
  //   }
  // };

  const filteredTasks = availableTasks.filter(
    (task) =>
      task.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      task.type.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleMouseLeave = () => {
    setIsHovered(false);
    setShowConnector(false);
    setShowRemoveConnector(false);
  };

  return (
    <TooltipProvider>
      <div
        className={nodeStyle}
        onDoubleClick={handleDoubleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={handleMouseLeave}
      >
        <Handle
          type="target"
          position={Position.Left}
          className={cn(
            'w-3 h-3 -ml-0.5 bg-gray-400 border-2 border-white rounded-full transition-all',
            isHovered && 'bg-indigo-500'
          )}
          isConnectable={isConnectable}
        />

        {!isSubmitted ? (
          <input
            type="text"
            value={inputValue}
            onChange={onChange}
            onKeyPress={onEnterPress}
            placeholder="Enter task name..."
            className="w-full px-2 py-1 text-sm border rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
            autoFocus
          />
        ) : (
          <div className="space-y-2">
            <div className="flex items-center">
              <div className="font-medium text-sm truncate">{inputValue}</div>
            </div>
            <div className="flex items-center gap-2 text-xs text-gray-500">
              <span>{data.label}</span>
              {data.last_status && (
                <span
                  className={cn(
                    'px-1.5 py-0.5 rounded-full text-xs font-medium',
                    statusColors[data.last_status]?.replace('bg-', 'bg-opacity-50 bg-')
                  )}
                >
                  {data.last_status}
                </span>
              )}
            </div>
          </div>
        )}

        <Handle
          type="source"
          position={Position.Right}
          className={cn(
            'w-3 h-3 -mr-0.5 bg-gray-400 border-2 border-white rounded-full transition-all',
            isHovered && 'bg-indigo-500'
          )}
          isConnectable={isConnectable}
        />

        {isHovered && isSubmitted && (
          <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-white rounded-md shadow-lg border border-gray-200 px-1 py-0.5 flex items-center gap-1">
            <Popover
              open={showRemoveConnector}
              onOpenChange={handleRemoveConnectorOpenChange}
              modal={true}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <PopoverTrigger asChild>
                    <Button variant="ghost" size="sm" className="h-6 w-6">
                      <Minus className="h-3 w-3" />
                    </Button>
                  </PopoverTrigger>
                </TooltipTrigger>
                <TooltipContent>Remove connection</TooltipContent>
              </Tooltip>
              <PopoverContent className="w-64 p-2" align="start">
                <div className="space-y-2">
                  <Input
                    placeholder="Search tasks..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="w-full"
                  />
                  <div className="max-h-[200px] overflow-y-auto space-y-1">
                    {isLoading ? (
                      <div className="flex items-center justify-center py-4">
                        <Loader2 className="h-4 w-4 animate-spin" />
                      </div>
                    ) : filteredTasks.length === 0 ? (
                      <div className="text-sm text-muted-foreground p-2">
                        No tasks found
                      </div>
                    ) : (
                      filteredTasks.map((task) => (
                        <Button
                          key={task.id}
                          variant="ghost"
                          size="sm"
                          className="w-full justify-start text-sm"
                          onClick={() => handleRemoveDependency(task.id)}
                        >
                          <div className="flex items-center gap-2 truncate">
                            <span className="font-medium truncate">{task.name}</span>
                            <span className="text-xs text-muted-foreground">
                              ({task.type})
                            </span>
                          </div>
                        </Button>
                      ))
                    )}
                  </div>
                </div>
              </PopoverContent>
            </Popover>

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className="h-6 w-6"
                  onClick={() => setShowTaskModal(true)}
                >
                  <Edit2 className="h-3 w-3" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Edit task</TooltipContent>
            </Tooltip>

            <Popover
              open={showConnector}
              onOpenChange={handleConnectorOpenChange}
              modal={true}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <PopoverTrigger asChild>
                    <Button variant="ghost" size="sm" className="h-6 w-6">
                      <Plus className="h-3 w-3" />
                    </Button>
                  </PopoverTrigger>
                </TooltipTrigger>
                <TooltipContent>Add connection</TooltipContent>
              </Tooltip>
              <PopoverContent className="w-64 p-2" align="start">
                <div className="space-y-2">
                  <Input
                    placeholder="Search tasks..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="w-full"
                  />
                  <div className="max-h-[200px] overflow-y-auto space-y-1">
                    {isLoading ? (
                      <div className="flex items-center justify-center py-4">
                        <Loader2 className="h-4 w-4 animate-spin" />
                      </div>
                    ) : filteredTasks.length === 0 ? (
                      <div className="text-sm text-muted-foreground p-2">
                        No tasks found
                      </div>
                    ) : (
                      filteredTasks.map((task) => (
                        <Button
                          key={task.id}
                          variant="ghost"
                          size="sm"
                          className="w-full justify-start text-sm"
                          onClick={() => handleConnect(task.id)}
                        >
                          <div className="flex items-center gap-2 truncate">
                            <span className="font-medium truncate">{task.name}</span>
                            <span className="text-xs text-muted-foreground">
                              ({task.type})
                            </span>
                          </div>
                        </Button>
                      ))
                    )}
                  </div>
                </div>
              </PopoverContent>
            </Popover>

            {data.hasChildren && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-6 w-6"
                    onClick={handleExpandClick}
                  >
                    {data.isExpanded ? (
                      <ChevronDown className="h-3 w-3" />
                    ) : (
                      <ChevronRight className="h-3 w-3" />
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  {data.isExpanded ? 'Collapse' : 'Expand'} dependencies
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        )}

        {showTaskModal && (
          <CommonTask
            taskId={id}
            task_type={data.label}
            onCloseTask={() => setShowTaskModal(false)}
          />
        )}
      </div>
    </TooltipProvider>
  );
}

export default memo(TextUpdaterNode);
