import { useState } from "react";
import { format } from "date-fns";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Loader2, ExternalLink } from "lucide-react";
import { cn } from "@/lib/utils";

const statusConfig = {
  SUCCESS: {
    color: "bg-green-500 dark:bg-green-600",
    textColor: "text-green-700 dark:text-green-200",
  },
  RUNNING: {
    color: "bg-green-500 dark:bg-green-600",
    textColor: "text-green-300 dark:text-blue-200",
    animation: "animate-pulse",
  },
  FAILED: {
    color: "bg-red-500 dark:bg-red-600",
    textColor: "text-red-700 dark:text-red-200",
  },
  TERMINATED: {
    color: "bg-yellow-500 dark:bg-yellow-600",
    textColor: "text-yellow-700 dark:text-yellow-200",
  },
  DISABLED: {
    color: "bg-gray-300 dark:bg-gray-600",
    textColor: "text-gray-700 dark:text-gray-200",
  },
};

const StatusDot = ({ status, onTerminate, isDag = false }) => {
  const [isTerminating, setIsTerminating] = useState(false);
  const statusValue = status?.status || 'DISABLED';
  const config = statusConfig[statusValue] || statusConfig.DISABLED;

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    try {
      return format(new Date(dateString), "MMM d, yyyy HH:mm:ss");
    } catch {
      return "Invalid date";
    }
  };

  const handleViewDetails = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const workflowId = status?.workflow_id || '';
    const runId = status?.run_id || '';
    window.open(
      `http://localhost:8233/namespaces/default/workflows/${encodeURIComponent(
        workflowId
      )}/${runId}/history`,
      '_blank'
    );
  };

  const handleTerminate = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isTerminating) return;

    setIsTerminating(true);
    try {
      await onTerminate(status?.workflow_id, status?.workflow_run_id, status?.activity_id);
    } finally {
      setIsTerminating(false);
    }
  };

  return (
    <HoverCard openDelay={200}>
      <HoverCardTrigger asChild>
        <div
          className={cn(
            "h-4 w-4 rounded cursor-pointer transition-all",
            config.color,
            config.animation,
            isTerminating && "opacity-50"
          )}
        />
      </HoverCardTrigger>
      <HoverCardContent
        side="top"
        align="center"
        className="w-80"
      >
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <Badge
              variant="outline"
              className={cn("capitalize font-medium", config.textColor)}
            >
              {statusValue.toLowerCase()}
            </Badge>
            <span className="text-xs text-muted-foreground">
              {isDag ? 'DAG Status' : 'Task Status'}
            </span>
          </div>

          <div className="text-sm space-y-1">
            <div className="flex justify-between">
              <span className="text-muted-foreground">Started:</span>
              <span>{formatDate(status?.start_ts)}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-muted-foreground">Ended:</span>
              <span>{formatDate(status?.end_ts)}</span>
            </div>
            {status?.duration && (
              <div className="flex justify-between">
                <span className="text-muted-foreground">Duration:</span>
                <span>{status.duration}</span>
              </div>
            )}
          </div>

          <div className="flex gap-2">
            {statusValue === "RUNNING" && status?.activity_id && (
              <Button
                variant="destructive"
                size="sm"
                onClick={handleTerminate}
                disabled={isTerminating}
                className="flex-1"
              >
                {isTerminating ? (
                  <>
                    <Loader2 className="mr-2 h-3 w-3 animate-spin" />
                    Terminating
                  </>
                ) : (
                  "Terminate"
                )}
              </Button>
            )}
            <Button
              variant="outline"
              size="sm"
              onClick={handleViewDetails}
              className="flex-1"
            >
              <ExternalLink className="mr-2 h-3 w-3" />
              View Details
            </Button>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};

export default StatusDot;
