import { useEffect } from 'react';
import { Portal } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { XCircleIcon } from '@heroicons/react/20/solid';

function GlobalSuccess({ message, onClose }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <Portal>
      <div className="fixed top-2 right-80 left-80 px-24 rounded-xl inset-x-0 z-[9999] bg-green-50 p-4 shadow-lg">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-grow">
            <h3 className="text-sm font-medium text-green-800">Success!</h3>
            <div className="mt-2 text-sm text-green-700">
              <p>{message}</p>
            </div>
          </div>
          <div className="flex-shrink-0">
            <button
              onClick={onClose}
              className="rounded-md text-green-400 hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              <XCircleIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default GlobalSuccess;
