import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition, Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

function Share({ resourceType, onClose, onSave }) {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState('');
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        fetchUsers();
        fetchResourcePermissionTypes()
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await fetch("http://localhost:8080/user/", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const users = await response.json();
            setUsers(users);
        } catch (error) {
            console.error("There was a problem fetching users:", error);
        }
    };

    const fetchResourcePermissionTypes = async () => {
        try {
            const response = await fetch(`http://localhost:8080/resource/${resourceType}/permission_types`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const permissionTypes = await response.json();
            console.log("Permission Types:", permissionTypes);
            setRoles(permissionTypes);
        } catch (error) {
            console.error("There was a problem fetching resource permission types:", error);
        }
    };


    return (
        <Transition appear show={true} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose}>
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>

                    <div className="inline-block w-1/2 max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6  mb-2">
                            Share {resourceType} with
                        </Dialog.Title>

                        <div className="mb-3 flex justify-between items-center">
                            <span className={`inline-flex items-center gap-x-2 mr-2 mb-2 rounded-md px-2 py-1 text-xs font-medium ${user ? user.color : ''} ${user ? user.text_color : ''}`}>
                                {user ? user.name : 'No user selected'}
                            </span>
                            <span className={`inline-flex items-center ml-2 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600`}>
                                {role || 'No role selected'}
                            </span>
                        </div>

                        <Listbox as="div" value={user} onChange={setUser} className="space-y-4">
                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none sm:text-sm">
                                <span className="block truncate">{user ? user.user_email : 'Choose a user'}</span>
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400 absolute inset-y-0 right-0 flex items-center pr-2" />
                            </Listbox.Button>
                            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <Listbox.Options className="mt-2 border border-gray-300 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                    {users.map(User => (
                                        <Listbox.Option key={User.user_email} value={User} className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : ' text-gray-600'}`}>
                                            {User.user_email}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </Listbox>

                        <Listbox as="div" value={role} onChange={setRole} className="mt-4">
                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none sm:text-sm">
                                <span className="block truncate">{role || 'Select role'}</span>
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400 absolute inset-y-0 right-0 flex items-center pr-2" />
                            </Listbox.Button>
                            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <Listbox.Options className="mt-2 border border-gray-300 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                    {roles.map(role => (
                                        <Listbox.Option key={role.name} value={role.name} className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : ' text-gray-600'}`}>
                                            {role.name}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </Listbox>

                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button type="button" className="text-sm font-semibold leading-6  text-gray-800" onClick={onClose}>
                                Cancel
                            </button>
                            <button
                                type="button"
                                disabled={!user || !role}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300"
                                onClick={() => {
                                    onSave({ ...user, role });
                                    onClose();
                                }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default Share;
