import { Fragment, useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Search as SearchIcon,
  ChevronRight,
  Menu,
  Bell,
  File,
  FileSpreadsheet,
  FileText,
  Notebook,
  BarChart,
  ChevronDown,
  Layout,
  Database,
  Table,
  FileCode,
  Columns,
  Tag,
  Filter,
  Grid,
  Users,
  User,
} from 'lucide-react';
import { cn } from '@/lib/utils';

// Import your shadcn components
import { Card, CardContent } from '@/components/ui/card';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Badge } from '@/components/ui/badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

import { Separator } from '@/components/ui/separator';
import SetDomainModal from '../components/modals/SetDomain';
import { search } from '../utils/search';
import { Skeleton } from '@/components/ui/skeleton';
import { getTags } from '@/utils/tags';
import TagBadge from '@/components/common/TagBadge';

// Animation variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 100,
    },
  },
};

const getResourceTypeIcon = (type) => {
  switch (type) {
    case 'project':
      return <Layout className="w-4 h-4" />;
    case 'dataset':
      return <Database className="w-4 h-4" />;
    case 'table':
      return <Table className="w-4 h-4" />;
    case 'column':
      return <Columns className="w-4 h-4" />;
    case 'user':
      return <Users className="w-4 h-4" />;
    default:
      return <FileCode className="w-4 h-4" />;
  }
};

const Search = () => {
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [resourceTypes, setResourceTypes] = useState({});
  const [tags, setTags] = useState([]);
  const [isLabelsExpanded, setIsLabelsExpanded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSetDomainModal, setShowSetDomainModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const excludedFields = ['resource_name', 'content', 'name'];
  const isKeywordField = (field) => field.endsWith('.keyword');

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const queryParam = searchParams.get('query');
  const filterParam = searchParams.get('filter');

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const query = formData.get('search');
    setSearchParams({ query });
  };

  const getResourceIcon = (type, docType) => {
    switch (type) {
      case 'dag':
      case 'task':
        return <File className="w-4 h-4 text-blue-500" />;
      case 'dag':
        return <File className="w-4 h-4 text-blue-500" />;
      case 'looker':
        return <BarChart className="w-4 h-4 text-purple-500" />;
      case 'notebook':
        return <Notebook className="w-4 h-4 text-orange-500" />;
      case 'user':
        return <Users className="w-4 h-4" />;
      case 'project':
        return <Layout className="w-4 h-4" />;
      case 'dataset':
        return <Database className="w-4 h-4" />;
      case 'table':
        return <Table className="w-4 h-4" />;
      case 'column':
        return <Columns className="w-4 h-4" />;
      case 'doc':
        if (docType === 'xlsx')
          return <FileSpreadsheet className="w-6 h-6 text-green-500" />;
        return <FileText className="w-6 h-6 text-gray-500" />;
      default:
        return <File className="w-6 h-6 text-gray-500" />;
    }
  };

  const getAllTags = async () => {
    try {
      const tags_data = await getTags();
      setTags(tags_data || []);
    } catch (error) {
      console.error('Failed to fetch tags:', error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await search({
        query: queryParam || '*:*',
        facet_fields: 'resource_type',
        rows: 20,
        filter: filterParam,
      });

      // Update results state with hits
      setResults(response);

      // Process and set filtered results
      if (response?.hits) {
        setFilteredResults(response.hits);
      }

      // Process and set resource types from aggregations
      if (response?.aggregations?.resource_type?.buckets) {
        const typesCount = {};
        response.aggregations.resource_type.buckets.forEach((bucket) => {
          typesCount[bucket.key] = bucket.doc_count;
        });
        setResourceTypes(typesCount);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFacets = async () => {
    try {
      const data = await fetchData(
        `/search/facets${queryParam ? `?query=${queryParam}` : ''}`
      );
      const typesCount = {};
      data?.resource_type?.buckets?.forEach((bucket) => {
        typesCount[bucket.key] = bucket.doc_count;
      });
      setResourceTypes(typesCount);
    } catch (error) {
      console.error('Error fetching facets:', error);
    }
  };

  useEffect(() => {
    fetchData();
    getAllTags();
  }, [queryParam, filterParam]);

  const handleResourceTypeClick = (type) => {
    setSearchParams({
      ...(queryParam && { query: queryParam }),
      filter: `resource_type:${type}`,
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-muted/20">
      <div className="flex">
        {/* Sidebar */}
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className="lg:hidden fixed top-4 left-4 z-50"
            >
              <Menu className="h-5 w-5" />
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="w-72 p-0">
            <ScrollArea className="h-full">
              <SidebarContent
                resourceTypes={resourceTypes}
                tags={tags}
                isLabelsExpanded={isLabelsExpanded}
                setIsLabelsExpanded={setIsLabelsExpanded}
                handleResourceTypeClick={handleResourceTypeClick}
              />
            </ScrollArea>
          </SheetContent>
        </Sheet>

        <div className="hidden lg:flex w-72 border-r border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <ScrollArea className="w-full">
            <SidebarContent
              resourceTypes={resourceTypes}
              tags={tags}
              isLabelsExpanded={isLabelsExpanded}
              setIsLabelsExpanded={setIsLabelsExpanded}
              handleResourceTypeClick={handleResourceTypeClick}
            />
          </ScrollArea>
        </div>

        {/* Main Content */}
        <div className="flex-1">
          <header className="border-b border-zinc-200 dark:border-zinc-800 p-4">
            <div className="flex items-center gap-x-4">
              <form onSubmit={handleSearchSubmit} className="flex-1">
                <div className="relative">
                  <SearchIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                  <Input
                    name="search"
                    placeholder="Search resources..."
                    defaultValue={queryParam}
                    className="pl-10 h-9 md:h-10 bg-muted"
                  />
                </div>
              </form>

              <div className="flex items-center gap-2">
                <Button variant="ghost" size="icon" className="h-9 w-9">
                  <Bell className="h-4 w-4" />
                </Button>

                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="sm" className="gap-2">
                      <span className="hidden md:inline-block">
                        {user?.name || 'User'}
                      </span>
                      <ChevronDown className="h-4 w-4 opacity-50" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-48">
                    <DropdownMenuLabel>My Account</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>Profile</DropdownMenuItem>
                    <DropdownMenuItem>Sign out</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </header>

          <main className="pl-6 py-6 pr-12">
            <div className="grid gap-3">
              {isLoading
                ? // Loading skeletons
                  Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <Card
                        key={i}
                        className="hover:shadow-md transition-all duration-200"
                      >
                        <CardContent className="p-4">
                          <div className="flex items-start gap-4">
                            <Skeleton className="h-10 w-10 rounded-lg" />
                            <div className="flex-1 space-y-2">
                              <Skeleton className="h-4 w-[250px]" />
                              <Skeleton className="h-4 w-[200px]" />
                              <div className="flex gap-2">
                                <Skeleton className="h-5 w-[100px]" />
                                <Skeleton className="h-5 w-[80px]" />
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    ))
                : filteredResults.map((doc) => (
                    <Card
                      key={doc.id}
                      className="group hover:shadow-md transition-all duration-200"
                    >
                      <CardContent className="p-4">
                        <div className="flex items-start gap-4">
                          <div className="flex-shrink-0 p-2 rounded-lg bg-primary/5 text-primary">
                            {getResourceIcon(doc.resource_type, doc.param_doc_type)}
                          </div>
                          <div className="flex-1 min-w-0">
                            <ResourceLink doc={doc} />
                            {doc.description && (
                              <p className="mt-1 text-sm text-muted-foreground">
                                {doc.description}
                              </p>
                            )}
                            <div className="mt-2 flex gap-2">
                              {doc.id_domain ? (
                                <Badge variant="outline">{doc.id_domain}</Badge>
                              ) : (
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() => {
                                    setSelectedResource(doc);
                                    setShowSetDomainModal(true);
                                  }}
                                >
                                  Set Domain
                                </Button>
                              )}
                              {doc.labels?.map((tag) => (
                                <Badge key={tag} variant="secondary">
                                  {tag}
                                </Badge>
                              ))}
                            </div>
                            {/* Highlighted content */}
                            {doc.highlight && Object.keys(doc.highlight).length > 0 && (
                              <div className="mt-2 text-sm">
                                {Object.entries(doc.highlight)
                                  .filter(
                                    ([field]) =>
                                      !excludedFields.includes(field) &&
                                      !isKeywordField(field)
                                  )
                                  .map(([field, highlights]) => (
                                    <div key={field} className="">
                                      <span className="font-medium">
                                        {field.replace('param_', '')}:
                                      </span>
                                      {highlights.map((highlight, i) => (
                                        <div
                                          key={i}
                                          className="mt-0.5 [&>em]:bg-yellow-100 [&>em]:dark:bg-yellow-900/30 [&>em]:dark:text-yellow-200 [&>em]:not-italic [&>em]:font-normal"
                                          dangerouslySetInnerHTML={{
                                            __html: highlight,
                                          }}
                                        />
                                      ))}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
            </div>
          </main>
        </div>
      </div>

      <SetDomainModal
        resource={selectedResource}
        isOpen={showSetDomainModal}
        onClose={() => {
          setShowSetDomainModal(false);
          setSelectedResource(null);
        }}
      />
    </div>
  );
};

const SidebarContent = ({
  resourceTypes,
  tags,
  isLabelsExpanded,
  setIsLabelsExpanded,
  handleResourceTypeClick,
}) => {
  return (
    <div className="flex h-full flex-col">
      <div className="border-b border-zinc-200 dark:border-zinc-800 p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Avatar className="h-10 w-10 bg-gradient-to-br from-violet-500 to-indigo-500">
              <AvatarImage
                src="https://play-lh.googleusercontent.com/4-wa067yiYYMj5rRdHm82jPdDiSHSoIkwwvHA0teCEauFFU_cjx053jMBwBxfo2anJo"
                alt="AI Assistant"
              />
              <AvatarFallback>N</AvatarFallback>
            </Avatar>
            <div>
              <h2 className="font-semibold text-zinc-900 dark:text-zinc-100">Noon</h2>
              <p className="text-xs text-zinc-500 dark:text-zinc-400">data platform</p>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-1 p-4">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-xs font-medium text-muted-foreground uppercase tracking-wider">
            Resources
          </h2>
          <Badge variant="secondary" className="text-xs">
            {Object.values(resourceTypes).reduce((a, b) => a + b, 0)}
          </Badge>
        </div>
        <AnimatePresence mode="wait">
          {Object.entries(resourceTypes).map(([type, count], index) => (
            <motion.div
              key={type}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ delay: index * 0.05 }}
            >
              <Button
                variant="ghost"
                className="w-full justify-between group h-9 px-3"
                onClick={() => handleResourceTypeClick(type)}
              >
                <div className="flex items-center gap-2">
                  {getResourceTypeIcon(type)}
                  <span className="text-sm capitalize">{type}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Badge
                    variant="secondary"
                    className="text-xs bg-muted group-hover:bg-primary/10 transition-colors"
                  >
                    {count}
                  </Badge>
                  <ChevronRight className="h-4 w-4 text-muted-foreground opacity-0 group-hover:opacity-100 transition-opacity" />
                </div>
              </Button>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <div className="space-y-1 p-4">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-xs font-medium text-muted-foreground uppercase tracking-wider">
            Labels
          </h2>
          <Button
            variant="ghost"
            size="sm"
            className="h-6 px-2"
            onClick={() => setIsLabelsExpanded(!isLabelsExpanded)}
          >
            {isLabelsExpanded ? (
              <ChevronDown className="h-4 w-4" />
            ) : (
              <ChevronRight className="h-4 w-4" />
            )}
          </Button>
        </div>

        <AnimatePresence>
          {isLabelsExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="space-y-1 pt-1"
            >
              {tags.map((tag) => (
                <motion.div
                  key={tag.name}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                >
                  <TagBadge
                    key={tag.id}
                    variant="outline"
                    tag={tag}
                    onClick={() => {
                      const searchParams = new URLSearchParams();
                      searchParams.set('filter', `labels:${tag.name}`);
                      window.location.search = searchParams.toString();
                    }}
                  >
                    {tag.name}
                  </TagBadge>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const ResourceLink = ({ doc }) => {
  const prefixUrl = doc.id_domain ? `/domains/${doc.id_domain}` : '';
  const parts = [];

  const buildLink = (text, href, icon) => (
    <a
      key={href}
      href={href}
      className="group inline-flex items-center gap-1.5 text-sm hover:text-primary transition-colors"
    >
      {icon}
      <span className="group-hover:underline">{text}</span>
    </a>
  );

  if (doc.resource_type === 'doc') {
    parts.push(
      buildLink(
        doc.resource_name,
        `${prefixUrl}/assets/docs/${doc.id}/`,
        <FileText className="h-4 w-4" />
      )
    );
  } else if (doc.resource_type === 'notebook') {
    parts.push(
      buildLink(
        doc.resource_name,
        `${prefixUrl}/assets/notebooks/${doc.id}/`,
        <Notebook className="h-4 w-4" />
      )
    );
  } else if (doc.resource_type === 'user') {
    parts.push(
      buildLink(
        doc.resource_name,
        `${prefixUrl}/users/${doc.id}/`,
        <User className="h-4 w-4" />
      )
    );
  } else if (doc.resource_type === 'looker') {
    parts.push(
      buildLink(
        doc.resource_name,
        `${prefixUrl}/assets/lookers/${doc.id}/`,
        <BarChart className="h-4 w-4" />
      )
    );
  } else if (doc.resource_type === 'datastudio') {
    parts.push(
      buildLink(
        doc.name,
        `${prefixUrl}/assets/datastudios/${doc.id}/`,
        <Layout className="h-4 w-4" />
      )
    );
  } else if (doc.resource_type === 'dag' || doc.resource_type === 'task') {
    if (doc.param_dag_name) {
      parts.push(
        buildLink(
          doc.param_dag_name,
          `${prefixUrl}/dags/${doc.param_id_dag || doc.id}/`,
          <File className="h-4 w-4" />
        )
      );
    }
    if (doc.param_task_name) {
      parts.push(
        buildLink(
          doc.param_task_name,
          `${prefixUrl}/dags/${doc.param_id_dag}/tasks/${doc.id}`,
          <File className="h-4 w-4" />
        )
      );
    }
  } else {
    if (doc.param_project) {
      parts.push(
        buildLink(
          doc.param_project,
          `${prefixUrl}/assets/bigquery/projects/${doc.param_project}`,
          <Layout className="h-4 w-4" />
        )
      );
    }
    if (doc.param_dataset) {
      parts.push(
        buildLink(
          doc.param_dataset,
          `${prefixUrl}/assets/bigquery/projects/${doc.param_project}/datasets/${doc.param_dataset}`,
          <Database className="h-4 w-4" />
        )
      );
    }
    if (doc.param_table) {
      parts.push(
        buildLink(
          doc.param_table,
          `${prefixUrl}/assets/bigquery/projects/${doc.param_project}/datasets/${doc.param_dataset}/tables/${doc.param_table}`,
          <Table className="h-4 w-4" />
        )
      );
    }
    if (doc.param_column_name) {
      parts.push(
        <span
          key="column"
          className="text-sm text-muted-foreground flex items-center gap-1.5"
        >
          <Columns className="h-4 w-4" />
          {doc.param_column_name}
        </span>
      );
    }
  }

  return (
    <div className="flex items-center gap-2 text-sm">
      {parts.map((part, index) => (
        <Fragment key={index}>
          {index > 0 && (
            <span className="text-muted-foreground">
              <ChevronRight className="h-4 w-4" />
            </span>
          )}
          {part}
        </Fragment>
      ))}
    </div>
  );
};

export default Search;
