import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import {
  MessageSquare,
  FileText,
  UserPlus,
  Building2,
  Shield,
  Settings,
  GitCommit,
  AlertCircle,
  Clock,
  Check,
  X,
  FileEdit,
  UserCog,
} from 'lucide-react';
import { cn } from "@/lib/utils";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";

const activityTypes = {
  CHAT: {
    icon: MessageSquare,
    color: 'text-blue-500',
    badge: 'Chat',
    bgColor: 'bg-blue-500/10',
  },
  DOCUMENT: {
    icon: FileText,
    color: 'text-yellow-500',
    badge: 'Document',
    bgColor: 'bg-yellow-500/10',
  },
  USER_JOIN: {
    icon: UserPlus,
    color: 'text-green-500',
    badge: 'User',
    bgColor: 'bg-green-500/10',
  },
  DOMAIN_UPDATE: {
    icon: Building2,
    color: 'text-purple-500',
    badge: 'Domain',
    bgColor: 'bg-purple-500/10',
  },
  PERMISSION: {
    icon: Shield,
    color: 'text-red-500',
    badge: 'Permission',
    bgColor: 'bg-red-500/10',
  },
  SETTINGS: {
    icon: Settings,
    color: 'text-gray-500',
    badge: 'Settings',
    bgColor: 'bg-gray-500/10',
  }
};

const Timeline = ({ userId }) => {
  // This would normally be fetched from an API
  const activities = [
    {
      id: 1,
      type: 'CHAT',
      title: 'Started a new conversation',
      description: 'Initiated chat with Support Team regarding deployment issues',
      timestamp: '2024-10-26T10:30:00',
      status: 'completed',
      metadata: {
        chatId: '123',
        participants: ['Support Team']
      }
    },
    {
      id: 2,
      type: 'DOCUMENT',
      title: 'Updated documentation',
      description: 'Modified deployment guidelines for new feature rollout',
      timestamp: '2024-10-26T09:15:00',
      status: 'completed',
      metadata: {
        docId: '456',
        changes: ['Updated deployment steps', 'Added troubleshooting guide']
      }
    },
    {
      id: 3,
      type: 'DOMAIN_UPDATE',
      title: 'Domain role updated',
      description: 'Received additional permissions in Development domain',
      timestamp: '2024-10-25T16:45:00',
      status: 'completed',
      metadata: {
        domain: 'Development',
        role: 'Senior Developer'
      }
    },
    {
      id: 4,
      type: 'PERMISSION',
      title: 'Permission request',
      description: 'Requested access to Production deployment tools',
      timestamp: '2024-10-25T14:20:00',
      status: 'pending',
      metadata: {
        requestId: '789',
        requestType: 'Access Request'
      }
    },
    {
      id: 5,
      type: 'SETTINGS',
      title: 'Profile settings updated',
      description: 'Modified notification preferences and contact information',
      timestamp: '2024-10-25T11:00:00',
      status: 'completed',
      metadata: {
        changes: ['Email notifications', 'Contact details']
      }
    }
  ];

  const TimelineItem = ({ activity }) => {
    const ActivityIcon = activityTypes[activity.type]?.icon || AlertCircle;
    
    return (
      <div className="relative pb-8">
        {/* Vertical line */}
        <div className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-800" />
        
        <div className="relative flex items-start space-x-3">
          {/* Icon */}
          <div className={cn(
            "relative h-8 w-8 flex items-center justify-center rounded-full",
            activityTypes[activity.type]?.bgColor
          )}>
            <ActivityIcon className={cn(
              "h-5 w-5",
              activityTypes[activity.type]?.color
            )} />
          </div>

          {/* Content */}
          <div className="flex-1 min-w-0">
            <HoverCard>
              <HoverCardTrigger asChild>
                <div className="relative flex items-center justify-between cursor-pointer">
                  <div>
                    <h3 className="text-sm font-semibold">{activity.title}</h3>
                    <p className="text-sm text-muted-foreground mt-0.5">
                      {activity.description}
                    </p>
                  </div>
                  <Badge 
                    variant="secondary"
                    className={cn(
                      "ml-2",
                      activityTypes[activity.type]?.bgColor,
                      activityTypes[activity.type]?.color
                    )}
                  >
                    {activityTypes[activity.type]?.badge}
                  </Badge>
                </div>
              </HoverCardTrigger>
              <HoverCardContent className="w-80">
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <h4 className="font-semibold">{activity.title}</h4>
                    <Badge variant={activity.status === 'completed' ? 'default' : 'outline'}>
                      {activity.status}
                    </Badge>
                  </div>
                  <p className="text-sm">{activity.description}</p>
                  <div className="text-xs text-muted-foreground">
                    {Object.entries(activity.metadata).map(([key, value]) => (
                      <div key={key} className="flex items-center mt-1">
                        <span className="font-medium capitalize">{key.replace('_', ' ')}:</span>
                        <span className="ml-1">
                          {Array.isArray(value) ? value.join(', ') : value}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="text-xs text-muted-foreground mt-2">
                    {formatDistanceToNow(new Date(activity.timestamp), { addSuffix: true })}
                  </div>
                </div>
              </HoverCardContent>
            </HoverCard>

            <div className="flex items-center text-xs text-muted-foreground mt-1">
              <Clock className="h-3 w-3 mr-1" />
              {formatDistanceToNow(new Date(activity.timestamp), { addSuffix: true })}
              <span className="mx-1">•</span>
              {activity.status === 'completed' ? (
                <span className="flex items-center text-green-500">
                  <Check className="h-3 w-3 mr-1" />
                  Completed
                </span>
              ) : (
                <span className="flex items-center text-yellow-500">
                  <Clock className="h-3 w-3 mr-1" />
                  Pending
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ScrollArea className="h-[400px] pr-4">
      <div className="space-y-6">
        {activities.map((activity) => (
          <TimelineItem key={activity.id} activity={activity} />
        ))}
      </div>
    </ScrollArea>
  );
};

export default Timeline;