import { Outlet } from 'react-router-dom';
import AssetInfo from './AssetInfo';

export default function BqAssetProject() {
  return (
    <div className="">
      <div className="">BqAssetProject</div>
    </div>
  );
}
