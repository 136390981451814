import { useState, useEffect } from "react";
import Hisory from "../components/History";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";

import {
  Rocket,
} from "lucide-react";

const Home = () => {
  const [loading, setLoading] = useState(true);

  const Loader = () => (
    <div className="w-full h-4 bg-gray-300 rounded-sm overflow-hidden">
      <div className="h-full bg-blue-600 animate-loader"></div>
    </div>
  );

  return (
    <>
      <div className="">
        <Card className="border-0 shadow-lg">
          <CardHeader className="border-b bg-muted/50 rounded-t-lg">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Rocket className="h-5 w-5 text-primary" />
                <div>
                  <CardTitle>Activity History</CardTitle>
                  <CardDescription>
                    Manage and view all users in the system
                  </CardDescription>
                </div>
              </div>
            </div>
          </CardHeader>
        </Card>

        <Hisory></Hisory>
      </div>

    </>
  );
};

export default Home;
