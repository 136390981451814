import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Separator } from '@/components/ui/separator';
import { Badge } from '@/components/ui/badge';
import { Alert } from '@/components/ui/alert';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { python } from '@codemirror/lang-python';
import { dracula } from '@uiw/codemirror-theme-dracula';
import { oneDark } from '@codemirror/theme-one-dark';

import { Copy, Pencil, Save } from 'lucide-react';

import { useToast } from '@/components/hooks/use-toast';
import { ToastProvider } from '@/components/ui/toast';

import { PERMISSIONS } from '../../context/permissions';
import { fetchAllTagList } from '../../utils/domain';
import { getDagTask, getOperatorType, updateDagTask } from '../../utils/dag';
import { getResourceTags } from '../../utils/tags';
import TagModal from '../common/Tags';
import CommitMessage from '../modals/CommitMessage';
import PermissionButton from '../common/PermissonButton';

const CommonTask = (props) => {
  const [open, setOpen] = useState(true);
  const [task, setTask] = useState();
  const [taskName, setTaskName] = useState('');
  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const { domainId, dagId } = useParams();
  const [taskType, setTaskType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [params, setParams] = useState({});
  const editorRef = useRef(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [showTagModal, setShowTagModal] = useState(false);
  const [showCommitMessageModal, setShowCommitMessageModal] = useState(false);
  const [commitMessage, setCommitMessage] = useState('');
  const [lastIdHistory, setLastIdHistory] = useState('');
  const [errors, setErrors] = useState([]);
  const { toast } = useToast();

  const [theme, setTheme] = useState('dracula');

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getTask();
  }, []);

  useEffect(() => {
    if (task) {
      getTaskTypeInfo();
      fetchAllTags();
      fetchResourceTags();
    }
  }, [task]);

  const getTask = async () => {
    try {
      const task = await getDagTask(dagId, props.taskId);
      setTask(task);
      setTaskName(task.task_name);
      setSummary(task.summary);
      setDescription(task.description);
      setParams(task.params);
    } catch (error) {
      console.error('Error fetching task:', error);
    }
  };

  const getTaskTypeInfo = async () => {
    try {
      const data = await getOperatorType(props.task_type);
      if (data && data.fields) {
        data.fields = data.fields.filter(
          (field) =>
            field.name !== 'activity_labels' &&
            field.name !== 'heartbeat_details' &&
            field.name !== 'task_id'
        );
        setTaskType(data);
      }
    } catch (error) {
      console.error('Error fetching task type info:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllTags = async () => {
    const tags = await fetchAllTagList();
    setTags(tags);
  };

  const fetchResourceTags = async () => {
    const resourceTags = await getResourceTags(props.taskId);
    setSelectedTags(resourceTags);
  };

  const handleEditorChange = (fieldName, value) => {
    setParams({ ...params, [fieldName]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }

    setIsSaving(true);
    const updatedParams = { ...params };
    try {
      const updatedTask = {
        ...task,
        task_name: taskName,
        summary: summary,
        description: description,
        params: updatedParams,
      };

      const body = JSON.stringify(updatedTask);
      const response = await updateDagTask(dagId, props.taskId, body);
      setShowCommitMessageModal(false);
      handleClose();
      toast({
        description: 'Task edited succesfully',
      });
    } catch (error) {
      console.error('Error occurred while updating task:', error);
      setErrors([...errors, 'Failed to save changes. Please try again.']);
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    props.onCloseTask();
  };

  const validateFields = () => {
    let errorMessages = [];
    taskType.fields.forEach((field) => {
      if (field.required && !params[field.name]) {
        errorMessages.push(`${field.name} is required.`);
      }
    });
    setErrors(errorMessages);
    return errorMessages.length === 0;
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    toast({
      description: 'Code copied to clipboard!',
    });
  };

  const getThemeObject = () => {
    switch (theme) {
      case 'dracula':
        return dracula;
      case 'oneDark':
        return oneDark;
      default:
        return dracula;
    }
  };

  const generateFields = () => {
    if (!taskType || !taskType.fields) return null;

    return (
      taskType &&
      taskType.fields.map((field) => {
        if (['sql', 'bq_sql', 'python'].includes(field.name)) {
          return (
            <div key={field.name} className="mt-6">
              <Label className="text-sm font-medium text-gray-900">
                {`${field.name} (${normalize_type(field.outer_type)})`}
                {field.required ? (
                  <span className="text-red-500"> (required)</span>
                ) : (
                  <span className="text-green-500"> (optional)</span>
                )}
              </Label>
              <div className="mt-2">
                <div className="flex justify-between items-center mb-2">
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => handleCopy(params[field.name])}
                  >
                    <Copy className="h-4 w-4 mr-1" />
                    Copy
                  </Button>
                  <Select value={theme} onValueChange={setTheme}>
                    <SelectTrigger className="w-[150px]">
                      <SelectValue placeholder="Select Theme" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="dracula">Dracula</SelectItem>
                      <SelectItem value="oneDark">One Dark</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <CodeMirror
                  value={params[field.name]}
                  extensions={[field.name === 'python' ? python() : sql()]}
                  theme={getThemeObject()}
                  editable={true}
                  basicSetup={{
                    lineNumbers: true,
                    highlightActiveLine: true,
                    foldGutter: true,
                    highlightSelectionMatches: true,
                  }}
                  onChange={(value) => handleEditorChange(field.name, value)}
                  style={{
                    height: 'auto',
                    borderRadius: '5px',
                    border: '1px solid #e2e8f0',
                  }}
                  className="overflow-hidden"
                />
              </div>
            </div>
          );
        } else {
          return (
            <div key={field.name} className="mt-6">
              <Label className="text-sm font-medium text-gray-900">
                {`${field.name} (${normalize_type(field.outer_type)})`}
                {field.required ? (
                  <span className="text-red-500"> (required)</span>
                ) : (
                  <span className="text-green-500"> (optional)</span>
                )}
              </Label>
              <div className="mt-2">
                <Input
                  type="text"
                  name={field.name}
                  id={field.name}
                  value={params?.[field.name] || ''}
                  placeholder={field.default}
                  onChange={(e) =>
                    setParams({ ...params, [field.name]: e.target.value })
                  }
                />
              </div>
            </div>
          );
        }
      })
    );
  };

  const normalize_type = (task_type) => {
    return task_type.replace('typing.', '');
  };

  const TagBadge = ({ tag }) => {
    const navigate = useNavigate();

    const handleTagClick = () => {
      navigate(`/search?filter=labels:${tag.name}`);
    };

    return (
      <Badge
        variant="secondary"
        onClick={handleTagClick}
        className="cursor-pointer mr-2"
      >
        {tag.name}
      </Badge>
    );
  };

  return (
    <ToastProvider>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent className="max-w-7xl h-[90vh] overflow-y-auto p-12">
          <DialogHeader>
            <DialogTitle>{taskType && taskType.class_name}</DialogTitle>
          </DialogHeader>
          {loading ? (
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
            </div>
          ) : (
            <form>
              <div className="space-y-6">
                <div>
                  <Label htmlFor="task_name">Task Name</Label>
                  <div className="mt-2">
                    <Input
                      type="text"
                      name="task_name"
                      id="task_name"
                      placeholder="Give a unique task name"
                      value={taskName}
                      onChange={(e) => setTaskName(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <Label htmlFor="description">Description</Label>
                  <div className="mt-2">
                    <Textarea
                      name="description"
                      id="description"
                      rows={4}
                      placeholder="Enter a description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
                {generateFields()}
                {errors.length > 0 && (
                  <Alert variant="destructive" className="mt-4">
                    <ul className="list-disc pl-5">
                      {errors.map((error, index) => (
                        <li key={index} className="text-sm">
                          {error}
                        </li>
                      ))}
                    </ul>
                  </Alert>
                )}
              </div>
              <DialogFooter className="mt-8">
                <Button variant="outline" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="default"
                  className="bg-black text-white hover:bg-gray-800"
                  onClick={handleSave}
                  disabled={isSaving}
                >
                  <Save className="h-4 w-4 mr-2" />
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              </DialogFooter>
            </form>
          )}
          {showTagModal && (
            <TagModal
              resource_id={props.taskId}
              onClose={() => setShowTagModal(false)}
            />
          )}
          {showCommitMessageModal && (
            <CommitMessage
              id_history={lastIdHistory}
              onClose={() => {
                setShowCommitMessageModal(false);
                props.onCloseTask();
              }}
              message={commitMessage}
            />
          )}
          <DialogClose />
        </DialogContent>
      </Dialog>
    </ToastProvider>
  );
};

export default CommonTask;
