import { createContext, useState } from "react";

const SelectedContext = createContext({
  selectedDomain: "",
  setSelectedDomain: () => {},
  selectedDag: "",
  setSelectedDag: () => {},
  selectedTask: "",
  setSelectedTask: () => {},
});

const SelectedProvider = ({ children }) => {
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedDag, setSelectedDag] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  return (
    <SelectedContext.Provider
      value={{
        selectedDomain,
        setSelectedDomain,
        selectedDag,
        setSelectedDag,
        selectedTask,
        setSelectedTask,
      }}
    >
      {children}
    </SelectedContext.Provider>
  );
};

export {SelectedContext, SelectedProvider}
