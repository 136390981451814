import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { SelectedContext } from '../context/SelectedContext';
import { AuthContext } from '../context/AuthContext';
import { cn } from '@/lib/utils';
import {
  Home,
  Users,
  BarChart3,
  Settings,
  MessageSquare,
  ChevronRight,
  Search,
  Menu,
  Bell,
  LogOut,
  User,
  Circle,
  Compass,
  ChevronFirst,
  ChevronLast,
  Database,
  PanelLeftOpen,
  PanelRightOpen,
  ChevronDown,
} from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';

import { Sheet, SheetContent } from '@/components/ui/sheet';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from '@/components/ui/command';
import { Kbd } from '@/components/ui/kbd';
import { getDomains } from '../utils/domain';
import debounce from 'lodash.debounce';
import ThemeToggle from '@/components/ThemeToggle';
import SearchCommand from '@/components/search/SearchCommand';

const navigation = [
  { name: 'Home', path: '/', icon: Home },
  { name: 'People', path: '/users', icon: Users },
  { name: 'Analytics', path: '/reports', icon: BarChart3 },
  { name: 'Settings', path: '/settings', icon: Settings },
  { name: 'Chat', path: '/chat', icon: MessageSquare },
];

const MainLayout = ({ children }) => {
  const { selectedDomain, setSelectedDomain } = useContext(SelectedContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dunits, setDunits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openCommandMenu, setOpenCommandMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const down = (e) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpenCommandMenu((open) => !open);
        if (!openCommandMenu) {
          setSearchQuery('');
        }
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, [openCommandMenu]);

  const handleSignOut = async () => {
    try {
      const response = await fetch('http://localhost:8080/logout', {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        navigate('/login');
      }
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };

  useEffect(() => {
    const fetchDomains = async () => {
      setLoading(true);
      try {
        const fetchedDomains = await getDomains();
        if (fetchedDomains?.length > 0) {
          const parentDomains = fetchedDomains.filter(
            (domain) => domain.id_parent_domain === null
          );
          const domainsWithSubdomains = parentDomains.map((parent) => ({
            ...parent,
            subdomains: fetchedDomains.filter(
              (sub) => sub.id_parent_domain === parent.id_domain
            ),
          }));
          setDunits(domainsWithSubdomains);
        }
      } catch (error) {
        console.error('Error fetching domains:', error);
        setDunits([]);
      } finally {
        setLoading(false);
      }
    };

    fetchDomains();
  }, []);

  const getFilteredDunits = () => {
    if (!searchQuery.trim()) {
      return { matchedParents: dunits, matchedSubdomains: [] };
    }
    const lowerCaseQuery = searchQuery.toLowerCase();
    const matchedParents = [];
    const matchedSubdomains = [];

    dunits.forEach((parent) => {
      if (parent.id_domain.toLowerCase().includes(lowerCaseQuery)) {
        matchedParents.push(parent);
      }
      parent.subdomains.forEach((subdomain) => {
        if (subdomain.id_domain.toLowerCase().includes(lowerCaseQuery)) {
          matchedSubdomains.push(subdomain);
        }
      });
    });

    return { matchedParents, matchedSubdomains };
  };

  const Sidebar = ({ className }) => {
    const [sidebarSearchQuery, setSidebarSearchQuery] = useState('');
    const [localExpandedDunits, setLocalExpandedDunits] = useState({});

    const { matchedParents, matchedSubdomains } = getFilteredDunits();
    useEffect(() => {
      if (sidebarSearchQuery.trim()) {
        const newExpanded = {};
        matchedParents.forEach((parent) => {
          newExpanded[parent.id_domain] = true;
        });
        setLocalExpandedDunits(newExpanded);
      } else {
        setLocalExpandedDunits({});
      }
    }, [sidebarSearchQuery, matchedParents]);

    const toggleLocalExpand = (domainId) => {
      setLocalExpandedDunits((prev) => ({
        ...prev,
        [domainId]: !prev[domainId],
      }));
    };

    return (
      <div
        className={cn(
          'relative h-full',
          isCollapsed ? 'w-16' : 'w-72',
          'transition-all duration-300 ease-in-out',
          className
        )}
      >
        <div className="flex h-full flex-col">
          <div className="border-b border-zinc-200 dark:border-zinc-800 p-4">
            <div className="flex items-center justify-between">
              {!isCollapsed && (
                <div className="flex items-center gap-3">
                  <Avatar className="h-10 w-10 bg-gradient-to-br from-violet-500 to-indigo-500">
                    <AvatarImage
                      src="https://play-lh.googleusercontent.com/4-wa067yiYYMj5rRdHm82jPdDiSHSoIkwwvHA0teCEauFFU_cjx053jMBwBxfo2anJo"
                      alt="AI Assistant"
                    />
                    <AvatarFallback>N</AvatarFallback>
                  </Avatar>
                  <div>
                    <h2 className="font-semibold text-zinc-900 dark:text-zinc-100">
                      Noon
                    </h2>
                    <p className="text-xs text-zinc-500 dark:text-zinc-400">
                      data platform
                    </p>
                  </div>
                </div>
              )}
              {isCollapsed && (
                <Avatar className="h-10 w-10 bg-gradient-to-br from-violet-500 to-indigo-500">
                  <AvatarImage
                    src="https://play-lh.googleusercontent.com/4-wa067yiYYMj5rRdHm82jPdDiSHSoIkwwvHA0teCEauFFU_cjx053jMBwBxfo2anJo"
                    alt="AI Assistant"
                  />
                  <AvatarFallback>N</AvatarFallback>
                </Avatar>
              )}
            </div>
          </div>
          <div className="space-y-4 py-4 flex flex-col h-full">
            <div className="px-3">
              <div className="space-y-1">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.path}
                    className={({ isActive }) =>
                      cn(
                        'flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors',
                        isActive
                          ? 'bg-primary text-primary-foreground'
                          : 'hover:bg-accent hover:text-accent-foreground'
                      )
                    }
                  >
                    <item.icon className="h-4 w-4" />
                    {!isCollapsed && item.name}
                  </NavLink>
                ))}
              </div>
            </div>
            <div className="px-3 py-2 flex-1">
              {!isCollapsed && (
                <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
                  Domains
                </h2>
              )}
              <ScrollArea className="h-[calc(100vh-300px)]">
                <div className="space-y-1">
                  {dunits.map((parent) => (
                    <Collapsible
                      key={parent.id_domain}
                      open={!isCollapsed && localExpandedDunits[parent.id_domain]}
                      onOpenChange={() =>
                        !isCollapsed && toggleLocalExpand(parent.id_domain)
                      }
                    >
                      <div className="flex items-center justify-between">
                        <NavLink
                          to={`/domains/${parent.id_domain}`}
                          className={({ isActive }) =>
                            cn(
                              'flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors flex-grow',
                              isActive
                                ? 'bg-primary text-primary-foreground'
                                : 'hover:bg-accent hover:text-accent-foreground'
                            )
                          }
                          onClick={() => setSelectedDomain(parent)}
                        >
                          <Database className="h-4 w-4" />
                          {!isCollapsed && <span>{parent.id_domain}</span>}
                        </NavLink>
                        {!isCollapsed && parent.subdomains?.length > 0 && (
                          <CollapsibleTrigger className="ml-auto">
                            <ChevronRight
                              className={cn(
                                'h-4 w-4 transition-transform',
                                localExpandedDunits[parent.id_domain] ? 'rotate-90' : ''
                              )}
                            />
                          </CollapsibleTrigger>
                        )}
                      </div>
                      {!isCollapsed && parent.subdomains?.length > 0 && (
                        <CollapsibleContent className="pl-6 space-y-1">
                          {parent.subdomains.map((subdomain) => (
                            <NavLink
                              key={subdomain.id_domain}
                              to={`/domains/${subdomain.id_domain}`}
                              className={({ isActive }) =>
                                cn(
                                  'flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors',
                                  isActive
                                    ? 'bg-primary text-primary-foreground'
                                    : 'hover:bg-accent hover:text-accent-foreground'
                                )
                              }
                              onClick={() => setSelectedDomain(subdomain)}
                            >
                              <Circle className="h-3 w-3" />
                              <span>{subdomain.id_domain}</span>
                            </NavLink>
                          ))}
                        </CollapsibleContent>
                      )}
                    </Collapsible>
                  ))}
                </div>
              </ScrollArea>
            </div>
            <div className="border-t border-zinc-200 dark:border-zinc-800 p-4 space-y-2">
              <ThemeToggle
                className={cn(
                  'w-full justify-start text-zinc-600 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100',
                  !isCollapsed && 'justify-between'
                )}
              >
                {!isCollapsed && <span className="ml-2">Theme</span>}
              </ThemeToggle>
              <Button
                variant="ghost"
                className="w-full justify-start text-zinc-600 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
              >
                <Settings className="h-4 w-4" />
                {!isCollapsed && <span className="ml-2">Settings</span>}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen">
      <Sheet open={sidebarOpen} onOpenChange={setSidebarOpen}>
        <SheetContent side="left" className="w-72 p-0">
          <Sidebar />
        </SheetContent>
      </Sheet>

      <div className="hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-72">
        <Sidebar className="border-r" />
      </div>

      <div
        className={cn(
          'lg:pl-72 transition-all duration-300',
          isCollapsed && 'lg:pl-16'
        )}
      >
        <header className=" border-b border-zinc-200 dark:border-zinc-800 p-4">
          <div className="flex items-center gap-x-4 flex-1 justify-between">
            <div className="flex items-center gap-x-4 justify-start">
              <div className="relative hidden lg:block">
                <Button
                  variant="ghost"
                  size="icon"
                  className="z-50 relative"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  {isCollapsed ? (
                    <PanelLeftOpen className="h-4 w-4" />
                  ) : (
                    <PanelRightOpen className="h-4 w-4" />
                  )}
                </Button>
              </div>
              <Button
                variant="ghost"
                className="lg:hidden"
                size="icon"
                onClick={() => setSidebarOpen(true)}
              >
                <Menu className="h-6 w-6" />
              </Button>
            </div>
            <div className="flex items-center gap-x-4  justify-between">
              <Button
                onClick={() => navigate('/search')}
                variant="outline"
                className="group relative inline-flex items-center gap-2.5 py-2 font-semibold transition-all duration-200 hover:shadow-md mr-2"
              >
                <span className="relative flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 group-hover:from-cyan-600 group-hover:to-blue-600 transition-all duration-200">
                  <Compass className="h-3 w-3 text-white" aria-hidden="true" />
                </span>
                <span className="relative">Explore</span>
              </Button>
              <SearchCommand
                className="mr-2"
                open={openCommandMenu}
                onOpenChange={setOpenCommandMenu}
              />

              <div className="flex items-center gap-2">
                <Button variant="ghost" size="icon" className="h-9 w-9">
                  <Bell className="h-4 w-4" />
                </Button>

                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="sm" className="gap-2">
                      <span className="hidden md:inline-block">
                        {user?.name || 'User'}
                      </span>
                      <ChevronDown className="h-4 w-4 opacity-50" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-48">
                    <DropdownMenuLabel>My Account</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>Profile</DropdownMenuItem>
                    <DropdownMenuItem>Sign out</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </header>
        {isCollapsed && (
          <div
            className="fixed inset-y-0 left-16 w-8 z-30 cursor-pointer bg-transparent"
            onClick={() => setIsCollapsed(false)}
          />
        )}
        <main className="pl-6 py-6 pr-12">{children}</main>
      </div>
    </div>
  );
};

export default MainLayout;
