import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { getAllUsers } from '../../utils/user';

function UserManager({ teamID, open, onClose, onSave }) {
  const [users, setUsers] = useState([]);
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [role, setRole] = useState('');
  const [userPopoverOpen, setUserPopoverOpen] = useState(false);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState('');

  const roles = ['Owner', 'Admin', 'Editor', 'Viewer'];

  useEffect(() => {
    if (open) {
      setSelectedUserEmail('');
      setRole('');
      setError(null);
    }
  }, [open]);

  const handleSearch = async (query) => {
    if (query.length < 2) {
      setUsers([]);
      return;
    }

    try {
      const result = await getAllUsers(1, 30, query);
      if (result && Array.isArray(result.users)) {
        setUsers(result.users);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
    }
  };

  const handleSave = () => {
    if (!selectedUserEmail || !role) {
      setError('Please select both a user and a role');
      return;
    }
    const selectedUser = users.find((user) => user.user_email === selectedUserEmail);
    if (selectedUser) {
      onSave({ ...selectedUser, role });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Add Member</DialogTitle>
        </DialogHeader>

        {error && (
          <Alert variant="destructive">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="flex justify-between items-center py-2">
          <Badge variant="secondary" className="text-sm">
            {users.find((u) => u.user_email === selectedUserEmail)?.name ||
              'No user selected'}
          </Badge>
          <Badge variant="outline" className="text-sm">
            {role || 'No role selected'}
          </Badge>
        </div>

        <div className="grid gap-4 py-4">
          {/* User Selection */}
          <Popover open={userPopoverOpen} onOpenChange={setUserPopoverOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={userPopoverOpen}
                className="w-full justify-between"
              >
                {selectedUserEmail
                  ? users.find((user) => user.user_email === selectedUserEmail)?.name
                  : 'Search users...'}
                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[--radix-popover-trigger-width] p-0">
              <Command shouldFilter={false}>
                <CommandInput
                  placeholder="Search users..."
                  onValueChange={handleSearch}
                />
                <CommandList>
                  <CommandEmpty>No users found.</CommandEmpty>
                  <CommandGroup>
                    {users.map((user) => (
                      <CommandItem
                        key={user.user_email}
                        value={user.user_email}
                        onSelect={(currentValue) => {
                          setSelectedUserEmail(
                            currentValue === selectedUserEmail ? '' : currentValue
                          );
                          setUserPopoverOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            'mr-2 h-4 w-4',
                            selectedUserEmail === user.user_email
                              ? 'opacity-100'
                              : 'opacity-0'
                          )}
                        />
                        {user.name} ({user.user_email})
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>

          {/* Role Selection */}
          <Select value={role} onValueChange={setRole}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select role" />
            </SelectTrigger>
            <SelectContent>
              {roles.map((roleOption) => (
                <SelectItem key={roleOption} value={roleOption}>
                  {roleOption}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <DialogFooter className="sm:justify-end">
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!selectedUserEmail || !role}
            onClick={handleSave}
          >
            Add Member
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default UserManager;
