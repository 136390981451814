import React, { useEffect, useState } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { Skeleton } from '@/components/ui/skeleton';
import AssetInfo from './AssetInfo';
import { layoutConfig } from './AssetLayoutConf';
import { fetchData } from '../../utils/baseRequest';

const ContentSkeleton = () => (
  <div className="space-y-4">
    <Skeleton className="h-8 w-[250px]" />
    <div className="grid gap-4">
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-[90%]" />
      <Skeleton className="h-4 w-[80%]" />
    </div>
    <div className="grid gap-4 mt-8">
      <Skeleton className="h-[200px] w-full rounded-xl" />
      <div className="grid grid-cols-2 gap-4">
        <Skeleton className="h-[100px] w-full rounded-lg" />
        <Skeleton className="h-[100px] w-full rounded-lg" />
      </div>
    </div>
  </div>
);

const InfoSkeleton = () => (
  <div className="space-y-6">
    <div className="space-y-2">
      <Skeleton className="h-4 w-[100px]" />
      <Skeleton className="h-20 w-full" />
    </div>
    <div className="space-y-2">
      <Skeleton className="h-4 w-[80px]" />
      <div className="flex gap-2">
        <Skeleton className="h-6 w-20 rounded-full" />
        <Skeleton className="h-6 w-24 rounded-full" />
        <Skeleton className="h-6 w-16 rounded-full" />
      </div>
    </div>
    <div className="space-y-2">
      <Skeleton className="h-4 w-[120px]" />
      <div className="space-y-2">
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-12 w-full" />
      </div>
    </div>
  </div>
);

export default function AssetMain() {
  const params = useParams();
  const location = useLocation();
  const segments = location.pathname.split('/').filter(Boolean);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const assetType =
    segments.find((segment) => layoutConfig.hasOwnProperty(segment)) || 'default';

  let pageType;
  if (layoutConfig[assetType]) {
    switch (assetType) {
      case 'docs':
        pageType = params.docId ? 'docs' : 'list';
        break;
      case 'notebooks':
        pageType = params.notebookId ? 'notebooks' : 'list';
        break;
      case 'datastudios':
        pageType = params.datastudioId ? 'datastudios' : 'list';
        break;
      case 'lookers':
        pageType = params.lookerId ? 'lookers' : 'list';
        break;
      case 'bigquery':
        if (params.projectId && params.datasetId && params.tableId) {
          pageType = 'tables';
        } else if (params.projectId && params.datasetId) {
          pageType = 'datasets';
        } else if (params.projectId) {
          pageType = 'projects';
        } else {
          pageType = 'list';
        }
        break;
      default:
        pageType = 'list';
    }
  }

  const layout = layoutConfig[assetType]
    ? layoutConfig[assetType][pageType]
    : 'default';

  useEffect(() => {
    const fetchResourceData = async () => {
      setLoading(true);
      try {
        let apiUrl = buildApiUrl();
        if (apiUrl) {
          const response = await fetchData(apiUrl);
          setData(response);
        }
      } catch (error) {
        console.error('Error fetching resource data:', error);
      } finally {
        setTimeout(() => setLoading(false), 300);
      }
    };

    fetchResourceData();
  }, [assetType, pageType, params]);

  const buildApiUrl = () => {
    switch (assetType) {
      case 'bigquery':
        if (pageType === 'tables') {
          return `/resources/tables/${params.projectId}/${params.datasetId}/${params.tableId}`;
        } else if (pageType === 'datasets') {
          return `/resources/datasets/${params.projectId}/${params.datasetId}`;
        } else if (pageType === 'projects') {
          return `/resources/projects/${params.projectId}`;
        }
        break;
      case 'docs':
        return pageType === 'docs'
          ? `/resources/doc/${params.docId}`
          : `/resources/${params.domainId}/doc/`;
      case 'notebooks':
        return pageType === 'notebooks'
          ? `/resources/notebooks/${params.notebookId}`
          : `/resources/${params.domainId}/notebook/`;
      case 'datastudios':
        return pageType === 'datastudios'
          ? `/resources/${params.domainId}/datastudios/${params.datastudioId}`
          : `/resources/${params.domainId}/datastudio/`;
      case 'lookers':
        return pageType === 'lookers'
          ? `/resources/looker/${params.lookerId}`
          : `/resources/${params.domainId}/looker/`;
      default:
        return null;
    }
  };

  if (layout === 'detail') {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="lg:col-span-3">
          {loading ? <ContentSkeleton /> : <Outlet />}
        </div>
        <div className="lg:col-span-1">
          {loading ? (
            <InfoSkeleton />
          ) : (
            data && (
              <AssetInfo
                resourceType={pageType}
                projectId={params.projectId}
                datasetId={params.datasetId}
                tableId={params.tableId}
                data={data}
              />
            )
          )}
        </div>
      </div>
    );
  }

  return <div className="w-full">{loading ? <ContentSkeleton /> : <Outlet />}</div>;
}
