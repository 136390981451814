import { createData, deleteData, fetchData, updateData } from "./baseRequest";

export const getTags = async () => {
    return fetchData(`/tags/`);  
};

export const getResourceTags = async (resource_id) => {
    return fetchData(`/tags/${resource_id}/`);
};

export const addResourceTags = async (resource_id, tags) => {
    return createData(`/tags/resources/${resource_id}/`, {tags});
};

