export const layoutConfig = {
  bigquery: {
    projects: "detail",
    datasets: "detail",
    tables: "detail",
  },
  docs: {
    list: "list",
    docs: "detail",
  },
  notebooks: {
    list: "list",
    notebooks: "detail",
  },
  lookers: {
    list: "list",
    lookers: "detail",
  },
  datastudios: {
    list: "list",
    datastudios: "detail",
  },
};
