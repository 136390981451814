import React, { createContext, useContext, useState, useEffect } from 'react';
import { checkAuth } from '../utils/user';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initializeAuth = async () => {
            setIsLoading(true);
            const storedUserData = null;
            if (storedUserData) {
                setUser(JSON.parse(storedUserData));
            } else {

                try {
                    const data = await checkAuth()
                    if (data.isAuthenticated) {
                        setUser(data.user);
                    } else {
                        setUser(null);
                    }
                } catch (error) {
                    console.error('Failed to authenticate:', error);
                    setUser(null);
                }
            }
            setIsLoading(false);
        };
        initializeAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
