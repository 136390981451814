import { createData, deleteData, fetchData, updateData } from './baseRequest';

export const createDomainDag = async (data) => {
  return createData(`/dags`, data);
};

export const createDagTask = async (data) => {
  return createData(`/dags/tasks`, data);
};

export const getDagTask = async (dagId, taskId) => {
  return fetchData(`/dags/${dagId}/tasks/${taskId}`);
};

export const updateDagTask = async (dagId, taskId, data) => {
  return updateData(`/dags/${dagId}/tasks/${taskId}`, data);
};

export const deleteDagTask = async (dagId, taskId) => {
  return deleteData(`/dag/${dagId}/task/${taskId}`);
};

export const deleteDag = async (dagId) => {
  return deleteData(`/dags/${dagId}`);
};

export const deleteDagTaskDep = async (dagId, data) => {
  return updateData(`/dag/${dagId}/edge`, data);
};

export const createDagTaskDep = async (dagId, data) => {
  return createData(`/dag/${dagId}/edge`, data);
};

export const getDagTaskList = async (dagId) => {
  return fetchData(`/dags/${dagId}/tasks`);
};

export const getDagTaskRuns = async (taskId) => {
  return fetchData(`/domain/tasks/${taskId}/runs`);
};

export const terminateActivity = async (dunit, workflowId, runId, activityId) => {
  return createData(
    `/dag/${dunit}/${workflowId}/${runId}/activity/${activityId}/cancel`
  );
};

export const fetchDag = async (dagId) => {
  return fetchData(`/dags/${dagId}/`);
};

export const trigger_dag = async (dagId) => {
  return createData(`/dag/${dagId}/trigger`);
};

export const trigger_adhoc = async (dagId, data) => {
  return createData(`/dag/${dagId}/trigger-adhoc`, data);
};

export const updateDomainDag = async (dagId, data) => {
  return updateData(`/dags/${dagId}`, data);
};

export const publishDomainDag = async (dagId) => {
  return createData(`/dag/${dagId}/publish`);
};

export const getOperatorType = async (type) => {
  return fetchData(`/operators/${type}`);
};

export const toggleTask = async (apiPart, taskId) => {
  return createData(`/tasks/${taskId}/${apiPart}`);
};
