import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select";
  import { Brain } from "lucide-react";

const LLMSelector = ({ value, onValueChange }) => {
    return (
      <Select value={value} onValueChange={onValueChange}>
        <SelectTrigger 
          className="w-[140px] h-8 text-xs bg-zinc-50 dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700"
        >
          <div className="flex items-center gap-2">
            <Brain className="h-3 w-3" />
            <SelectValue placeholder="Select LLM" />
          </div>
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="gpt-4">GPT-4</SelectItem>
          <SelectItem value="gpt-3.5">GPT-3.5</SelectItem>
          <SelectItem value="claude">Claude</SelectItem>
          <SelectItem value="llama">Llama 2</SelectItem>
        </SelectContent>
      </Select>
    );
  };

export default LLMSelector