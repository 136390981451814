import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition, Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'


function CommitMessage({ id_history, onClose, message }) {
    const [key, setKey] = useState(message)

    const fetchTaskCommitMessage = async () => { }

    const saveCommitMessage = async () => {

        try {
            const response = await fetch(`http://localhost:8080/task/${id_history}/commit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "message": key })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            return result;

        } catch (error) {
            console.error("There was a problem creating the tag:", error);
            throw error;
        }
    }

    useEffect(() => {
        fetchTaskCommitMessage()
    }, [])

    return (
        <Transition appear show={true} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={onClose}
            >
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />

                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div className="inline-block w-1/2 max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6  mb-6"
                        >
                            Commit Message
                        </Dialog.Title>

                        <div className='mb-6'>
                            <label
                                className="block flex text-sm font-medium leading-6 text-gray-600 justify-between"
                            >
                                <div>Message</div>
                                <div className=' text-green-500'></div>
                            </label>
                            <textarea
                                type="text"
                                value={key}
                                onChange={e => setKey(e.target.value)}
                                placeholder="key"
                                className="block mb-2 w-full rounded-md border-0 py-1.5  text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button
                                type="button"
                                className="text-sm font-semibold leading-6  text-gray-800"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                            <div>
                                <button
                                    type="submit"
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={() => {
                                        saveCommitMessage();
                                        onClose();
                                    }}
                                >
                                    Save
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default CommitMessage;
