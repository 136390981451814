import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import cronstrue from "cronstrue";
import {
  Clock,
  Check,
  ChevronUpDown,
  Globe,
  Users,
  Lock
} from "lucide-react";

import { SelectedContext } from "../../context/SelectedContext";
import { cn } from "@/lib/utils";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  SheetFooter,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";

const schedules = [
  { label: "Every Hour", value: "0 * * * *" },
  { label: "Every Day at Midnight", value: "0 0 * * *" },
  { label: "Every Half an Hour", value: "30 0 * * *" },
  { label: "Every Week on Sunday", value: "0 0 * * 0" },
  { label: "Every Month on the 1st", value: "0 0 1 * *" },
];

const DagEdit = ({ dag, onClose, onDagEdited }) => {
  const [cron, setCron] = useState(dag?.schedule || schedules[0].value);
  const [cronReadable, setCronReadable] = useState("");
  const [dagName, setDagName] = useState(dag?.dag_name || "");
  const [description, setDescription] = useState(dag?.description || "");
  const [privacy, setPrivacy] = useState("public");
  const [open, setOpen] = useState(true);
  const [isCustomCron, setIsCustomCron] = useState(!schedules.some(s => s.value === dag?.schedule));
  const { selectedDomain } = useContext(SelectedContext);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      setCronReadable(cronstrue.toString(cron));
    } catch (error) {
      setCronReadable("Invalid cron expression");
    }
  }, [cron]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onDagEdited({
        dag_name: dagName,
        schedule: cron,
        description: description
      });
    } catch (error) {
      // Handle error
      console.error('Failed to update DAG:', error);
    }
  };

  const handleScheduleSelect = (value) => {
    setCron(value);
    setIsCustomCron(false);
    try {
      setCronReadable(cronstrue.toString(value));
    } catch (error) {
      setCronReadable("Invalid cron expression");
    }
  };

  const handleCustomCronChange = (e) => {
    const value = e.target.value;
    setCron(value);
    setIsCustomCron(true);
    try {
      setCronReadable(cronstrue.toString(value));
    } catch (error) {
      setCronReadable("Invalid cron expression");
    }
  };

  return (
    <Sheet open={open} onOpenChange={() => {
      setOpen(false);
      onClose();
    }}>
      <SheetContent 
        side="right" 
        className="w-full sm:w-[540px] p-0"
        closeButtonProps={{ className: "top-4 right-4" }}
      >
        <form onSubmit={handleSubmit} className="flex flex-col h-full">
          <SheetHeader className="px-6 py-4 border-b">
            <SheetTitle>Edit DAG</SheetTitle>
            <SheetDescription>
              Make changes to your DAG configuration
            </SheetDescription>
          </SheetHeader>

          <ScrollArea className="flex-1 px-6">
            <div className="space-y-6 py-6">
              <div className="space-y-2">
                <Label htmlFor="dagName" className="text-sm font-medium">
                  DAG Name
                </Label>
                <Input
                  id="dagName"
                  value={dagName}
                  onChange={(e) => setDagName(e.target.value)}
                  placeholder="Enter DAG name"
                  className="w-full"
                />
              </div>

              <div className="space-y-2">
                <Label className="text-sm font-medium">Schedule</Label>
                <Select 
                  value={isCustomCron ? "" : cron}
                  onValueChange={handleScheduleSelect}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a schedule" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Common Schedules</SelectLabel>
                      {schedules.map((schedule) => (
                        <SelectItem 
                          key={schedule.value} 
                          value={schedule.value}
                        >
                          {schedule.label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>

                <div className="relative">
                  <Label className="text-sm font-medium">
                    Custom Schedule
                  </Label>
                  <Input
                    placeholder="Enter custom cron expression"
                    value={isCustomCron ? cron : ""}
                    onChange={handleCustomCronChange}
                    className="mt-2"
                  />
                  <div className="flex items-center space-x-2 mt-2 text-sm text-muted-foreground">
                    <Clock className="h-4 w-4" />
                    <span>{cronReadable}</span>
                  </div>
                </div>
              </div>

              <Separator />

              <div className="space-y-2">
                <Label htmlFor="description" className="text-sm font-medium">
                  Description
                </Label>
                <Textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Describe your DAG..."
                  className="min-h-[120px]"
                />
              </div>

              <div className="space-y-2">
                <Label className="text-sm font-medium">Privacy Settings</Label>
                <RadioGroup
                  value={privacy}
                  onValueChange={setPrivacy}
                  className="space-y-4 mt-2"
                >
                  <div className="flex items-start space-x-3">
                    <RadioGroupItem value="public" id="public" />
                    <div className="space-y-1">
                      <Label
                        htmlFor="public"
                        className="flex items-center gap-2 font-normal cursor-pointer"
                      >
                        <Globe className="h-4 w-4" />
                        Public access
                      </Label>
                      <p className="text-sm text-muted-foreground">
                        Everyone with the link will see this project.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-3">
                    <RadioGroupItem value="domain" id="domain" />
                    <div className="space-y-1">
                      <Label
                        htmlFor="domain"
                        className="flex items-center gap-2 font-normal cursor-pointer"
                      >
                        <Users className="h-4 w-4" />
                        Private to domain members
                      </Label>
                      <p className="text-sm text-muted-foreground">
                        Only members of this domain can access.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-3">
                    <RadioGroupItem value="private" id="private" />
                    <div className="space-y-1">
                      <Label
                        htmlFor="private"
                        className="flex items-center gap-2 font-normal cursor-pointer"
                      >
                        <Lock className="h-4 w-4" />
                        Private to you
                      </Label>
                      <p className="text-sm text-muted-foreground">
                        Only you can access this project.
                      </p>
                    </div>
                  </div>
                </RadioGroup>
              </div>
            </div>
          </ScrollArea>

          <SheetFooter className="px-6 py-4 border-t">
            <div className="flex justify-end gap-2 w-full">
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  setOpen(false);
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save changes</Button>
            </div>
          </SheetFooter>
        </form>
      </SheetContent>
    </Sheet>
  );
};

export default DagEdit;