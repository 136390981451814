import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { X, ChevronUpDown } from 'lucide-react';
import { addResourceTags, getTags, getResourceTags } from '../../utils/tags';

function TagModal({ resource_id, onClose }) {
  const [newTag, setNewTag] = useState('');
  const [selected, setSelected] = useState({ name: null, color: null });
  const [localSelectedTags, setLocalSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);

  const tailwindColors = [
    { color: 'bg-gray-50', text_color: 'text-gray-600' },
    { color: 'bg-red-50', text_color: 'text-red-700' },
    { color: 'bg-yellow-50', text_color: 'text-yellow-800' },
    { color: 'bg-green-50', text_color: 'text-green-700' },
    { color: 'bg-blue-50', text_color: 'text-blue-700' },
    { color: 'bg-indigo-50', text_color: 'text-indigo-700' },
    { color: 'bg-purple-50', text_color: 'text-purple-700' },
    { color: 'bg-pink-50', text_color: 'text-pink-700' },
    { color: 'bg-orange-50', text_color: 'text-orange-700' },
  ];

  const getAllTags = async () => {
    try {
      const tags_data = await getTags(resource_id);
      setTags(tags_data || []);
    } catch (error) {
      console.error('Failed to fetch tags:', error);
    }
  };

  const getResourceTagList = async () => {
    try {
      const resource_tags = await getResourceTags(resource_id);
      setLocalSelectedTags(resource_tags);
    } catch (error) {
      console.error('Failed to fetch resource tags:', error);
    }
  };

  useEffect(() => {
    getResourceTagList();
    getAllTags();
  }, []);

  useEffect(() => {
    if (selected.name) {
      setLocalSelectedTags((prevTags) => {
        if (!prevTags.some((tag) => tag.name === selected.name)) {
          return [...prevTags, selected];
        }
        return prevTags;
      });
      setSelected({ name: null, color: null });
    }
  }, [selected]);

  const handleRemoveTag = (tagNameToRemove) => {
    setLocalSelectedTags(
      localSelectedTags.filter((tagObj) => tagObj.name !== tagNameToRemove)
    );
  };

  const handleAddResourceTags = async (tags) => {
    try {
      const updatedTags = await addResourceTags(resource_id, tags);
    } catch (error) {
      console.error('Failed to update resource tags:', error);
    }
  };

  const handleAddTag = () => {
    if (!newTag.trim()) return;

    const randomColorClass = getRandomColorClass();
    setLocalSelectedTags([
      ...localSelectedTags,
      {
        name: newTag.trim(),
        color: randomColorClass.color,
        text_color: randomColorClass.text_color,
      },
    ]);
    setNewTag('');
  };

  function getRandomColorClass() {
    const randomIndex = Math.floor(Math.random() * tailwindColors.length);
    return tailwindColors[randomIndex];
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && newTag.trim()) {
      e.preventDefault();
      handleAddTag();
    }
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Tags</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <div className="flex flex-wrap gap-2">
            {localSelectedTags.map((tagObj) => (
              <span
                key={tagObj.name}
                className={`inline-flex items-center gap-x-1 rounded-md px-2 py-1 text-xs font-medium ${tagObj.color} ${tagObj.text_color}`}
              >
                {tagObj.name}
                <button
                  type="button"
                  className="ml-1 inline-flex h-4 w-4 items-center justify-center rounded-full hover:bg-gray-200"
                  onClick={() => handleRemoveTag(tagObj.name)}
                >
                  <X className="h-3 w-3" />
                </button>
              </span>
            ))}
          </div>

          <div className="flex space-x-2">
            <Input
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="New tag"
              className="flex-1"
            />
            <Button
              onClick={handleAddTag}
              disabled={!newTag.trim()}
              variant="secondary"
              size="sm"
            >
              Add
            </Button>
          </div>

          <Select
            value={selected.name || ''}
            onValueChange={(value) => {
              const selectedTag = tags.find((tag) => tag.name === value);
              if (selectedTag) {
                setSelected(selectedTag);
              }
            }}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Choose a tag" />
            </SelectTrigger>
            <SelectContent>
              {tags.map((tag) => (
                <SelectItem key={tag.name} value={tag.name}>
                  {tag.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <DialogFooter className="sm:justify-end">
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={() => {
              handleAddResourceTags(localSelectedTags);
              onClose();
            }}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default TagModal;
