import { useContext } from 'react';
import { AuthContext } from './AuthContext';
import { ROLE_PERMISSIONS } from './permissions';


const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const hasPermission = (permission, domainId = null, resourceId = null) => {
    if (!context.user || !context.user.roles) {
      return false;
    }

    if (resourceId && context.user.specific_permissions) {
      const resourceKey = `${resourceId}`;
      const resourcePermissions = context.user.specific_permissions[resourceKey];
      if (resourcePermissions && resourcePermissions.permissions.includes(permission)) {
        return true;
      }
    }

    const userRole = context.user.roles[domainId];
    if (!userRole) {
      return false;
    }

    return ROLE_PERMISSIONS[userRole].includes(permission);
  };

  return { ...context, hasPermission };
};

export default useAuth;