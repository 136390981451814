import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Wand2, Loader2 } from 'lucide-react';
import { generateResourceDescription } from '@/utils/chat';

const TextEditorModal = ({ content, isOpen, onClose, title, onSave, id_resource }) => {
  const [value, setValue] = useState('');
  const [showAI, setShowAI] = useState(false);
  const [aiSuggestion, setAiSuggestion] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue(content || '');
  }, [content]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['link', 'blockquote'],
      [{ color: [] }],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'align',
    'link',
    'blockquote',
    'color',
  ];

  const handleGenerateAI = async () => {
    try {
      setIsLoading(true);
      setShowAI(true);

      const response = await generateResourceDescription(id_resource);
      if (response?.description) {
        setAiSuggestion(response.description);
      } else {
        setAiSuggestion('Failed to generate description. Please try again.');
      }
    } catch (error) {
      console.error('Error generating description:', error);
      setAiSuggestion(
        'An error occurred while generating the description. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleUseSuggestion = () => {
    setValue(aiSuggestion);
    setShowAI(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className={`fixed left-1/2 top-1/2 transition-all duration-300 ${
          showAI
            ? 'sm:max-w-[1650px] -translate-x-1/2 -translate-y-1/2'
            : 'sm:max-w-[900px] -translate-x-1/2 -translate-y-1/2'
        }`}
      >
        <div className={`relative flex ${showAI ? 'gap-6' : ''}`}>
          <div className="w-[850px]">
            <DialogHeader className="mb-4 flex">
              <DialogTitle>{title}</DialogTitle>
            </DialogHeader>
            <div className="flex-1 min-h-0">
              <div className="h-[600px]">
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  modules={modules}
                  formats={formats}
                  className="h-[550px]"
                />
              </div>
            </div>
            <DialogFooter className="mt-2">
              <Button
                variant="secondary"
                onClick={handleGenerateAI}
                className="mr-auto"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                ) : (
                  <Wand2 className="w-4 h-4 mr-2" />
                )}
                Generate with AI
              </Button>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={() => onSave(value)}>Save changes</Button>
            </DialogFooter>
          </div>

          {showAI && (
            <div className="w-[700px]">
              <div className="bg-background">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-semibold text-lg">AI Suggestion</h3>
                </div>
                <div className="relative">
                  <div className="h-[550px]">
                    <ReactQuill
                      theme="snow"
                      value={aiSuggestion}
                      readOnly={true}
                      modules={{ toolbar: false }}
                      className="h-[550px]"
                    />
                  </div>
                  {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-background/80">
                      <Loader2 className="w-8 h-8 animate-spin" />
                    </div>
                  )}
                  <div className="flex justify-end gap-2 mt-2 pt-2">
                    <Button variant="outline" onClick={() => setShowAI(false)}>
                      Ignore
                    </Button>
                    <Button onClick={handleUseSuggestion} disabled={isLoading}>
                      Use Suggestion
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TextEditorModal;
