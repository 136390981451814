import React, {
  useReducer,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  memo,
} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { Command, CommandInput } from '@/components/ui/command';
import { Card, CardContent } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Search, Table, FolderOpen } from 'lucide-react';
import { Switch } from '@/components/ui/switch';

import {
  MessageCircle,
  Plus,
  MoreVertical,
  Send,
  Database,
  Table as TableIcon,
  Loader2,
  Settings,
  PanelLeftOpen,
  PanelRightOpen,
  Sparkles,
  Copy,
  Check,
  Bot,
  User,
  Keyboard,
  Brain,
} from 'lucide-react';
import {
  addMessage,
  createConversation,
  getConversation,
  getConversationMessages,
  getUserConversations,
  generateSummary,
  addResource,
  deleteConversation,
} from '@/utils/chat';
import SearchCommand from '@/components/search/SearchCommand';
import ChatResourceSearch from '@/components/chat/ChatResourceSearch';
import { fetchResource } from '@/utils/resources';
import { fetchData } from '@/utils/baseRequest';
import CodeArtifactViewer from '@/components/chat/CodeArtifactViewer';
import LLMSelector from '@/components/chat/LLMSelector';
import { useSearchParams } from 'react-router-dom';
import { useToast } from '@/components/hooks/use-toast';
import { Eye, Trash2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';

const useTypingAnimation = (text, duration = 30) => {
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    setDisplayText('');
    setIsTyping(true);

    const characters = text.split('');
    let currentIndex = 0;

    const timer = setInterval(() => {
      if (currentIndex < characters.length) {
        setDisplayText((prev) => characters.slice(0, currentIndex + 1).join(''));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(timer);
      }
    }, duration);

    return () => clearInterval(timer);
  }, [text, duration]);

  return [displayText, isTyping];
};

const useCopy = (duration = 2000) => {
  const [copied, setCopied] = useState(false);

  const copy = useCallback(
    (text) => {
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), duration);
      });
    },
    [duration]
  );

  return [copied, copy];
};

const Message = memo(
  ({
    message,
    onCopy,
    isLatest,
    forceShowFull = false,
    onArtifactOpen,
    conversationId,
    typingStatus,
  }) => {
    const [copied, copy] = useCopy();
    const isAi = message.sender === 'ai';
    const shouldAnimate =
      isAi && isLatest && !forceShowFull && typingStatus[conversationId];

    const [displayText, isTyping] = useTypingAnimation(
      message.content,
      shouldAnimate ? 30 : 0
    );

    const messageRef = useRef(null);

    useEffect(() => {
      if (isLatest && message.sender === 'ai' && typingStatus[conversationId]) {
        const scrollContainer = messageRef.current?.closest('.scroll-area-viewport');
        if (scrollContainer) {
          const isNearBottom =
            scrollContainer.scrollHeight - scrollContainer.scrollTop <=
            scrollContainer.clientHeight + 100;
          if (isNearBottom) {
            messageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }
      }
    }, [isLatest, message.sender, conversationId, typingStatus, displayText]);

    const parseCodeBlock = (content) => {
      const codeRegex = /<code language="([^"]+)">([\s\S]*?)<\/code>/;
      const match = content.match(codeRegex);

      if (match) {
        const [fullMatch, language, code] = match;
        const beforeCode = content.substring(0, content.indexOf(fullMatch));
        const afterCode = content.substring(
          content.indexOf(fullMatch) + fullMatch.length
        );
        return {
          hasCode: true,
          language,
          code: code.trim(),
          beforeCode: beforeCode.trim(),
          afterCode: afterCode.trim(),
        };
      }
      return { hasCode: false, code: null, language: null };
    };

    const contentParsed = parseCodeBlock(message.content);
    const contentToShow = forceShowFull
      ? message.content
      : isAi && isLatest && !forceShowFull
      ? displayText
      : message.content;

    const handleOpenArtifact = () => {
      if (onArtifactOpen) {
        if (message.isCode) {
          onArtifactOpen(message.content, message.language || 'text');
        } else if (contentParsed.hasCode) {
          onArtifactOpen(contentParsed.code, contentParsed.language || 'text');
        }
      }
    };

    return (
      <motion.div
        ref={messageRef}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className={`flex items-start gap-4 ${!isAi && 'flex-row-reverse'}`}
      >
        <Avatar
          className={`h-8 w-8 ${
            isAi ? 'bg-gradient-to-br from-violet-500 to-indigo-500' : 'bg-zinc-700'
          }`}
        >
          <AvatarFallback>
            {isAi ? <Bot className="h-4 w-4" /> : <User className="h-4 w-4" />}
          </AvatarFallback>
        </Avatar>

        <div className={`flex flex-col gap-2 max-w-[80%] ${!isAi && 'items-end'}`}>
          <div
            className={`relative rounded-lg p-4 ${
              isAi
                ? 'bg-zinc-50 dark:bg-zinc-800'
                : 'bg-gradient-to-r from-violet-500 to-indigo-500 text-white'
            }`}
          >
            {contentParsed.hasCode ? (
              <div className="space-y-2">
                {contentParsed.beforeCode && (
                  <p className="text-sm whitespace-pre-wrap">
                    {contentParsed.beforeCode}
                  </p>
                )}
                <Button
                  variant="outline"
                  className="w-full bg-gray-300 hover:bg-gray-200 p-8 flex items-center justify-between "
                  onClick={handleOpenArtifact}
                >
                  <div className="flex items-center gap-3">
                    <Bot className="h-4 w-4" />
                    <div className="text-left">
                      <div className="font-medium">improved version of python code</div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Badge variant="secondary" className="text-xs">
                      {contentParsed.language}
                    </Badge>
                  </div>
                </Button>
                {contentParsed.afterCode && (
                  <p className="text-sm whitespace-pre-wrap">
                    {contentParsed.afterCode}
                  </p>
                )}
              </div>
            ) : (
              <p className="text-sm whitespace-pre-wrap">
                {contentToShow}
                {isTyping && !forceShowFull && (
                  <motion.span
                    animate={{ opacity: [0, 1] }}
                    transition={{
                      duration: 0.5,
                      repeat: Infinity,
                      repeatType: 'reverse',
                    }}
                  >
                    ▋
                  </motion.span>
                )}
              </p>
            )}
          </div>
          <span className="text-xs text-zinc-500">
            {new Date(message.timestamp).toLocaleTimeString()}
          </span>
        </div>
      </motion.div>
    );
  }
);
const RESOURCE_TYPES = {
  PROJECT: 'project',
  DATASET: 'dataset',
  TABLE: 'table',
};

const ResourceIcon = ({ type }) => {
  switch (type) {
    case RESOURCE_TYPES.PROJECT:
      return <FolderOpen className="h-5 w-5" />;
    case RESOURCE_TYPES.DATASET:
      return <Database className="h-5 w-5" />;
    case RESOURCE_TYPES.TABLE:
      return <Table className="h-5 w-5" />;
    default:
      return <Search className="h-5 w-5" />;
  }
};

const ResourceList = ({ resources = [], isLoading = false, onRemove }) => {
  const [selectedResource, setSelectedResource] = useState(null);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [isLoadingResource, setIsLoadingResource] = useState(false);
  const { toast } = useToast();

  const handleViewResource = async (resourceId) => {
    try {
      setIsLoadingResource(true);
      const resource = await fetchResource('table', resourceId);
      setSelectedResource(resource);
      setIsViewDialogOpen(true);
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to load resource details',
      });
    } finally {
      setIsLoadingResource(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-4">
        <Loader2 className="h-6 w-6 animate-spin text-zinc-400" />
      </div>
    );
  }

  if (!resources || resources.length === 0) {
    return (
      <div className="text-center text-sm text-zinc-500 dark:text-zinc-400 p-4">
        No resources added yet
      </div>
    );
  }

  return (
    <>
      <div className="space-y-3 py-4">
        {resources.map((resource) => (
          <Card
            key={resource.id || resource.id_resource}
            className="bg-zinc-50 dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700 hover:border-violet-500 dark:hover:border-violet-400 transition-colors"
          >
            <CardContent className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="h-8 w-8 rounded-full bg-gradient-to-br from-violet-500 to-indigo-500 flex items-center justify-center">
                    <ResourceIcon
                      type={resource.resource_type}
                      className="h-4 w-4 text-white"
                    />
                  </div>
                  <div>
                    <p className="font-medium text-sm text-zinc-900 dark:text-zinc-100 truncate max-w-[160px]">
                      {resource.resource_name}
                    </p>
                    <p className="text-xs text-zinc-500 dark:text-zinc-400 capitalize">
                      {resource.resource_type}
                    </p>
                  </div>
                </div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="icon">
                      <MoreVertical className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem
                      onClick={() =>
                        handleViewResource(resource.id || resource.id_resource)
                      }
                    >
                      <Eye className="h-4 w-4 mr-2" />
                      View details
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => onRemove(resource)}
                      className="text-red-600"
                    >
                      <Trash2 className="h-4 w-4 mr-2" />
                      Remove
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      <Dialog open={isViewDialogOpen} onOpenChange={setIsViewDialogOpen}>
        <DialogContent className="sm:max-w-[500px] h-[80vh] flex flex-col p-0">
          {' '}
          {/* Remove padding here */}
          <DialogHeader className="p-6 pb-2">
            {' '}
            {/* Add padding to header */}
            <DialogTitle>Resource Details</DialogTitle>
            <DialogDescription>
              Detailed information about the selected resource
            </DialogDescription>
          </DialogHeader>
          {isLoadingResource ? (
            <div className="flex items-center justify-center p-6">
              <Loader2 className="h-6 w-6 animate-spin text-zinc-400" />
            </div>
          ) : (
            selectedResource && (
              <div className="flex-1 overflow-hidden">
                {' '}
                {/* Wrapper for ScrollArea */}
                <ScrollArea className="h-full px-6">
                  {' '}
                  {/* Add horizontal padding */}
                  <div className="space-y-4 pb-6">
                    {' '}
                    {/* Add bottom padding for content */}
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <ResourceIcon
                          type={selectedResource.resource_type}
                          className="h-5 w-5 text-violet-500"
                        />
                        <h3 className="font-medium">
                          {selectedResource.resource_name}
                        </h3>
                      </div>
                      <Badge variant="secondary">
                        {selectedResource.resource_type}
                      </Badge>
                    </div>
                    <div className="space-y-2">
                      <h4 className="text-sm font-medium text-zinc-500">Properties</h4>
                      <div className="rounded-lg border border-zinc-200 dark:border-zinc-700 divide-y divide-zinc-200 dark:divide-zinc-700">
                        {Object.entries(selectedResource).map(([key, value]) => (
                          <div key={key} className="flex py-2 px-3">
                            <span className="flex-shrink-0 w-1/3 text-sm font-medium break-words">
                              {key}
                            </span>
                            <span className="text-sm text-zinc-600 dark:text-zinc-400 break-all ml-4">
                              {typeof value === 'object'
                                ? JSON.stringify(value, null, 2)
                                : value?.toString()}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </ScrollArea>
              </div>
            )
          )}
          <DialogFooter className="p-6 pt-4 border-t border-zinc-200 dark:border-zinc-700">
            {' '}
            {/* Add padding and border to footer */}
            <Button variant="ghost" onClick={() => setIsViewDialogOpen(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Chat = () => {
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(true);
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(true);
  const [artifactOpen, setArtifactOpen] = useState(false);
  const [currentArtifact, setCurrentArtifact] = useState({
    code: '',
    language: 'text',
  });
  const [selectedLLM, setSelectedLLM] = useState('claude');
  const [autonomousSearch, setAutonomousSearch] = useState(false);
  const [searchParams] = useSearchParams();
  const [typingStatus, setTypingStatus] = useState({});

  const [messages, setMessages] = useState([
    {
      id: '1',
      content:
        "Hello! 👋 I'm your AI assistant. I can help you with data analysis, writing queries, and finding information. How can I assist you today?",
      sender: 'ai',
      timestamp: new Date(Date.now() - 5000),
    },
  ]);

  const [isNewChat, setIsNewChat] = useState(false);

  const handleArtifactOpen = (code, language = 'python') => {
    setCurrentArtifact({ code, language });
    setArtifactOpen(true);
  };

  const [newMessage, setNewMessage] = useState('');
  // const [isTyping, setIsTyping] = useState(false);
  const chatEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [conversations, setConversations] = useState([]);
  const [activeConversationId, setActiveConversationId] = useState(null);
  const [isLoadingConversations, setIsLoadingConversations] = useState(true);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [openCommandMenu, setOpenCommandMenu] = useState(false);
  const [temporaryConversation, setTemporaryConversation] = useState({
    id_conversation: 'temp-conversation',
    resources: [],
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    last_activity: new Date().toISOString(),
  });

  const { toast } = useToast();

  const isCurrentlyTyping = activeConversationId && typingStatus[activeConversationId];

  const parseCodeBlock = (content) => {
    const codeRegex = /<code>([\s\S]*?)<\/code>/;
    const match = content.match(codeRegex);
    if (match) {
      const [fullMatch, code] = match;
      const beforeCode = content.substring(0, content.indexOf(fullMatch));
      const afterCode = content.substring(
        content.indexOf(fullMatch) + fullMatch.length
      );
      return {
        hasCode: true,
        code: code.trim(),
        beforeCode,
        afterCode,
      };
    }
    return { hasCode: false, code: null };
  };

  const sidebarAnimation = {
    open: {
      width: '288px',
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
      },
    },
    closed: {
      width: '0px',
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
      },
    },
  };

  const contentAnimation = {
    open: (side) => ({
      x: side === 'left' ? 320 : 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
      },
    }),
    closed: (side) => ({
      x: side === 'left' ? 0 : 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
      },
    }),
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (artifactOpen) {
      setIsLeftPanelOpen(false);
      setIsRightPanelOpen(false);
    }
  }, [artifactOpen]);

  useEffect(() => {
    const resourceId = searchParams.get('resourceId');

    loadConversations().then(() => {
      if (resourceId) {
        handleInitialResource(resourceId);
      }
    });
  }, []);

  const handleInitialResource = async (resourceId) => {
    try {
      const resource = await fetchData(`/search/id/${resourceId}`);
      if (resource) {
        setTemporaryConversation((prev) => ({
          ...prev,
          resources: [...prev.resources, resource],
        }));
        setActiveConversationId('temp-conversation');
        setIsNewChat(true);

        toast({
          title: 'Resource loaded',
          description: 'Resource will be linked when you start the conversation',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        variant: 'destructive',
        title: 'Failed to load resource',
      });
    } finally {
    }
  };

  const loadConversations = async () => {
    setIsLoadingConversations(true);
    try {
      const data = await getUserConversations();
      const sortedConversations = data.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
      setConversations(sortedConversations);

      if (
        sortedConversations.length > 0 &&
        activeConversationId !== 'temp-conversation'
      ) {
        // await loadConversation(sortedConversations[0].id_conversation);
      }
    } catch (error) {
      console.error('Error loading conversations:', error);
    } finally {
      setIsLoadingConversations(false);
    }
  };

  const getResource = async (resourceId) => {
    const response = await fetchResource('x', resourceId);
    const data = await response.json();
    return data;
  };

  const loadConversation = async (conversationId) => {
    setIsLoadingMessages(true);

    try {
      let messagesData;
      if (conversationId !== activeConversationId) {
        messagesData = await getConversationMessages(conversationId);
      }

      const formattedMessages = messagesData.map((msg) => ({
        id: msg.id_message.toString(),
        content: msg.content,
        sender: msg.sender,
        timestamp: new Date(msg.timestamp),
        conversation_id: msg.conversation_id,
      }));

      setMessages(formattedMessages);
      setActiveConversationId(conversationId);
      setIsNewChat(false);
      setIsLoadingMessages(false);

      try {
        const conversationData = await getConversation(conversationId);
        setConversations((prev) =>
          prev.map((conv) => {
            if (conv.id_conversation === conversationId) {
              return {
                ...conv,
                ...conversationData,
              };
            }
            return conv;
          })
        );
      } catch (error) {
        console.error('Error loading conversation data:', error);
      }
    } catch (error) {
      console.error('Error loading messages:', error);
      setIsLoadingMessages(false);
    }
  };
  const handleNewConversation = () => {
    setActiveConversationId('temp-conversation');
    setTemporaryConversation({
      id_conversation: 'temp-conversation',
      resources: [],
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      last_activity: new Date().toISOString(),
    });
    setMessages([
      {
        id: '1',
        content: "Hello! 👋 I'm your AI assistant. How can I help you today?",
        sender: 'ai',
        timestamp: new Date(),
      },
    ]);
    setIsNewChat(true);
  };

  const getConversationPreview = (conversation) => {
    return conversation.summary || '';
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();

    if (date.toDateString() === now.toDateString()) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    return date.toLocaleDateString();
  };

  const handleSendMessage = useCallback(async () => {
    if (!newMessage.trim()) return;

    const messageText = newMessage;
    setNewMessage('');

    setTypingStatus((prev) => ({
      ...prev,
      [activeConversationId]: true,
    }));

    try {
      const userMessage = {
        id: Date.now().toString(),
        content: messageText,
        sender: 'user',
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, userMessage]);

      let currentConversationId = activeConversationId;
      let isFirstMessage = false;

      if (isNewChat) {
        const newConversation = await createConversation();
        currentConversationId = newConversation.id_conversation;
        setActiveConversationId(currentConversationId);

        if (
          activeConversationId === 'temp-conversation' &&
          temporaryConversation.resources.length > 0
        ) {
          setConversations((prev) => [
            {
              id_conversation: currentConversationId,
              resources: temporaryConversation.resources,
              resource_ids: temporaryConversation.resources.map(
                (r) => r.id_resource || r.id
              ),
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              last_activity: new Date().toISOString(),
            },
            ...prev,
          ]);
          await Promise.all(
            temporaryConversation.resources.map((resource) =>
              addResource(currentConversationId, resource.id_resource || resource.id)
            )
          );
        }

        isFirstMessage = true;
      } else if (messages.length <= 1) {
        isFirstMessage = true;
      }

      const messageResponse = await addMessage(
        currentConversationId,
        messageText,
        'user'
      );

      if (isFirstMessage) {
        const summary = await generateSummary(currentConversationId, messageText);
        const newConvWithSummary = {
          id_conversation: currentConversationId,
          summary: summary.summary,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          last_activity: new Date().toISOString(),
          resource_ids: temporaryConversation.resources.map(
            (r) => r.id_resource || r.id
          ),
          resources: temporaryConversation.resources,
        };
        setConversations((prev) => [
          newConvWithSummary,
          ...prev.filter((c) => c.id_conversation !== currentConversationId),
        ]);

        setTemporaryConversation({
          id_conversation: 'temp-conversation',
          resources: [],
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          last_activity: new Date().toISOString(),
        });

        setIsNewChat(false);
      }

      if (Array.isArray(messageResponse) && messageResponse.length > 0) {
        setMessages((prev) => {
          const withoutTemp = prev.filter((m) => m.id !== userMessage.id);
          const formattedMessages = messageResponse.map((msg) => ({
            id: msg.id_message.toString(),
            content: msg.content,
            sender: msg.sender,
            timestamp: new Date(msg.timestamp),
            conversation_id: msg.conversation_id,
          }));
          return [...withoutTemp, ...formattedMessages];
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prev) => [
        ...prev,
        {
          id: Date.now().toString(),
          content:
            'Sorry, there was an error processing your message. Please try again.',
          sender: 'ai',
          timestamp: new Date(),
        },
      ]);
    } finally {
      setTypingStatus((prev) => ({
        ...prev,
        [activeConversationId]: false,
      }));
    }
  }, [
    newMessage,
    isNewChat,
    activeConversationId,
    messages.length,
    selectedLLM,
    autonomousSearch,
    temporaryConversation,
  ]);

  const formatRelativeTime = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return 'Just now';
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
    if (date.getFullYear() === now.getFullYear()) {
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    }
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const [isSummaryLoading, setIsSummaryLoading] = useState(false);

  const ConversationItem = memo(({ conversation, isActive, onClick, onDelete }) => {
    const isLoading = isSummaryLoading && isActive;
    const { toast } = useToast();

    const handleDelete = async (e) => {
      e.stopPropagation();
      try {
        const response = await deleteConversation(conversation.id_conversation);
        onDelete(conversation.id_conversation);
        toast({
          title: 'Conversation deleted',
          description: 'The conversation has been removed',
        });
      } catch (error) {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Failed to delete conversation',
        });
      }
    };

    return (
      <div
        className={`group relative hover:bg-gradient-to-r hover:from-violet-50 hover:to-indigo-50 dark:hover:from-violet-900/20 dark:hover:to-indigo-900/20 ${
          isActive
            ? 'bg-gradient-to-r from-violet-50 to-indigo-50 dark:from-violet-900/20 dark:to-indigo-900/20'
            : ''
        }`}
      >
        <Button
          variant="ghost"
          className="w-full justify-start h-auto py-3 px-4 hover:bg-transparent"
          onClick={onClick}
        >
          <div className="flex flex-col items-start gap-1 w-full">
            <div className="flex items-center gap-2 w-full">
              <MessageCircle className="h-4 w-4 shrink-0" />
              <span className="text-sm flex font-medium truncate flex-1">
                {isLoading ? (
                  <div className="flex items-center gap-2">
                    <Loader2 className="h-3 w-3 animate-spin" />
                    <span className="text-zinc-500">Generating summary...</span>
                  </div>
                ) : (
                  conversation.summary || 'New Conversation'
                )}
              </span>
            </div>
            <div className="flex items-center gap-2 w-full text-xs text-zinc-500">
              <time dateTime={conversation.last_activity}>
                {formatRelativeTime(conversation.last_activity)}
              </time>
              {conversation.resource_ids?.length > 0 && (
                <Badge variant="secondary" className="text-xs">
                  <Database className="h-3 w-3 mr-1" />
                  {conversation.resource_ids.length}
                </Badge>
              )}
            </div>
          </div>
        </Button>

        <div className="absolute right-2 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 hover:bg-zinc-200/50 dark:hover:bg-zinc-800/50"
                onClick={(e) => e.stopPropagation()}
              >
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem className="text-red-600" onClick={handleDelete}>
                Delete conversation
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    );
  });
  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  return (
    <div className="flex h-screen bg-gradient-to-br from-zinc-50 to-zinc-100 dark:from-zinc-900 dark:to-zinc-800 overflow-hidden">
      <motion.div
        initial="open"
        animate={isLeftPanelOpen ? 'open' : 'closed'}
        variants={sidebarAnimation}
        className="relative border-r border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900 overflow-hidden w-72"
      >
        <motion.div className="absolute top-0 left-0 w-72 h-full">
          <div className="flex h-full flex-col">
            <div className="border-b border-zinc-200 dark:border-zinc-800 p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <Avatar className="h-10 w-10 bg-gradient-to-br from-violet-500 to-indigo-500">
                    <AvatarImage
                      src="https://play-lh.googleusercontent.com/4-wa067yiYYMj5rRdHm82jPdDiSHSoIkwwvHA0teCEauFFU_cjx053jMBwBxfo2anJo"
                      alt="AI Assistant"
                    />
                    <AvatarFallback>N</AvatarFallback>
                  </Avatar>
                  <div>
                    <h2 className="font-semibold text-zinc-900 dark:text-zinc-100">
                      Noon
                    </h2>
                    <p className="text-xs text-zinc-500 dark:text-zinc-400">
                      data platform
                    </p>
                  </div>
                </div>
                <Button
                  variant="ghost"
                  size="icon"
                  className="hover:bg-zinc-100 dark:hover:bg-zinc-800"
                  onClick={() => setIsLeftPanelOpen(false)}
                >
                  <PanelLeftOpen className="h-4 w-4" />
                </Button>
              </div>
            </div>

            <div className="p-4 space-y-4">
              <Button
                className="w-full  text-white shadow-lg"
                size="lg"
                onClick={handleNewConversation}
              >
                <Plus className="mr-2 h-4 w-4" />
                New Chat
              </Button>
            </div>

            <ScrollArea className="flex-1 px-2">
              <div className="space-y-2 p-2">
                {isLoadingConversations ? (
                  <div className="flex items-center justify-center py-4">
                    <Loader2 className="h-6 w-6 animate-spin text-zinc-400" />
                  </div>
                ) : (
                  conversations.map((conversation) => (
                    <ConversationItem
                      key={conversation.id_conversation}
                      conversation={conversation}
                      isActive={activeConversationId === conversation.id_conversation}
                      onClick={() => loadConversation(conversation.id_conversation)}
                      onDelete={(id) => {
                        setConversations((prev) =>
                          prev.filter((c) => c.id_conversation !== id)
                        );
                        if (activeConversationId === id) {
                          handleNewConversation();
                        }
                      }}
                    />
                  ))
                )}
              </div>
            </ScrollArea>

            <div className="p-4 border-t border-zinc-200 dark:border-zinc-800">
              <Button
                variant="ghost"
                className="w-full justify-start text-zinc-600 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
              >
                <Settings className="h-4 w-4 mr-2" />
                Settings
              </Button>
            </div>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        className="flex-1 flex flex-col min-w-0 bg-white dark:bg-zinc-900"
        animate={{
          marginLeft: isLeftPanelOpen ? 0 : 0,
          marginRight: isRightPanelOpen ? 0 : 0,
          x: artifactOpen ? -300 : 0,
        }}
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 30,
        }}
      >
        <div className="flex items-center p-4  border-zinc-200 dark:border-zinc-800">
          <div className="flex-1 flex items-center">
            {!isLeftPanelOpen && (
              <Button
                variant="ghost"
                size="icon"
                className="mr-2 hover:bg-zinc-100 dark:hover:bg-zinc-800"
                onClick={() => setIsLeftPanelOpen(true)}
              >
                <PanelLeftOpen className="h-4 w-4" />
              </Button>
            )}
            <h1 className="font-semibold text-zinc-900 dark:text-zinc-100">
              {activeConversationId && conversations.length > 0
                ? conversations.find((c) => c.id_conversation === activeConversationId)
                    ?.summary || 'New Chat'
                : 'New Chat'}
            </h1>
            {isLoadingMessages && (
              <Loader2 className="h-4 w-4 animate-spin text-zinc-400" />
            )}
          </div>
          {!isRightPanelOpen && (
            <Button
              variant="ghost"
              size="icon"
              className="hover:bg-zinc-100 dark:hover:bg-zinc-800"
              onClick={() => setIsRightPanelOpen(true)}
            >
              <PanelRightOpen className="h-4 w-4" />
            </Button>
          )}
        </div>
        <ScrollArea className="flex-1 p-4">
          <div className="max-w-3xl mx-auto space-y-6">
            {isLoadingMessages ? (
              <div className="flex items-center justify-center py-8">
                <Loader2 className="h-8 w-8 animate-spin text-zinc-400" />
              </div>
            ) : (
              <>
                {messages.map((message, index) => (
                  <Message
                    key={message.id}
                    message={message}
                    isLatest={index === messages.length - 1}
                    forceShowFull={!isNewChat}
                    onArtifactOpen={(code, language) => {
                      // Receive both code and language
                      setCurrentArtifact({ code, language });
                      setArtifactOpen(true);
                    }}
                    conversationId={activeConversationId}
                    typingStatus={typingStatus}
                  />
                ))}
              </>
            )}

            {isCurrentlyTyping && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex items-center gap-2 text-zinc-500"
              >
                <Avatar className="h-8 w-8 bg-gradient-to-br from-violet-500 to-indigo-500">
                  <AvatarFallback>
                    <Bot className="h-4 w-4" />
                  </AvatarFallback>
                </Avatar>
                <motion.div
                  animate={{ y: [0, -5, 0] }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    repeatType: 'reverse',
                  }}
                >
                  <Loader2 className="h-4 w-4 animate-spin" />
                </motion.div>
                <span className="text-sm">AI is thinking...</span>
              </motion.div>
            )}

            <div ref={chatEndRef} />
          </div>
        </ScrollArea>
        <div className="p-4 border-t border-zinc-200 dark:border-zinc-800">
          <div className="max-w-3xl mx-auto">
            <div className="relative">
              <Textarea
                ref={textareaRef}
                value={newMessage}
                onChange={(e) => {
                  setNewMessage(e.target.value);
                  e.target.style.height = 'auto';
                  e.target.style.height = `${Math.min(e.target.scrollHeight, 800)}px`;
                }}
                onKeyDown={handleKeyPress}
                placeholder="Message..."
                className="min-h-[100px] max-h-[800px] pb-12 px-4 bg-zinc-50 dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700 rounded-lg resize-y focus:ring-2 focus:ring-violet-500 dark:focus:ring-violet-400 overflow-y-auto"
              />

              <div className="absolute bottom-2 left-0 right-0 px-2 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <LLMSelector value={selectedLLM} onValueChange={setSelectedLLM} />
                </div>

                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        className="bg-gradient-to-r from-violet-500 to-indigo-500 hover:from-violet-600 hover:to-indigo-600"
                        size="icon"
                        onClick={handleSendMessage}
                        disabled={!newMessage.trim() || isCurrentlyTyping}
                      >
                        <Send className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <div className="flex items-center gap-2">
                        <span>Send message</span>
                        <Badge variant="outline" className="text-xs">
                          <Keyboard className="h-3 w-3 mr-1" />
                          Enter
                        </Badge>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>

              <div className="" aria-hidden="true" />
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial="open"
        animate={isRightPanelOpen ? 'open' : 'closed'}
        variants={sidebarAnimation}
        className="relative border-l border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900 overflow-hidden"
      >
        <motion.div className="absolute top-0 right-0 w-72 h-full">
          <div className="flex h-full flex-col">
            <div className="border-b border-zinc-200 dark:border-zinc-800 p-4">
              <div className="flex items-center justify-between">
                <h2 className="font-semibold text-zinc-900 dark:text-zinc-100">
                  Resources
                </h2>
                <Button
                  variant="ghost"
                  size="icon"
                  className="hover:bg-zinc-100 dark:hover:bg-zinc-800"
                  onClick={() => setIsRightPanelOpen(false)}
                >
                  <PanelRightOpen className="h-4 w-4" />
                </Button>
              </div>
              <div className="mt-4 flex items-center justify-between p-3 rounded-lg bg-zinc-50 dark:bg-zinc-800/50 border border-zinc-200 dark:border-zinc-700">
                <div className="flex items-center gap-2">
                  <div className="h-8 w-8 rounded-full bg-gradient-to-br from-violet-500 to-indigo-500 flex items-center justify-center">
                    <Brain className="h-4 w-4 text-white" />
                  </div>
                  <div>
                    <p className="font-medium text-sm">Smart Context</p>
                    <p className="text-xs text-zinc-500 dark:text-zinc-400">
                      Let AI find relevant resources
                    </p>
                  </div>
                </div>
                <Switch
                  checked={autonomousSearch}
                  onCheckedChange={setAutonomousSearch}
                />
              </div>
              {autonomousSearch && (
                <p className="mt-2 text-xs text-zinc-500 dark:text-zinc-400 flex items-center gap-1">
                  <Sparkles className="h-3 w-3" />
                  AI will automatically find relevant resources
                </p>
              )}
            </div>
            <div className="p-4 border-b border-zinc-200 dark:border-zinc-800">
              <ChatResourceSearch
                open={openCommandMenu}
                onOpenChange={setOpenCommandMenu}
                conversationId={activeConversationId}
                resources={
                  activeConversationId === 'temp-conversation'
                    ? temporaryConversation.resources
                    : conversations.find(
                        (c) => c.id_conversation === activeConversationId
                      )?.resources || []
                }
                onNewConversation={(newConversation) => {
                  setActiveConversationId(newConversation.id_conversation);
                  setConversations((prev) => [
                    {
                      ...newConversation,
                      resources: [],
                      resource_ids: [],
                    },
                    ...prev,
                  ]);
                  setIsNewChat(false);
                }}
                onResourceAdd={(resource) => {
                  if (activeConversationId === 'temp-conversation') {
                    setTemporaryConversation((prev) => ({
                      ...prev,
                      resources: [...prev.resources, resource],
                    }));
                  } else {
                    setConversations((prev) =>
                      prev.map((conv) => {
                        if (conv.id_conversation === activeConversationId) {
                          return {
                            ...conv,
                            resources: [...(conv.resources || []), resource],
                            resource_ids: [
                              ...(conv.resource_ids || []),
                              resource.id_resource || resource.id,
                            ],
                          };
                        }
                        return conv;
                      })
                    );
                  }
                }}
                temporaryConversation={temporaryConversation}
                setTemporaryConversation={setTemporaryConversation}
                setActiveConversationId={setActiveConversationId}
              />
            </div>
            <ScrollArea className="flex-1 px-4">
              <ResourceList
                resources={
                  activeConversationId === 'temp-conversation'
                    ? temporaryConversation.resources
                    : conversations.find(
                        (c) => c.id_conversation === activeConversationId
                      )?.resources || []
                }
              />
            </ScrollArea>
          </div>
        </motion.div>
      </motion.div>
      <CodeArtifactViewer
        open={artifactOpen}
        onOpenChange={setArtifactOpen}
        code={currentArtifact.code}
        language={currentArtifact.language}
      />
    </div>
  );
};

export default Chat;
