// src/components/SecretSettings.jsx

import React, { useState, Fragment, useEffect, useCallback } from 'react';
import {
  MoreVertical,
  UserPlus,
  Plus,
  Search,
  RotateCw,
  Edit2,
  Trash2,
} from 'lucide-react';
import debounce from 'lodash.debounce';

import SecretManager from '../../components/modals/SecretManager';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Skeleton } from '@/components/ui/skeleton';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

function SecretSettings() {
  const [secrets, setSecrets] = useState([]);
  const [filteredSecrets, setFilteredSecrets] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSecret, setSelectedSecret] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initialSecrets = [
      { id: 1, key: 'API_KEY', value: '********' },
      { id: 2, key: 'DB_PASSWORD', value: '********' },
      { id: 3, key: 'SECRET_TOKEN', value: '********' },
    ];
    setSecrets(initialSecrets);
    setFilteredSecrets(initialSecrets);
  }, []);

  const handleSearch = useCallback(
    debounce((term) => {
      const filtered = secrets.filter((secret) =>
        secret.key.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredSecrets(filtered);
    }, 300),
    [secrets]
  );

  const onSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    handleSearch(term);
  };

  const addSecret = (secret) => {
    const newSecret = {
      id: secrets.length ? Math.max(...secrets.map((s) => s.id)) + 1 : 1,
      key: secret.key,
      value: '********',
    };
    setSecrets((prev) => [...prev, newSecret]);
    setFilteredSecrets((prev) => [...prev, newSecret]);
  };

  const updateSecret = (updatedSecret) => {
    setSecrets((prev) =>
      prev.map((s) =>
        s.id === updatedSecret.id ? { ...s, key: updatedSecret.key } : s
      )
    );
    setFilteredSecrets((prev) =>
      prev.map((s) =>
        s.id === updatedSecret.id ? { ...s, key: updatedSecret.key } : s
      )
    );
  };

  const deleteSecret = (id) => {
    setSecrets((prev) => prev.filter((s) => s.id !== id));
    setFilteredSecrets((prev) => prev.filter((s) => s.id !== id));
  };

  const SecretLoadingSkeleton = () => (
    <div className="space-y-4">
      {[1, 2, 3].map((n) => (
        <div key={n} className="flex items-center gap-4">
          <Skeleton className="h-6 w-40" />
          <Skeleton className="h-6 w-40" />
          <Skeleton className="h-6 w-10" />
        </div>
      ))}
    </div>
  );

  return (
    <div className="p-4">
      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <div className="mb-4 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
        <div>
          <CardTitle className="text-lg">Secrets</CardTitle>
        </div>
        <div className="flex items-center gap-2">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
            <Input
              type="text"
              placeholder="Search secrets..."
              className="pl-10"
              value={searchTerm}
              onChange={onSearchChange}
            />
          </div>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    setSelectedSecret(null);
                    setShowModal(true);
                  }}
                  className="flex items-center"
                >
                  <Plus className="h-4 w-4 mr-1" />
                  Add Secret
                </Button>
              </TooltipTrigger>
              <TooltipContent>Create a new secret</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    const initialSecrets = [
                      { id: 1, key: 'API_KEY', value: '********' },
                      { id: 2, key: 'DB_PASSWORD', value: '********' },
                      { id: 3, key: 'SECRET_TOKEN', value: '********' },
                    ];
                    setSecrets(initialSecrets);
                    setFilteredSecrets(initialSecrets);
                  }}
                  disabled={isLoading}
                >
                  <RotateCw className={`h-5 w-5 ${isLoading ? 'animate-spin' : ''}`} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Reset secrets</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>

      {isLoading ? (
        <SecretLoadingSkeleton />
      ) : secrets.length > 0 ? (
        <div className="bg-white shadow rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th
                  className="px-4 py-3 text-left text-sm font-semibold text-gray-700"
                  scope="col"
                >
                  Key
                </th>
                <th
                  className="px-4 py-3 text-left text-sm font-semibold text-gray-700"
                  scope="col"
                >
                  Value
                </th>
                <th
                  className="px-4 py-3 text-right text-sm font-semibold text-gray-700"
                  scope="col"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredSecrets.map((secret) => (
                <tr key={secret.id}>
                  <td className="px-4 py-2 text-sm text-gray-800">{secret.key}</td>
                  <td className="px-4 py-2 text-sm text-gray-800">{secret.value}</td>
                  <td className="px-4 py-2 text-right text-sm font-medium flex justify-end gap-2">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => {
                              setSelectedSecret(secret);
                              setShowModal(true);
                            }}
                            className="flex items-center"
                          >
                            <Edit2 className="h-4 w-4 text-gray-400 hover:text-gray-600" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Edit Secret</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>

                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => deleteSecret(secret.id)}
                            className="flex items-center"
                          >
                            <Trash2 className="h-4 w-4 text-gray-400 hover:text-gray-600" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Delete Secret</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="bg-white shadow rounded-lg px-6 py-4">
          <p className="text-sm text-gray-600">No secrets added yet.</p>
        </div>
      )}

      {showModal && (
        <SecretManager
          onClose={() => {
            setShowModal(false);
            setSelectedSecret(null);
          }}
          onSave={(secret) => {
            if (selectedSecret) {
              updateSecret(secret);
            } else {
              addSecret(secret);
            }
            setShowModal(false);
            setSelectedSecret(null);
          }}
          secret={selectedSecret}
        />
      )}
    </div>
  );
}

export default SecretSettings;
