import React from 'react';
import useAuth from '../../context/useAuth';
import { Button } from '@/components/ui/button';


const PermissionButton = ({ permission, domainId, resourceId, onClick, className, children, ...props }) => {
  const { hasPermission } = useAuth();

  if (!hasPermission(permission, domainId, resourceId)) {
    return null;
  }

  return (
    <Button
      onClick={onClick}
      className={className}
      {...props}
    >
      {children}
    </Button>
  );
};

export default PermissionButton;