import { fetchData } from './baseRequest';

export const search = async (options = {}) => {
  const params = new URLSearchParams();

  Object.entries(options).forEach(([key, value]) => {
    if (value && value !== 'null' && value !== undefined) {
      // Handle facet_fields specially to append .keyword
      if (key === 'facet_fields' && Array.isArray(value)) {
        value.forEach((field) => {
          // Don't append .keyword if it's already there or if it's the 'labels' field
          const facetField =
            field.endsWith('.keyword') || field === 'labels'
              ? field
              : `${field}.keyword`;
          params.append(key, facetField);
        });
      }
      // Handle other array parameters
      else if (Array.isArray(value)) {
        value.forEach((v) => params.append(key, v));
      }
      // Handle single value parameters
      else {
        params.append(key, value);
      }
    }
  });

  const queryString = params.toString();
  const queryUrl = `/search/${queryString ? `?${queryString}` : ''}`;

  return fetchData(queryUrl);
};

export const autocompleteSearch = async (query, size = 8) => {
  const params = new URLSearchParams({ query, size });
  const queryUrl = `/search/autocomplete?${params.toString()}`;

  return fetchData(queryUrl);
};

export const getResourceById = async (id) => {
  return fetchData(`/search/id/${id}`);
};

